import React, { DetailedHTMLProps, ImgHTMLAttributes } from 'react';

interface OwnProps extends DetailedHTMLProps<ImgHTMLAttributes<HTMLImageElement>, HTMLImageElement> {
  alt: string;
  fallbackSrc: string;
}

export default function ImageWithFallback({ fallbackSrc, src, alt, ...rest }: OwnProps) {
  const fallBack = (evt: any) => {
    if (evt?.target && evt.target.src !== fallbackSrc) {
      evt.target.src = fallbackSrc;
    }
  };
  return <img src={src || fallbackSrc} alt={alt} onError={fallBack} {...rest} />;
}
