import React from 'react';
import { Link } from 'react-router-dom';
import { Card, CardBody, CardHeader } from 'reactstrap';

import { paths } from '../../paths';

interface SectorListProps {
  sectors: { code: string; name: string }[];
  title?: string;
}

const SectorList = (props: SectorListProps) => {
  const { sectors, title } = props;

  return sectors.length > 0 ? (
    <Card className={'mb-3'}>
      <CardHeader>{title || 'Related Sectors'}</CardHeader>
      <CardBody>
        <small>
          <ul className={'pl-3'}>
            {sectors.slice(0, 10).map((t) => (
              <li key={t.code}>
                <Link to={paths.VIEW_SECTOR.replace(':id', t.code)}>{t.name}</Link>
              </li>
            ))}
          </ul>
        </small>
      </CardBody>
    </Card>
  ) : null;
};

export default SectorList;
