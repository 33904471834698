import React from 'react';
import { ButtonGroup, Button } from 'reactstrap';

import { PageData } from './PageData';

export interface PaginatorProps {
  skip: number;
  limit: number;
  total: number;
  onPageChange: (newPage: number) => any;
}

export const PaginatorLite = (props: PaginatorProps) => {
  const { skip, limit, total, onPageChange } = props;

  const pages = new PageData({ skip, limit }, total, true);

  return pages && pages?.total && pages.total > 1 ? (
    <ButtonGroup size="sm">
      {pages.range[0] > 1 && (
        <Button
          color="light"
          active={pages.current === 1}
          style={{ marginBottom: 0 }}
          onClick={() => {
            onPageChange(1);
          }}
        >
          1
        </Button>
      )}
      {pages.range[0] > 2 && (
        <Button disabled color="light" style={{ marginBottom: 0 }}>
          ...
        </Button>
      )}
      {pages.range.map((pageNumber: any) => {
        return (
          <Button
            color="light"
            key={pageNumber}
            active={pages.current === pageNumber}
            style={{ marginBottom: 0 }}
            onClick={() => {
              onPageChange(pageNumber);
            }}
          >
            {pageNumber}
          </Button>
        );
      })}
      {pages.range[pages.range.length - 1] < pages.total - 1 && (
        <Button disabled color="light" style={{ marginBottom: 0 }}>
          ...
        </Button>
      )}
      {pages.range[pages.range.length - 1] < pages.total && (
        <Button
          color="light"
          active={pages.current === pages.total}
          style={{ marginBottom: 0 }}
          onClick={() => {
            onPageChange(pages.total);
          }}
        >
          {pages.total}
        </Button>
      )}
    </ButtonGroup>
  ) : null;
};
