import { accessToken } from '../utils/Tokens';

import config from '../config';
import { API_REQUEST_NOT_AUTHORIZED, LOGIN, LOGOUT, NEW_ACCESS_TOKEN_RECEIVED, SEND_LOGIN_EMAIL } from '../actions/actionTypes';
import { ApiError, AuthUser } from 'tcf-shared/models';

export interface AuthState {
  accessToken?: string;
  error?: string;
  isLoggingIn: boolean;
  authUser?: AuthUser;
}

const getLoggedOutState = () => ({
  isLoggingIn: false,
  error: '',
});

const getUserFromAccessToken = () => {
  const token = accessToken.decode();
  return (
    (token && {
      tokenVersion: token.tokenVersion,
      id: token.sub,
      email: token.email,
      firstName: token.firstName,
      lastName: token.lastName,
      organizationId: token.organizationId,
      subscription: token.subscription,
      categoryIds: token.categoryIds,
      regionIds: token.regionIds,
      sectorCodes: token.sectorCodes,
      noDownload: token.noDownload,

      canViewDecisionMakers: !!token.canViewDecisionMakers,
      canManageDirectory: !!(config.TCF_ENV !== 'prod' && token.canManageDirectory), // Disable in prod
      canManageCalendar: !!token.canManageCalendar,
      canManageOrganization: !!(token.canManageOrganization && token.organizationId),

      isPremiumSubscriber: token.subscription === 'premium' || !token.tokenVersion, // Treat tokens created before versioning was introduced as premium.  This can go away once we're sure all tokens have been refreshed (after 2/15/2024 or so).
      isAdmin: !!token.scopes?.admin,
      isStaff: !!(token.scopes?.admin || token.scopes?.staff),
    }) ||
    undefined
  );
};

const getInitialState = (): AuthState => {
  try {
    if (config.DISABLE_AUTHENTICATION_IN_DEV) {
      return {
        ...getLoggedOutState(),
        authUser: {
          id: '123',
          email: 'jane@example.com',
          canViewDecisionMakers: true,
          canManageDirectory: true,
          canManageCalendar: true,
          canManageOrganization: true,
          isPremiumSubscriber: true,
          isAdmin: true,
          isStaff: true,
        },
      };
    }

    return {
      ...getLoggedOutState(),
      accessToken: accessToken.get(),
      authUser: getUserFromAccessToken(),
    };
  } catch (err) {
    // tslint:disable-next-line:no-console
    console.log('Error loading initial auth state.', err);
    return getLoggedOutState();
  }
};

export const authReducer = (state = getInitialState(), action: any): AuthState => {
  let error;

  switch (action.type) {
    case SEND_LOGIN_EMAIL.REQUESTED:
    case SEND_LOGIN_EMAIL.SUCCEEDED:
      return {
        ...getLoggedOutState(),
      };

    case LOGIN.REQUESTED:
      return {
        ...state,
        isLoggingIn: true,
      };

    case LOGIN.SUCCEEDED:
      return {
        ...state,
        isLoggingIn: false,
      };

    case NEW_ACCESS_TOKEN_RECEIVED:
      return {
        ...getLoggedOutState(),
        accessToken: accessToken.get(),
        authUser: getUserFromAccessToken(),
      };

    case API_REQUEST_NOT_AUTHORIZED:
      error = action.payload.error as ApiError;
      return {
        ...getLoggedOutState(),
        accessToken: accessToken.get(),
        authUser: getUserFromAccessToken(),
        error: `${error ? `${error.name}: ${error.message}` : 'no message'}`,
      };

    case LOGIN.FAILED:
      error = action.payload as ApiError;
      return {
        ...getLoggedOutState(),
        accessToken: accessToken.get(),
        authUser: getUserFromAccessToken(),
        error: `${error ? `${error.name}: ${error.message}` : 'no message'}`,
      };

    case LOGOUT.SUCCEEDED:
    case LOGOUT.FAILED:
      return {
        ...getLoggedOutState(),
      };

    default:
      return state;
  }
};

export const getAuthUser = (state: { auth: AuthState }) => {
  return state.auth.authUser;
};

export const getAuthError = (state: { auth: AuthState }) => {
  return state.auth.error;
};

export const getIsLoggingIn = (state: { auth: AuthState }) => {
  return state.auth.isLoggingIn;
};
