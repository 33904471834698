export { addToArrayIfMissing, arrayIsSubset, arraysAreEquivalent, removeFromArrayIfPresent } from './arrays';
export { changeFromTimezone, changeToTimezone } from './changeTimezone';
export { humanizeBytes } from './humanizeBytes';
export { shortenFileName } from './shortenFileName';

export const getUserIdFromQueryString = (search?: string) => {
  // eslint-disable-next-line no-restricted-globals
  const startIndex = (search && search.indexOf('u=')) || -1;
  if (startIndex < 0) {
    return;
  }
  const endIndex = search!.indexOf('&', startIndex);
  return search!.substring(startIndex + 2, endIndex > 0 ? endIndex : undefined);
};

export const qsToObject = (qs: string, keyWhitelist: string[] = []) => {
  qs = qs.startsWith('?') ? qs.substring(1) : qs;
  const result = {};
  qs.split('&')
    .map((pair) => pair.split('='))
    .forEach((pair) => {
      if (pair.length === 2 && pair[0].length >= 1) {
        const key = decodeURIComponent(pair[0]);
        keyWhitelist.some((k) => k === key);
        result[key] = decodeURIComponent(pair[1]);
      }
    });
  return result;
};

export const objectToQs = (obj: {}) => {
  let result = '';
  Object.keys(obj)
    .filter((key) => obj[key])
    .map((key) => `${encodeURIComponent(key)}=${encodeURIComponent(obj[key])}`)
    .forEach((pair) => {
      result += (result === '' ? '?' : '&') + pair;
    });
  return result;
};

export const sleep = (time: number) => {
  return new Promise((resolve) => setTimeout(resolve, time));
};

export const isValidDateObject = (dateCandidate?: unknown) => {
  // So, as it turns out, Date objects can be invalid.  Checking if something is a Date is not sufficient.  We
  // also need to confirm that it contains an actual date value.  As far as I can tell, getTime() is a good
  // way to do this.  The value will be NaN if the date is invalid.
  return !!(dateCandidate && dateCandidate instanceof Date && !isNaN(dateCandidate.getTime()));
};
