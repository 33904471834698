import React, { useCallback, useEffect, useRef, useState } from 'react';
import debounce from 'lodash/debounce';

interface KeywordsFilterProps {
  keywords?: string;
  placeholder?: string;
  onChange: (value: string) => void;
  onBlur?: (event: React.FocusEvent<HTMLInputElement>) => void;
  className?: string;
  noFill?: boolean;
  setFocusOnMount?: boolean;
}

const KeywordsFilter = (props: KeywordsFilterProps) => {
  const { placeholder, onChange, onBlur, noFill } = props;
  const className = `${noFill ? '' : 'flex-fill form-control '}prop-field ${props.className || ''}`;
  const keywords = props.keywords ?? '';
  const [localKeywords, setLocalKeywords] = useState(keywords);

  // Catch external reset.
  useEffect(() => {
    if (!keywords) {
      setLocalKeywords('');
    }
  }, [keywords]);

  const debounceKeywords = useCallback(
    debounce((newKeywords: string) => onChange(newKeywords), 700),
    [onChange],
  );

  const keywordsChanged = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newKeywords = event.target.value;
    setLocalKeywords(newKeywords);
    debounceKeywords(newKeywords);
  };

  const inputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    // Set focus on the input field when the component mounts
    if (props.setFocusOnMount != null && props.setFocusOnMount && inputRef.current) {
      inputRef.current.focus();
    }
  }, [props.setFocusOnMount]);

  return (
    <input
      type="text"
      placeholder={placeholder ?? 'Enter search terms'}
      onChange={keywordsChanged}
      value={localKeywords}
      className={className}
      onBlur={onBlur}
      ref={inputRef}
    />
  );
};

export default KeywordsFilter;
