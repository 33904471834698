import { DirectoryEvidenceType, Query } from 'tcf-shared/models';

import { READ_SERVER, SAVE_SERVER_UPDATE_LIST, DELETE_SERVER_UPDATE_LIST } from './actionTypes';
import { library } from '../libraryApi';
import { asyncAction } from './asyncAction';
import { toast } from './toastActions';

export function searchDirectoryEvidenceType(storeIdentifier: string, query: Query) {
  return asyncAction({
    actionType: READ_SERVER,
    storeIdentifier,
    func: async () => (await library.directoryEvidenceType.search(query)).data,
  });
}

export function createDirectoryEvidenceType(storeIdentifier: string, directoryEvidenceType: Partial<DirectoryEvidenceType>) {
  return asyncAction({
    actionType: SAVE_SERVER_UPDATE_LIST,
    storeIdentifier,
    func: async () => (await library.directoryEvidenceType.post(directoryEvidenceType)).data,
    onSucceeded: async (result, dispatch) =>
      dispatch(
        toast.showSucceeded({
          title: 'Evidence type created',
          message: 'Evidence type created.',
        }),
      ),
    onFailed: async (result, dispatch) =>
      dispatch(
        toast.showFailed({
          title: 'Error',
          message:
            'Failed to create evidence type.  Refresh the page and try again.  If the issue persists, please contact support.',
        }),
      ),
  });
}

export function updateDirectoryEvidenceType(
  storeIdentifier: string,
  directoryEvidenceId: string,
  directoryEvidenceType: Partial<DirectoryEvidenceType>,
) {
  return asyncAction({
    actionType: SAVE_SERVER_UPDATE_LIST,
    storeIdentifier,
    func: async () => (await library.directoryEvidenceType.put(directoryEvidenceId, directoryEvidenceType)).data,
    onSucceeded: async (result, dispatch) =>
      dispatch(
        toast.showSucceeded({
          title: 'Evidence type updated',
          message: 'Evidence type updated.',
        }),
      ),
    onFailed: async (result, dispatch) =>
      dispatch(
        toast.showFailed({
          title: 'Error',
          message:
            'Evidence type was not updated correctly.  Refresh the page and try ' +
            'again.  If the issue persists, please contact support.',
        }),
      ),
  });
}

export function deleteDirectoryEvidenceType(storeIdentifier: string, id: string, updatedAt: Date | string) {
  return asyncAction({
    actionType: DELETE_SERVER_UPDATE_LIST,
    storeIdentifier,
    func: async () => (await library.directoryEvidenceType.delete({ id, updatedAt })).data,
    onSucceeded: async (result, dispatch) =>
      dispatch(
        toast.showSucceeded({
          title: 'Evidence type deleted.',
          message: 'Evidence type deleted.',
        }),
      ),
    onFailed: async (result, dispatch) =>
      dispatch(
        toast.showFailed({
          title: 'Error.',
          message:
            'Unable to delete evidence type.  Refresh the page and try again.  If the issue persists, please contact support.',
        }),
      ),
    deleteId: id,
  });
}
