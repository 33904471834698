import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Button, Card, CardBody, CardFooter, CardHeader, Col, Table } from 'reactstrap';

import { DirectoryEvidence, DirectoryEvidenceAffinity, DirectoryEvidenceType, Query } from 'tcf-shared/models';

import { useAppDispatch, useAppSelector } from '../../../utils/hooks';
import { searchDirectoryEvidence } from '../../../actions/directoryEvidenceActions';
import { PaginatorLite } from '../../../components';
import { resetServerStore } from '../../../actions/serverStoreActions';

const iconStyle: React.CSSProperties = {
  height: '1.25rem',
  width: '1.25rem',
};

const formatTitle = (effectiveDate?: string, type?: string, title?: string) => {
  const r = [effectiveDate, type, title].filter(Boolean).join(', ');
  return r || 'No date or title provided';
};

const hawkDove = (affinity?: DirectoryEvidenceAffinity) => {
  const a = affinity ?? 'unknown';
  const f = `${a}.svg`;
  return <img src={`/affinities/${f}`} alt={a} title={a} style={iconStyle} />;
};

interface OwnProps {
  parentEntityId: string;
  evidenceStoreId: string;
  evidenceType?: DirectoryEvidenceType;
  handleClickEvidence: (directoryEvidence: DirectoryEvidence) => void;
  handleClickAddNew?: () => void;
  rows?: number;
  showType?: boolean;
  colWidths?: {};
}

const EvidenceListCard = (props: OwnProps) => {
  const dispatch = useAppDispatch();

  const {
    parentEntityId,
    evidenceStoreId,
    evidenceType,
    handleClickEvidence,
    handleClickAddNew,
    rows = 10,
    showType,
    colWidths = { xs: '12' },
  } = props;

  const qry: Query = {
    q: '*',
    limit: rows,
    skip: 0,
    filters: { directoryEntityId: parentEntityId },
  };
  if (evidenceType?.id) qry.filters!['type.id'] = evidenceType.id;
  const [query, setQuery] = useState<Query>(qry);

  useEffect(() => {
    dispatch(searchDirectoryEvidence(evidenceStoreId, query));
    return () => {
      dispatch(resetServerStore(evidenceStoreId));
    };
  }, [dispatch, evidenceStoreId, query]);

  const directoryEvidenceState = useAppSelector((state) => state?.serverStores?.[evidenceStoreId] ?? {});
  const total = directoryEvidenceState?.payload?.total ?? 0;
  const evidence: DirectoryEvidence[] = directoryEvidenceState?.payload?.results ?? [];

  const handleClickRow = useCallback(
    (directoryEvidenceId: string) => {
      const directoryEvidence = evidence.find((f: DirectoryEvidence) => f.id === directoryEvidenceId);
      if (directoryEvidence) {
        handleClickEvidence(directoryEvidence);
      }
    },
    [evidence, handleClickEvidence],
  );

  const handlePageChange = (newPage: number) => {
    setQuery((q) => ({ ...q, skip: (newPage - 1) * rows }));
  };

  const renderInnards = useMemo(() => {
    if (!evidence.length) return <>None</>;
    return (
      <Table striped size="sm" style={{ marginBottom: 0 }}>
        <tbody>
          {evidence.map((f) => (
            <tr key={f.id} onClick={() => handleClickRow(f.id)} className="clickable" style={{ color: '#007bff' }}>
              <td>{hawkDove(f.affinity)}</td>
              <td style={{ width: '99%' }}>{formatTitle(f.effectiveDate, showType ? f.type?.title : undefined, f.title)}</td>
            </tr>
          ))}
        </tbody>
      </Table>
    );
  }, [evidence, handleClickRow, showType]);

  // TODO: Fix this.  This is a horrible side effect.  Probably should have an explicit "show if empty" flag.
  if (!evidence?.length && !handleClickAddNew) return null;

  return (
    <Col {...colWidths} style={{ marginBottom: '2em' }} key={evidenceStoreId}>
      <Card>
        <CardHeader>
          {handleClickAddNew ? (
            <Button color="primary" className="mb-0" title="Add Evidence" onClick={handleClickAddNew}>
              Add Evidence
            </Button>
          ) : (
            evidenceType?.title ?? 'Evidence'
          )}
        </CardHeader>
        <CardBody style={{ fontSize: '14px', minHeight: '7em' }}>{renderInnards}</CardBody>
        {total > rows ? (
          <CardFooter>
            <PaginatorLite skip={query.skip ?? 0} limit={query.limit ?? 0} total={total} onPageChange={handlePageChange} />
          </CardFooter>
        ) : null}
      </Card>
    </Col>
  );
};

export default EvidenceListCard;
