import React, { useCallback, useEffect, useState } from 'react';
import debounce from 'lodash/debounce';

import { Query } from 'tcf-shared/models';

import OrganizationSelfManagementPage from './components/Page';
import { OrganizationSelfManagementState } from '../../reducers/organizationSelfManagementReducer';
import {
  readSMLicenseCount,
  resetSMLicenseCount,
  resetSMUsersStore,
  searchSMUsers,
} from '../../actions/organizationSelfManagementActions';
import { useAppDispatch, useAppSelector } from '../../utils/hooks';

const LICENSE_STORE_ID = 'OrganizationSelfManagementLicenseCount';
const DEFAULT_QUERY_LIMIT = 50;

const OrganizationSelfManagement = () => {
  const dispatch = useAppDispatch();

  useEffect(() => {
    window.scrollTo(0, 0);
    dispatch(readSMLicenseCount(LICENSE_STORE_ID));
    return () => {
      dispatch(resetSMUsersStore());
      dispatch(resetSMLicenseCount(LICENSE_STORE_ID));
    };
  }, [dispatch]);

  const [searchText, setSearchText] = useState('');
  const [query, setQuery] = useState<Query>({ skip: 0, limit: DEFAULT_QUERY_LIMIT });

  const searchState: OrganizationSelfManagementState = useAppSelector((state) => state?.organizationSelfManagement);
  const licenseState = useAppSelector((state) => state?.serverStores?.[LICENSE_STORE_ID]);

  const debounceSetQuerySearchText = useCallback(
    debounce((newSearchText: string) => {
      setQuery((q) => ({ ...q, skip: 0, filters: { ...q.filters, prefixKeywords: { keywords: newSearchText } } }));
    }, 700),
    [],
  );

  const handleSearchTextChanged = (newSearchText: string) => {
    setSearchText(newSearchText);
    debounceSetQuerySearchText(newSearchText);
  };

  const queryString = JSON.stringify(query);
  useEffect(() => {
    const q = JSON.parse(queryString);
    dispatch(searchSMUsers(q));
  }, [queryString, dispatch]);

  const handlePageOrSortChange = (newQuery: Query) => {
    setQuery((q) => ({ ...q, ...newQuery }));
  };

  return (
    <OrganizationSelfManagementPage
      licenseStoreId={LICENSE_STORE_ID}
      searchState={searchState}
      licenseState={licenseState}
      searchText={searchText}
      query={query}
      handlePageOrSortChange={handlePageOrSortChange}
      handleSearchTextChanged={handleSearchTextChanged}
    />
  );
};

export default OrganizationSelfManagement;
