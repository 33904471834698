import { useState } from 'react';

export const useAccordionManager = (defaultClosedAccordionIds?: string[]) => {
  const [closedAccordionIds, setClosedAccordionIds] = useState<string[]>(defaultClosedAccordionIds ?? []);

  const isAccordionOpen = (accordionId: string) => {
    return !closedAccordionIds.includes(accordionId);
  };

  const toggleAccordion = (accordionId: string) => {
    setClosedAccordionIds((oldClosedAccordionIds) =>
      oldClosedAccordionIds.includes(accordionId)
        ? oldClosedAccordionIds.filter((id) => id !== accordionId)
        : [...oldClosedAccordionIds, accordionId],
    );
  };

  return { toggleAccordion, isAccordionOpen, closedAccordionIds };
};
