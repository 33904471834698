import React from 'react';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter, Form } from 'reactstrap';

import DownloadLink from './DownloadLink';
import { DocumentRelated } from '../../../reducers/documentsReducer';

// Copied from reactstrap because it doesn't export this.
interface ModalProps {
  isOpen?: boolean;
  toggle: () => void;
  // Other model props are not currently used.
}

type DownloadDialogProps = ModalProps & {
  document: any;
  documentRelated?: DocumentRelated;
};

export const DownloadDialog = (props: DownloadDialogProps) => {
  const { isOpen, toggle, document, documentRelated } = props;
  const modalProps = { isOpen, toggle };

  return (
    <Modal {...modalProps}>
      <Form>
        <ModalHeader toggle={toggle}>Download Article</ModalHeader>
        <ModalBody>
          &copy; {new Date().getFullYear()}&nbsp;The Capitol Forum. Direct or indirect reproduction or distribution of this
          article without prior written permission from The Capitol Forum is a violation of Federal Copyright Law.
        </ModalBody>
        <ModalFooter>
          <Button color="secondary" onClick={toggle}>
            Cancel
          </Button>
          &nbsp;
          <DownloadLink document={document} documentRelated={documentRelated} onCompletion={toggle} label="Continue" />
        </ModalFooter>
      </Form>
    </Modal>
  );
};
