import { ApiError } from 'tcf-shared/models';
import { API_REQUEST_NOT_AUTHORIZED, NEW_ACCESS_TOKEN_RECEIVED } from '../actions/actionTypes';
import { accessToken } from '../utils/Tokens';

export const newAccessTokenReceived = (token: string) => {
  accessToken.set(token);
  return { type: NEW_ACCESS_TOKEN_RECEIVED, payload: { token } };
};

export const apiRequestNotAuthorized = (apiError: ApiError, forceLogout: boolean = true) => {
  if (forceLogout) {
    accessToken.delete();
  }
  return {
    type: API_REQUEST_NOT_AUTHORIZED,
    error: true,
    payload: { error: apiError },
  };
};
