import React, { useCallback, useEffect, useState } from 'react';
import { Button, Col, Input, Row, Table } from 'reactstrap';
import { Link, useHistory } from 'react-router-dom';
import debounce from 'lodash/debounce';

import { DirectoryEntity, Query } from 'tcf-shared/models';

import AsyncPage from '../AsyncPage/AsyncPage';
import { searchDirectoryEntity } from '../../actions/directoryEntityActions';
import { resetServerStore } from '../../actions/serverStoreActions';
import DocumentListPaginator from '../Document/components/DocumentListPaginator';
import { useAppDispatch, useAppSelector, useDelayedRender } from '../../utils/hooks';
import { paths } from '../../paths';

const LIST_DIRECTORY_ENTITIES_STORE = 'DirectoryEntityList';
const PAGE_SIZE = 30;

interface OwnProps {
  decisionMakersOnly: boolean;
}

const EntityList = (props: OwnProps) => {
  const dispatch = useAppDispatch();
  const history = useHistory();

  const { decisionMakersOnly } = props;

  const searchPayload = useAppSelector((state) => state?.serverStores?.[LIST_DIRECTORY_ENTITIES_STORE]);
  const error = searchPayload?.error;
  const isFetching = searchPayload?.isFetching;
  const total = searchPayload?.payload?.total;
  const results = searchPayload?.payload?.results;

  const authUser = useAppSelector((state) => state.auth.authUser);
  const canManageDirectory = authUser?.canManageDirectory;

  const [searchText, setSearchText] = useState('');

  const [query, setQuery] = useState<Query>({
    q: '*',
    limit: PAGE_SIZE,
    skip: 0,
    filters: { ...(decisionMakersOnly ? { decisionMaker: true } : {}) },
  });

  const debounceSetQuerySearchText = useCallback(
    debounce((newSearchText: string) => {
      setQuery((q) => ({ ...q, skip: 0, filters: { ...q.filters, prefixKeywords: { keywords: newSearchText } } }));
    }, 700),
    [],
  );

  const handleSearchTextChanged = (newSearchText: string) => {
    setSearchText(newSearchText);
    debounceSetQuerySearchText(newSearchText);
  };

  const handlePageChange = ({ skip }: { skip?: number }) => {
    setQuery((q) => ({ ...q, skip: skip ?? 0 }));
  };

  const delayRenderingLoader = useDelayedRender(isFetching);

  useEffect(() => {
    return () => {
      dispatch(resetServerStore(LIST_DIRECTORY_ENTITIES_STORE));
    };
  }, [dispatch]);

  useEffect(() => {
    setQuery((q) => {
      const currentFilters = q.filters ?? {};
      const { decisionMaker, ...newFilters } = currentFilters;
      if (decisionMakersOnly) {
        newFilters.decisionMaker = true;
      }
      return { ...q, skip: 0, filters: { ...newFilters } };
    });
  }, [decisionMakersOnly]);

  const queryString = JSON.stringify(query);
  useEffect(() => {
    const q = JSON.parse(queryString);
    dispatch(searchDirectoryEntity(LIST_DIRECTORY_ENTITIES_STORE, q));
  }, [queryString, dispatch]);

  const paginator = (
    <DocumentListPaginator
      itemCount={total}
      query={query}
      showPerPage={false}
      onPageChange={handlePageChange}
      small={true}
      buttonGroupClassName="float-md-right"
    />
  );

  const handleEditClick = (evt: any) => {
    evt.stopPropagation();
    if (evt?.target?.id) {
      history.push(paths.admin.EDIT_DIRECTORY_ENTITY.replace(':id', evt.target.id));
    }
  };

  const handleClickEntity = (directoryEntityId: string) => {
    if (directoryEntityId) {
      history.push(paths.VIEW_DIRECTORY_ENTITY.replace(':id', directoryEntityId));
    }
  };

  const buildRow = (entity: DirectoryEntity) => {
    const hasEvidence = (entity as any).hasEvidence;

    const innards = [
      <td key="name">{entity.name}</td>,
      <td key="title">{entity.title}</td>,
      <td key="agency">{entity.agency}</td>,
      <td key="department">{entity.department}</td>,
      <td key="phone">{entity.phone}</td>,
      <td key="hasEvidence">{hasEvidence ? '📅' : null}</td>,
    ];

    if (canManageDirectory) {
      innards.push(
        <td key="edit">
          <Button
            id={entity.id}
            size="sm"
            color="link"
            title="Edit alias"
            className="tableButton float-right"
            style={{ padding: 0, margin: 0 }}
            onClick={handleEditClick}
          >
            EDIT
          </Button>
        </td>,
      );
    }

    return (
      <tr key={entity.id} onClick={() => handleClickEntity(entity.id)} className="clickable">
        {innards}
      </tr>
    );
  };

  return (
    <AsyncPage title="" loading={isFetching && !delayRenderingLoader} error={error}>
      <Row>
        <Col md="4" className="mt-2">
          <Input
            autoComplete="off"
            type="text"
            name="searchText"
            id="searchText"
            placeholder="Search..."
            value={searchText}
            style={{ display: 'inline', width: 'auto' }}
            onChange={(evt) => handleSearchTextChanged(evt.target.value)}
          />
          {total > 0 ? <small style={{ marginLeft: '0.5rem' }}>{total.toLocaleString()} matches</small> : null}
        </Col>
        <Col md="4" className="mt-2">
          {/* TODO: Make this better -- I don't like the placement */}
          {canManageDirectory && (
            <Link className="btn btn-secondary float-md-middle" to={paths.admin.EDIT_DIRECTORY_ENTITY.replace(':id', 'new')}>
              Create new entity
            </Link>
          )}
        </Col>
        <Col md="4" className="mt-2">
          {paginator}
        </Col>
      </Row>
      <div className="directory-entity mt-3">
        {results?.length ? (
          <Table className="noselect">
            <tbody>
              <tr key="header">
                <th>Name</th>
                <th>Title</th>
                <th>Agency</th>
                <th>Department</th>
                <th>Phone</th>
                <th />
                {canManageDirectory ? <th style={{ width: '3rem' }} /> : null}
              </tr>
              {results.map((s: DirectoryEntity) => buildRow(s))}
            </tbody>
          </Table>
        ) : (
          <>No matches</>
        )}
      </div>
      <Row className="my-3">
        <Col>{paginator}</Col>
      </Row>
    </AsyncPage>
  );
};

export default EntityList;
