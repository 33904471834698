import { AxiosInstance } from 'axios';
import { ApiResponse } from 'tcf-shared/models';
import { CrudApiClient } from './CrudApiClient';

export class OrganizationsApiClient extends CrudApiClient {
  constructor(readonly axios: AxiosInstance) {
    super(axios, '/organizations');
  }

  async getEfficyProjects(id: string): Promise<ApiResponse> {
    return this.axios.get<ApiResponse>(`${this.baseUrl}/${id}/efficy/projects`);
  }
}
