import { CalendarEvent, Query } from 'tcf-shared/models';

import { CastDates } from './castDates';
import { READ_SERVER, SAVE_SERVER, DELETE_SERVER } from './actionTypes';
import { asyncAction } from './asyncAction';
import { library } from '../libraryApi';
import { toast } from './toastActions';

const cd = new CastDates<CalendarEvent>((o) => ({
  ...o,
  earliestDateTime: o.earliestDateTime ? new Date(o.earliestDateTime) : o.earliestDateTime,
  latestDateTime: o.latestDateTime ? new Date(o.latestDateTime) : o.latestDateTime,
  datesTimes: (o.datesTimes ?? []).map((dt) => ({
    ...dt,
    startDateTime: dt.startDateTime ? new Date(dt.startDateTime) : dt.startDateTime,
  })),
  meta: {
    ...(o.meta ?? {}),
    createdAt: o.meta?.createdAt ? new Date(o.meta.createdAt) : o.meta?.createdAt,
    updatedAt: o.meta?.updatedAt ? new Date(o.meta.updatedAt) : o.meta?.updatedAt,
  },
}));

export function getCalendarEvent(storeIdentifier: string, id: string) {
  return asyncAction({
    actionType: READ_SERVER,
    func: async () => cd.castDates((await library.calendarEvent.get(id)).data),
    storeIdentifier,
  });
}

export function searchCalendarEvent(storeIdentifier: string, query: Query) {
  return asyncAction({
    actionType: READ_SERVER,
    storeIdentifier,
    func: async () => cd.castAllDates((await library.calendarEvent.search(query)).data),
  });
}

export function saveCalendarEvent(storeIdentifier: string, calendarEvent: Partial<CalendarEvent>) {
  const func = calendarEvent.id
    ? async () => cd.castDates((await library.calendarEvent.put(calendarEvent.id!, calendarEvent)).data)
    : async () => cd.castDates((await library.calendarEvent.post(calendarEvent)).data);
  return asyncAction({
    actionType: SAVE_SERVER,
    storeIdentifier,
    func,
    onSucceeded: async (result, dispatch) =>
      dispatch(
        toast.showSucceeded({
          title: 'Event saved',
          message: 'Event saved.',
        }),
      ),
    onFailed: async (result, dispatch) =>
      dispatch(
        toast.showFailed({
          title: 'Error',
          message:
            'Event was not saved correctly.  Refresh the page and try ' +
            'again.  If the issue persists, please contact support.',
        }),
      ),
  });
}

export function deleteCalendarEvent(storeIdentifier: string, id: string, updatedAt: Date | string) {
  const _updatedAt = updatedAt instanceof Date ? updatedAt.toISOString() : updatedAt;
  return asyncAction({
    actionType: DELETE_SERVER,
    storeIdentifier,
    func: async () => (await library.calendarEvent.delete({ id, updatedAt: _updatedAt })).data,
    onSucceeded: async (result, dispatch) =>
      dispatch(
        toast.showSucceeded({
          title: 'Event deleted.',
          message: 'Event deleted.',
        }),
      ),
    onFailed: async (result, dispatch) =>
      dispatch(
        toast.showFailed({
          title: 'Error.',
          message: 'Unable to delete event.  Refresh the page and try again.  If the issue persists, please contact support.',
        }),
      ),
    deleteId: id,
  });
}
