import React, { useEffect, useState } from 'react';
import { Button, Input, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';

import { User } from 'tcf-shared/models';

import { ConfirmDialog } from '../ConfirmDialog';
import { getAccountManager, sendSupportMessage } from '../../actions/userActions';
import { getAuthUser } from '../../reducers/authReducer';
import { useAppDispatch, useAppSelector, usePopupToggler } from '../../utils/hooks';

interface OwnProps {
  closeModal: () => void;
}

const noWrap: React.CSSProperties = {
  display: 'inline-block',
  maxWidth: '100%',
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
};

const MAX_LENGTH = 4000;
const STORE_ID = 'ContactSupportAccountManager';

const formatName = (user?: { firstName?: string; lastName?: string }) =>
  [(user?.firstName || '').trim(), (user?.lastName || '').trim()].filter(Boolean).join(' ');

const formatEmail = (name?: string, email?: string) => (email ? (name ? `${name} <${email}>` : email) : '');

const ContactSupport = ({ closeModal }: OwnProps) => {
  const dispatch = useAppDispatch();

  const popUpToggler = usePopupToggler<{ title: string; body: string; toggle: () => void }>();

  const [submitting, setSubmitting] = useState(false);
  const [value, setValue] = useState('');
  const valueSize = (value.match(/\S/g) || []).length;

  const authUser = useAppSelector((s) => getAuthUser(s));
  const userName = formatName(authUser);
  const userEmail = formatEmail(userName, authUser?.email) || 'You';

  const accountManager = useAppSelector<Partial<User>>((state) => state.serverStores?.[STORE_ID]?.payload);
  const accountManagerName = formatName(accountManager);
  const accountManagerEmail = formatEmail(accountManagerName, accountManager?.email) || 'Your Account Manager';

  useEffect(() => {
    dispatch(getAccountManager(STORE_ID));
  }, [dispatch]);

  const closePopUp = () => {
    popUpToggler.close();
    setSubmitting(false);
  };

  const sendMessage = async () => {
    setSubmitting(true);
    try {
      await dispatch(sendSupportMessage(value));
      popUpToggler.open({
        title: 'Message Sent',
        body: 'Thank you for your message.  Your account manager will reach out if there are further questions.',
        toggle: closeModal,
      });
    } catch {
      popUpToggler.open({
        title: 'Problem Sending Message',
        body: 'There was a problem sending your message.  Please try again or, if the issue persists, give us a call at +1 (888) 290-3445.',
        toggle: closePopUp,
      });
    }
  };

  if (!authUser) return null;

  return (
    <>
      <Modal isOpen={true} backdrop={true} toggle={closeModal} size="md">
        <ModalHeader
          tag="span"
          style={{ color: 'white', backgroundColor: '#607194' }}
          close={
            <span>
              <span
                className="close clickable"
                onClick={closeModal}
                style={{ color: '#FFFFFF', opacity: '.9', textShadow: 'none', lineHeight: '1rem' }}
              >
                x
              </span>
            </span>
          }
          toggle={closeModal}
        >
          Contact Support
        </ModalHeader>
        <ModalBody>
          For support, fill out the form below and click Submit. For immediate support, we can be reached at{' '}
          <a href="tel:+18882903445">+1 (888) 290-3445</a>.
          <br />
          <br />
          <span style={noWrap}>From:&nbsp; {userEmail}</span>
          <br />
          <span style={noWrap}>To:&nbsp; {accountManagerEmail}</span>
          <br />
          <br />
          <Input
            type="textarea"
            maxLength={MAX_LENGTH}
            value={value}
            onChange={(evt) => setValue(evt.target.value)}
            style={{ height: '10em' }}
          />
          <div className="text-right pt-2" style={{ fontSize: '0.9rem' }}>
            {(MAX_LENGTH - value.length).toLocaleString()} characters remaining
          </div>
        </ModalBody>
        <ModalFooter>
          <Button color="secondary" onClick={sendMessage} disabled={valueSize < 5 || submitting}>
            Submit
          </Button>
          <Button color="secondary" onClick={closeModal}>
            Close
          </Button>
        </ModalFooter>
      </Modal>
      {popUpToggler.isOpen && (
        <ConfirmDialog
          isOpen={true}
          toggle={popUpToggler.data?.toggle}
          header={popUpToggler.data?.title}
          body={popUpToggler.data?.body}
          onConfirm={popUpToggler.data?.toggle}
          confirmButtonColor="secondary"
          hideCancelButton={true}
        />
      )}
    </>
  );
};

export default ContactSupport;
