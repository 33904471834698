import React from 'react';

import { allDocumentTypeFilterOptions } from 'tcf-shared/models';

import FacetFilter from './FacetFilter';

interface DocTypeFilterProps {
  onChange?: any;
  selectedDocTypes?: string[];
}

const DocTypeFilter = (props: DocTypeFilterProps) => {
  const { onChange } = props;
  const selectedDocTypes = props.selectedDocTypes || [];

  const options = allDocumentTypeFilterOptions.map((o) => ({
    ...o,
    selected: selectedDocTypes.includes(o.value),
  }));

  const optionsChanged = (event: any) => {
    event.stopPropagation();
    const { value, checked } = event.target;
    let newOptions;
    if (checked) {
      newOptions = [...selectedDocTypes];
      newOptions.push(value);
    } else {
      newOptions = selectedDocTypes.filter((x) => x !== value);
    }
    onChange(newOptions);
  };

  return <FacetFilter title="Type" options={options} onChange={optionsChanged} />;
};

export default DocTypeFilter;
