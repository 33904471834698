import moment, { MomentInput } from 'moment';

import {
  longDateFormat,
  longDateTimeFormat,
  shortDateFormat,
  shortDateTimeFormat,
  shortDateTimeSecondFormat,
  veryLongDateTimeTZFormat,
  veryShortDateFormat,
} from '../constants/momentFormat';

export const formatCustomDate = (dateTime: MomentInput, momentFormat: string) =>
  (dateTime && momentFormat && moment(dateTime).format(momentFormat)) || '';

export const formatLongDate = (dateTime: MomentInput) => formatCustomDate(dateTime, longDateFormat);
export const formatLongDateTime = (dateTime: MomentInput) => formatCustomDate(dateTime, longDateTimeFormat);
export const formatShortDate = (dateTime: MomentInput) => formatCustomDate(dateTime, shortDateFormat);
export const formatShortDateTime = (dateTime: MomentInput) => formatCustomDate(dateTime, shortDateTimeFormat);
export const formatVeryLongDateTimeTZFormat = (dateTime: MomentInput) => formatCustomDate(dateTime, veryLongDateTimeTZFormat);
export const formatVeryShortDate = (dateTime: MomentInput) => formatCustomDate(dateTime, veryShortDateFormat);
export const formatUnixTime = (dateTime: MomentInput) =>
  formatCustomDate(typeof dateTime === 'number' ? dateTime * 1000 : dateTime, longDateTimeFormat);
export const formatShortDateTimeSecond = (dateTime: MomentInput) => formatCustomDate(dateTime, shortDateTimeSecondFormat);
