import React from 'react';
import { Container } from 'reactstrap';
import BootstrapTable from 'react-bootstrap-table-next';
import ToolkitProvider from 'react-bootstrap-table2-toolkit';
import paginationFactory from 'react-bootstrap-table2-paginator';
import moment from 'moment';
import { isString, isDate } from 'lodash';
import { Query } from 'tcf-shared/models';

export interface TcfBootstrapSearchTableProps {
  allowPaging?: boolean;
  allowWrap?: boolean;
  columns: any[];
  dataSource: any;
  defaultSorted?: any[];
  keyField?: string;
  onTableChange?: any;
  page?: number;
  remote?: boolean;
  sizePerPage?: number;
  totalSize: number;
  unlimited?: boolean;
  selectRowOptions?: any;
  sizePerPageList?: number[] | null;
  exportFileName?: string;
  expandRow?: any;
}

const MAX_SIZE = 10000;
const NOTE = `Note that paging is limited to first ${MAX_SIZE.toLocaleString()} rows based on selected sort column`;

export interface TableState {
  page: number;
  sizePerPage: number;
  sortField: string;
  sortOrder: any;
}

export const getPagingParamsForTable = (
  payload: any,
  query: Query,
  defaultPageSize: number,
  defaultSortBy: string,
  defaultSortOrder: string,
) => {
  const skip = payload?.query?.skip || query?.skip || 0;
  const limit = payload?.query?.limit || query?.limit || defaultPageSize;
  return {
    currentPage: Math.floor(skip / limit) + 1,
    limit,
    totalSize: payload?.total || 0,
    sortBy: query?.search?.sortBy || defaultSortBy,
    sortOrder: query?.search?.sortOrder || defaultSortOrder,
  };
};

export const getDefaultSorted = (sortBy: string, sortOrder: string) => {
  return sortBy && sortOrder
    ? [
        {
          dataField: sortBy,
          order: sortOrder,
        },
      ]
    : undefined;
};

export const formatNumber = (cell: number | string) =>
  (typeof cell !== 'undefined' &&
    cell !== null &&
    (typeof cell === 'string' ? cell : cell.toLocaleString('en-US', { maximumFractionDigits: 0 }))) ||
  '';

export const formatMonthYear = (cell: Date | string | null) =>
  cell && (isDate(cell) || isString(cell)) ? moment.utc(cell).format('M/YYYY') : '';

const TcfBootstrapSearchTable = (props: TcfBootstrapSearchTableProps) => {
  const {
    allowPaging,
    allowWrap,
    columns,
    dataSource,
    defaultSorted,
    keyField,
    onTableChange,
    page,
    remote,
    sizePerPage,
    totalSize,
    unlimited,
    selectRowOptions,
    sizePerPageList,
    exportFileName,
    expandRow,
  } = props;

  return (
    <div style={{ marginLeft: '-15px', marginRight: '-15px' }}>
      <Container fluid={true}>
        <div>
          {dataSource && totalSize > 0 ? (
            <ToolkitProvider
              exportCSV={{ fileName: exportFileName || '' }}
              bootstrap4
              keyField={keyField || 'id'}
              data={dataSource}
              columns={columns}
            >
              {(tprops: any) => (
                <div>
                  <BootstrapTable
                    {...tprops.baseProps}
                    remote={remote || undefined}
                    wrapperClasses={'table-responsive'}
                    rowClasses={allowWrap ? undefined : 'text-nowrap'}
                    condensed
                    bootstrap4
                    striped
                    bordered={false}
                    classes={'remoteTable'}
                    selectRow={selectRowOptions}
                    pagination={
                      allowPaging
                        ? paginationFactory({
                            page,
                            sizePerPage,
                            totalSize: unlimited ? totalSize : Math.min(totalSize, MAX_SIZE),
                            sizePerPageList: sizePerPageList ?? [5, 10, 25, 50, 100],
                            hidePageListOnlyOnePage: true,
                          })
                        : undefined
                    }
                    defaultSorted={defaultSorted}
                    onTableChange={onTableChange}
                    expandRow={expandRow || undefined}
                  />
                </div>
              )}
            </ToolkitProvider>
          ) : null}
          {allowPaging && !unlimited && totalSize >= MAX_SIZE && <div className={'font-italic'}>{NOTE}</div>}
        </div>
      </Container>
    </div>
  );
};

export default TcfBootstrapSearchTable;
