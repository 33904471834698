import * as React from 'react';
import { Redirect, useLocation } from 'react-router-dom';
import { Col, Row } from 'reactstrap';

import BasicPage from '../BasicPage/BasicPage';
import LoginForm from './components/LoginForm';
import { getIsLoggingIn, getAuthUser } from '../../reducers/authReducer';
import { SendLoginEmailProps, sendLoginEmail } from '../../actions/authActions';
import { useAppDispatch, useAppSelector } from '../../utils/hooks';

const Login = () => {
  const dispatch = useAppDispatch();

  const { state }: any = useLocation();
  const isLoggingIn = useAppSelector((appState) => getIsLoggingIn(appState));
  const authUser = useAppSelector((appState) => getAuthUser(appState));
  const redirectTo: string = (state && state.redirectTo) || undefined;
  const isSubscriber = !!authUser;

  const handleSubmit = async (payload: SendLoginEmailProps) => {
    if (payload?.email) {
      await dispatch(sendLoginEmail(payload));
    }
  };

  return (
    <BasicPage title="Login">
      <Row>
        {isSubscriber && <Redirect to="/" />}
        {!isSubscriber && !isLoggingIn && (
          <Col sm={{ size: 6, offset: 3 }}>
            <h2>Subscriber Login</h2>
            <p>Enter your email to request a login link.</p>
            <LoginForm onSubmit={handleSubmit} initialValues={{ redirectTo }} />
          </Col>
        )}
        {!isSubscriber && isLoggingIn && (
          <Col sm={{ size: 6, offset: 3 }}>
            <p>Logging in...</p>
          </Col>
        )}
      </Row>
    </BasicPage>
  );
};

export default Login;
