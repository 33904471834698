import axiosPkg, { AxiosError, AxiosRequestConfig, AxiosResponse } from 'axios';
import { Store } from 'redux';

import reduxStore from '../store';
import { apiRequestNotAuthorized, newAccessTokenReceived } from './apiActions';
import config from '../config';

export interface AuthAppState {
  auth: {
    authUser?: any;
    accessToken?: string;
  };
}

export class AxiosTokenInterceptors<AppState extends AuthAppState> {
  constructor(private store: Store<AppState>) {}

  attachTokens(aconfig: AxiosRequestConfig) {
    const { authUser, accessToken } = this.store.getState().auth;
    if (!aconfig.headers) aconfig.headers = {};
    if (authUser && accessToken) {
      aconfig.headers.Authorization = 'Bearer ' + accessToken;
    }
    if (config.CLIENT_VERSION) {
      aconfig.headers['x-tcf-client-version'] = config.CLIENT_VERSION;
    }
    return aconfig;
  }

  saveAccessToken(response: AxiosResponse) {
    const token = response.headers['x-tcf-access-token'];
    const currentVersion = response.headers['x-tcf-client-version'];

    if (token) {
      this.store.dispatch(newAccessTokenReceived(token));
    }
    if (currentVersion && currentVersion !== config.CLIENT_VERSION) {
      window.location.reload();
    }
    return response;
  }

  dispatchError(err: AxiosError) {
    const { authUser } = this.store.getState().auth;
    const response: any = err.response || {};
    const status = response.status || '';
    const econfig = err.config || null;
    const url = (econfig && econfig.url) || '';
    const downloadTokenRequest = url && url.match(/\/docs\/[^/]+\/token/);
    if (status === 401 || (status === 403 && (downloadTokenRequest || !authUser))) {
      const forceLogout = response.config.url!.indexOf('/login') < 0;
      this.store.dispatch(apiRequestNotAuthorized(response!.data, forceLogout));
    }
    return Promise.reject(err);
  }
}

export const getAxiosClient = (baseUrl: string) => {
  const axiosInstance = axiosPkg.create({ baseURL: baseUrl });
  const interceptors = new AxiosTokenInterceptors(reduxStore);

  axiosInstance.interceptors.request.use(interceptors.attachTokens.bind(interceptors));

  axiosInstance.interceptors.response.use(
    interceptors.saveAccessToken.bind(interceptors),
    interceptors.dispatchError.bind(interceptors),
  );

  return axiosInstance;
};

export const axios = getAxiosClient('/api');
