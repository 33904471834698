import React from 'react';
import { Row, Col, FormGroup, Input, Label } from 'reactstrap';
import { AuthUser, Query, CompanySearch } from 'tcf-shared/models';

import SectorFilter from '../../../components/filters/SectorFilter';

export interface OwnProps {
  authUser?: AuthUser;
  manageCompanies?: boolean;
  query: Query;
  onQueryChange: (query: Query, debounce?: boolean) => void;
}

const CompaniesFilter = (props: OwnProps) => {
  const { authUser, manageCompanies, query, onQueryChange } = props;

  const isStaffOrAdmin = authUser?.isStaff || authUser?.isAdmin;

  const search: CompanySearch = query?.search || {};
  if (!search) return null;

  const onChangeName = (event: React.ChangeEvent<HTMLInputElement>) => {
    const searchName = event.target.value;
    const cs: CompanySearch = query?.search || {};
    if (searchName) {
      cs.keywords = searchName;
    } else {
      delete cs.keywords;
    }
    query.skip = 0;
    onQueryChange(query, true);
  };

  const onChangeSearchField = (searchField: string, searchIds: string[]) => {
    // Handle changes to array of categories, regions, sectors IDs criteria
    const ds = query?.search || {};
    if (searchIds?.length) {
      // Set the array of IDs for the searchField
      query.search = { ...ds, [searchField]: searchIds };
    } else {
      // Or remove any existing value for the searchField; leaving an empty array is insufficient for determining
      // when to enable the "save search" feature.
      delete ds[searchField];
      query.search = { ...ds };
    }
    query.skip = 0;
    onQueryChange(query);
  };

  const onChangeArticles = (articles: boolean) => {
    const cs: CompanySearch = query?.search || {};
    if (articles) {
      cs.withArticlesOnly = true;
    } else {
      delete cs.withArticlesOnly;
    }
    query.skip = 0;
    onQueryChange(query);
  };

  const withArticlesOnly = search.withArticlesOnly ? true : false;

  const articleChanged = () => onChangeArticles(!withArticlesOnly);

  return (
    <>
      <input
        type="text"
        value={search.keywords ?? ''}
        onChange={onChangeName}
        placeholder="Enter search terms..."
        className="flex-fill form-control prop-field mb-3"
      />
      {manageCompanies && isStaffOrAdmin ? (
        <Row className="mb-3">
          <Col lg={6}>
            <FormGroup check>
              <Label check>
                <Input type="radio" value="1" checked={withArticlesOnly} onChange={articleChanged} />
                &nbsp;With articles
              </Label>
            </FormGroup>
          </Col>
          <Col lg={6}>
            <FormGroup check>
              <Label check>
                <Input type="radio" value="0" checked={!withArticlesOnly} onChange={articleChanged} />
                &nbsp;All companies
              </Label>
            </FormGroup>
          </Col>
        </Row>
      ) : null}
      <SectorFilter
        authUser={authUser}
        selectedSectors={search.sectors}
        onChange={(searchIds: string[]) => onChangeSearchField('sectors', searchIds)}
      />
    </>
  );
};

export default CompaniesFilter;
