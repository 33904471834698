import React, { useEffect } from 'react';
import Loader from 'react-loader';
import { Col, FormGroup, Input, Label, Row } from 'reactstrap';

import { AlertPreferences, resolveAuthUserEntitlements } from 'tcf-shared/models';

import EditDefaultAlerts from '../../../components/EditDefaultAlerts';
import ErrorComponent from '../../AsyncPage/ErrorComponent';
import { getAlertPreferences, setAlertPreferences } from '../../../actions/usersActions';
import { getAuthUser } from '../../../reducers/authReducer';
import { resetServerStore } from '../../../actions/serverStoreActions';
import { useAppDispatch, useAppSelector } from '../../../utils/hooks';

const DEFAULT_ALERT_STORE = 'defaultAlertStore';

const SelectAlertPreferences = () => {
  const dispatch = useAppDispatch();

  const authUser = useAppSelector((state) => getAuthUser(state));
  const authUserId = authUser?.id;

  const alertStore = useAppSelector((state) => state?.serverStores?.[DEFAULT_ALERT_STORE]);
  const isEmailSuppressed = alertStore?.payload?.isEmailSuppressed;
  const optOutOfDigestAlerts = alertStore?.payload?.optOutOfDigestAlerts;
  const optOutOfAnnouncements = alertStore?.payload?.optOutOfAnnouncements;

  const isFetching = alertStore?.isFetching;

  const error = alertStore?.error;

  const resolvedUserEntitlements = resolveAuthUserEntitlements(authUser);

  useEffect(() => {
    if (authUserId) {
      dispatch(getAlertPreferences(DEFAULT_ALERT_STORE, authUserId));
    }
    return () => {
      dispatch(resetServerStore(DEFAULT_ALERT_STORE));
    };
  }, [authUserId, dispatch]);

  if (!authUserId) return null;
  if (error) return <ErrorComponent error="Error retrieving record." />;
  if (isFetching) return <Loader loaded={false} />;

  const handleDigestAlertChanged = () => {
    dispatch(setAlertPreferences(DEFAULT_ALERT_STORE, authUserId, { optOutOfDigestAlerts: !optOutOfDigestAlerts }));
  };

  const handleAnnouncementsChanged = () => {
    dispatch(setAlertPreferences(DEFAULT_ALERT_STORE, authUserId, { optOutOfAnnouncements: !optOutOfAnnouncements }));
  };

  const handleDefaultAlertsChanged = (alertPreferences: AlertPreferences) => {
    dispatch(setAlertPreferences(DEFAULT_ALERT_STORE, authUserId, alertPreferences));
  };

  return (
    <>
      {isEmailSuppressed && (
        <div className="my-3 text-danger font-weight-bold">
          Your email alerts are currently on hold due to excessive bouncebacks from your email provider. Please contact your
          account manager to sort this issue out. Thank you!
        </div>
      )}
      <div className={'my-3'}>Which email types would you like to receive?</div>
      <Row>
        <Col xs={12} className="my-1 ml-3">
          <FormGroup check inline>
            <Input
              type="checkbox"
              id="announcements"
              defaultChecked={!optOutOfAnnouncements}
              onChange={() => handleAnnouncementsChanged()}
            />
            <Label for="announcements" check>
              Announcements, including conference invitations
            </Label>
          </FormGroup>
        </Col>
        <Col xs={12} className="my-1 ml-3">
          <FormGroup check inline>
            <Input
              type="checkbox"
              id="weekly-digests"
              defaultChecked={!optOutOfDigestAlerts}
              onChange={() => handleDigestAlertChanged()}
            />
            <Label for="weekly-digests" check>
              Weekly digests
            </Label>
          </FormGroup>
        </Col>
      </Row>
      <EditDefaultAlerts
        alertPreferences={alertStore?.payload}
        notEntitledWarning="This %s is not currently included in your subscription.  Please contact your account manager to activate."
        onChange={handleDefaultAlertsChanged}
        userEntitlements={resolvedUserEntitlements}
      />
    </>
  );
};

export default SelectAlertPreferences;
