import React, { useState } from 'react';
import { Modal, ModalBody, ModalHeader, Form, FormGroup, Label, Input, Button, Alert } from 'reactstrap';
import Loader from 'react-loader';

import { DocumentSearch, SavedSearch } from 'tcf-shared/models';

import { useAppDispatch, useAppSelector } from '../../../utils/hooks';
import SavedSearchDescription from '../../SavedSearch/components/SavedSearchDescription';
import { saveSavedSearch, searchSavedSearches } from '../../../actions/savedSearchesActions';

export interface SaveSearchModalProps {
  search: DocumentSearch;
  userId: string;
  showModal?: boolean;
  toggleModal: any;
  storeId: string;
}

const SaveSearchModal = (props: SaveSearchModalProps) => {
  const dispatch = useAppDispatch();
  const { search, storeId, userId, showModal, toggleModal } = props;
  const [saveSucceeded, setSaveSucceeded] = useState(false);
  const searchesStore = useAppSelector((s) => s.serverStores?.[storeId]);
  const isSaving = searchesStore?.isSaving;
  const error = searchesStore?.error;
  const [name, setName] = useState('');
  const [alert, setAlert] = useState(false);
  const canSave = (name?.length ?? 0) > 0;

  const onSaveSucceeded = () => {
    setSaveSucceeded(true);
    dispatch(searchSavedSearches(storeId, { search: { userId } }));
    return true;
  };

  const onSaveFailed = () => {
    setSaveSucceeded(false);
    dispatch(searchSavedSearches(storeId, { search: { userId } }));
    return true;
  };

  const onSaveSearch = (event: any) => {
    event.preventDefault();
    event.stopPropagation();
    if (canSave) {
      const _savedSearch: Partial<SavedSearch> = {
        userId,
        name,
        alert,
        ...search,
      };
      dispatch(saveSavedSearch(storeId, _savedSearch)).then(onSaveSucceeded, onSaveFailed);
    }
  };

  const onNameChange = (event: any) => {
    event.preventDefault();
    event.stopPropagation();
    setName(event.target.value);
  };

  const onAlertChange = (event: any) => {
    event.preventDefault();
    event.stopPropagation();
    setAlert(event.target.checked);
  };

  const onCancel = (event: any) => {
    event.stopPropagation();
    toggleModal();
  };

  return (
    <Modal isOpen={showModal} toggle={toggleModal}>
      <Loader loaded={!isSaving}>
        <ModalHeader toggle={toggleModal}>Save search</ModalHeader>
        <ModalBody>
          {saveSucceeded ? (
            <div>
              <Alert color="success">Saved search {name}.</Alert>
              <div className="d-flex justify-content-around mt-3">
                <Button onClick={onCancel}>OK</Button>
              </div>
            </div>
          ) : (
            <Form>
              {error && <Alert color="danger">Save failed!</Alert>}
              <div className="mb-3">
                <SavedSearchDescription savedSearch={search} />
              </div>
              <FormGroup>
                <Label for="name">Name for saved search (required):</Label>
                <Input type="text" name="name" id="name" placeholder="Enter name for saved search..." onChange={onNameChange} />
              </FormGroup>
              <FormGroup check>
                <Label check>
                  <Input type="checkbox" name="alert" onChange={onAlertChange} /> Send email alert when new documents are
                  published
                </Label>
              </FormGroup>
              <div className="d-flex justify-content-around mt-3">
                <Button
                  className="mr-2"
                  disabled={!canSave}
                  title={canSave ? 'Create saved search' : 'Name field is required'}
                  onClick={onSaveSearch}
                >
                  Save
                </Button>
                <Button onClick={toggleModal}>Cancel</Button>
              </div>
            </Form>
          )}
        </ModalBody>
      </Loader>
    </Modal>
  );
};

export default SaveSearchModal;
