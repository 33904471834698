import { AxiosInstance } from 'axios';

import { ApiResponse } from 'tcf-shared/models';

import { CrudApiClient } from './CrudApiClient';

export class DirectoryEntityApiClient extends CrudApiClient {
  constructor(readonly axios: AxiosInstance) {
    super(axios, '/directory/entity');
  }

  async postProfilePicture(directoryEntityId: string, file: File): Promise<ApiResponse> {
    const formData = new FormData();
    formData.append('file', file);
    return this.axios.post<ApiResponse>(`${this.baseUrl}/${directoryEntityId}/profile-picture`, formData);
  }

  async deleteProfilePicture(directoryEntityId: string): Promise<ApiResponse> {
    return this.axios.delete<ApiResponse>(`${this.baseUrl}/${directoryEntityId}/profile-picture`);
  }
}
