import { AxiosInstance } from 'axios';
import { ApiResponse } from 'tcf-shared/models';
import { CrudApiClient } from './CrudApiClient';

export class AuthorsApiClient extends CrudApiClient {
  constructor(readonly axios: AxiosInstance) {
    super(axios, '/authors');
  }

  async getAll(): Promise<ApiResponse> {
    return this.axios.get<ApiResponse>(`${this.baseUrl}`);
  }
}
