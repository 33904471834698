import React from 'react';
import { Table } from 'reactstrap';

import { useAppSelector } from '../../utils/hooks';
import { NotFoundError } from '../../components';
import { getAuthUser } from '../../reducers/authReducer';
import BasicPage from '../BasicPage/BasicPage';
import SubscriptionDetails from './components/SubscriptionDetails';

const ViewSubscription = () => {
  const authUser = useAppSelector((state) => getAuthUser(state));

  if (!authUser) return <NotFoundError />;

  return (
    <BasicPage title={'Subscription Details: ' + authUser.email}>
      <div className={'py-3 px-2'}>
        <Table borderless responsive className="mb-0">
          <tbody>
            <tr>
              <td className="px-0">
                <h2>Subscription Details</h2>
              </td>
              <td className="text-right px-0">Email: {authUser.email || ''}</td>
            </tr>
          </tbody>
        </Table>
        <SubscriptionDetails />
      </div>
    </BasicPage>
  );
};

export default ViewSubscription;
