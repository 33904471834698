import { ReportQuery } from 'tcf-shared/models';
import { library } from '../libraryApi';
import { READ_SERVER } from './actionTypes';
import { asyncAction } from './asyncAction';

export const getReport = (storeIdentifier: string, query: ReportQuery) => {
  return asyncAction({
    actionType: READ_SERVER,
    func: async () => (await library.docViews.getReport(query)).data,
    storeIdentifier,
    requestPayload: { query },
  });
};

export const getTopDocumentsAndTopics = (storeIdentifier: string) => {
  return asyncAction({
    actionType: READ_SERVER,
    func: async () => (await library.docViews.getTopDocumentsAndTopics()).data,
    storeIdentifier,
  });
};
