import config from '../config';
import { accessToken } from '../utils/Tokens';

interface ConfigParams {
  send_page_view?: false;
  browser_id?: string;
  user_id?: string;
  user_email?: string;
  environment?: string;
}

interface EventParams {
  event_category?: string;
  event_label?: string;
}

interface PageViewParams {
  page_path: string;
  page_title: string;
  dialog?: 'publish';
}

interface GoogleAnalyticsFunction {
  (command: 'config', property: string, params: ConfigParams): void;
  (command: 'set', params: ConfigParams): void;
  (command: 'event', name: 'page_view', params: PageViewParams): void;
  (
    command: 'event',
    name: 'exception' | 'login' | 'login_email_sent' | 'logout' | 'search' | 'view_search_results' | 'free_login_email_sent',
    params: EventParams,
  ): void;
}

declare var gtag: GoogleAnalyticsFunction;
const gaProperty =
  config.TCF_ENV === 'prod' || window.location.hostname === 'library.thecapitolforum.com'
    ? 'G-PHDHPBFLK0' // production
    : 'G-8LY33PLH7K'; // all other environments
gtag('config', gaProperty, { send_page_view: false });
const aToken = accessToken.decode();
gtag('set', {
  environment: config.TCF_ENV,
  user_id: aToken ? aToken.sub : undefined,
  user_email: aToken ? aToken.email : undefined,
});

export const trackException = (err: Error) => {
  gtag('event', 'exception', { event_label: err.message });
};

export const trackLoginEmailSent = (email: string) => {
  gtag('event', 'login_email_sent', {
    event_category: 'engagement',
    event_label: email,
  });
};

export const trackLogin = (user: { id: string; email: string }) => {
  gtag('set', { user_id: user.id, user_email: user.email });
  gtag('event', 'login', { event_category: 'engagement' });
};

export const trackLogout = () => {
  gtag('event', 'logout', { event_category: 'engagement' });
  gtag('set', { user_id: '', user_email: '' });
};

export const trackPageView = (path: string, title: string) => {
  gtag('event', 'page_view', { page_path: path, page_title: title });
};

export const trackSearch = (q: string) => {
  gtag('event', 'search', { event_label: q });
};
