// So we want to show as much of the file name as we can + the extension.  If the name is really long,
// truncate it, place ellipses where we trimmed, and add the extension back.  For edge cases where
// the file name contains a dot and not an extension, just truncate the file name.
export const shortenFileName = (fileName?: string, maxLength = 60, truncationIndicator = '...') => {
  if (!fileName || fileName.length <= maxLength) return fileName || '';

  const lastDotIndex = fileName.lastIndexOf('.');
  const ext = lastDotIndex > -1 ? fileName.substring(lastDotIndex + 1).trim() : '';

  // If this doesn't appear to be an extension, just return the truncated file name.
  if (ext.length > 5 || ext.length === 0) return fileName.substring(0, maxLength) + truncationIndicator;

  const name = lastDotIndex > -1 ? fileName.substring(0, lastDotIndex).trim() : '';

  // We want the file name to end up at about maxLength characters not counting ellipses.
  return [name.substring(0, maxLength - ext.length) + truncationIndicator, ext].filter(Boolean).join('');
};
