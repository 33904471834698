import { Topic, DeleteRequest, Query } from 'tcf-shared/models';
import { library } from '../libraryApi';
import { asyncAction } from './asyncAction';
import { READ_SERVER, SAVE_SERVER, DELETE_SERVER } from './actionTypes';
import { toast } from './toastActions';

export function searchTopics(storeIdentifier: string, query: Query) {
  return asyncAction({
    actionType: READ_SERVER,
    func: async () => (await library.topics.search(query)).data,
    storeIdentifier,
  });
}

export function getTopic(storeIdentifier: string, id: string) {
  return asyncAction({
    actionType: READ_SERVER,
    func: async () => (await library.topics.get(id)).data,
    storeIdentifier,
  });
}

export function getTopicRelated(storeIdentifier: string, id: string) {
  return asyncAction({
    actionType: READ_SERVER,
    func: async () => (await library.topics.getRelated(id)).data,
    storeIdentifier,
  });
}

export function saveTopic(storeIdentifier: string, topic: Partial<Topic>) {
  const func = topic?.meta?.createdAt
    ? async () => (await library.topics.put(topic.id!, topic)).data
    : async () => (await library.topics.post(topic)).data;

  return asyncAction({
    actionType: SAVE_SERVER,
    func,
    storeIdentifier,
    onSucceeded: async (result, dispatch) =>
      dispatch(
        toast.showSucceeded({
          message: 'Topic saved.',
        }),
      ),
    onFailed: async (result, dispatch) =>
      dispatch(
        toast.showFailed({
          message: 'The topic was not saved correctly.',
        }),
      ),
  });
}

export function deleteTopic(storeIdentifier: string, request: DeleteRequest) {
  return asyncAction({
    actionType: DELETE_SERVER,
    func: async () => (await library.topics.delete(request)).data,
    storeIdentifier,
    onSucceeded: async (result, dispatch) =>
      dispatch(
        toast.showSucceeded({
          message: 'Topic deleted.',
        }),
      ),
    onFailed: async (result, dispatch) =>
      dispatch(
        toast.showFailed({
          message: 'Something went wrong and we were not able to delete the topic. Please reload the page and try again.',
        }),
      ),
  });
}
