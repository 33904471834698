import React from 'react';
import { Prompt } from 'react-router-dom';

interface PromptUnsavedChangesProps {
  children?: React.ReactNode;
  dirty?: boolean;
  message?: string;
}

export const PromptUnsavedChanges = ({ children, dirty, message }: PromptUnsavedChangesProps) => (
  <>
    {children}
    <Prompt when={dirty} message={message || 'Are you sure you want to leave this page? You will lose any unsaved changes.'} />
  </>
);
