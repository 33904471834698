import React, { useEffect } from 'react';
import moment from 'moment';

import { setNotificationsDate } from '../../actions/userActions';
import { useAppDispatch, useAppSelector } from '../../utils/hooks';

// This is used by the notification bell and is stored in the user record when the user visits
// this page.  It doesn't have to be 100% accurate since it's more like a version number than an
// actual date, but when adjusting the date, it should always be "in the future" relative to the
// previous NOTIFICATIONS_LAST_UPDATED date.
export const NOTIFICATIONS_LAST_UPDATED = moment('2023-12-15T00:00:00.000Z');

export const NOTIFICATIONS_DATE_STORE_ID = 'NotificationsDate';

export interface NotificationDateStore {
  mostRecentlyViewedNotificationPageDate?: string;
  checkedAt?: string;
}

const Notifications = () => {
  const dispatch = useAppDispatch();

  const store = useAppSelector<NotificationDateStore>((s) => s.serverStores?.[NOTIFICATIONS_DATE_STORE_ID]?.payload);

  const mostRecentlyViewedNotificationPageDate = store?.mostRecentlyViewedNotificationPageDate;
  const hasBeenChecked = !!store?.checkedAt;

  useEffect(() => {
    if (
      hasBeenChecked &&
      !(
        mostRecentlyViewedNotificationPageDate &&
        moment(mostRecentlyViewedNotificationPageDate).isSame(NOTIFICATIONS_LAST_UPDATED)
      )
    ) {
      dispatch(setNotificationsDate(NOTIFICATIONS_DATE_STORE_ID, NOTIFICATIONS_LAST_UPDATED.toDate()));
    }
  }, [dispatch, hasBeenChecked, mostRecentlyViewedNotificationPageDate]);

  return (
    <div style={{ width: '60%', margin: 'auto', fontSize: '1rem' }}>
      <h2>Notifications</h2>
      {/* DON'T FORGET TO UPDATE NOTIFICATIONS_LAST_UPDATED IF ADDING A NOTIFICATION */}
      <hr />
      <small>
        <i>December 15, 2023</i>
      </small>
      <div style={{ textAlign: 'justify' }}>
        <p>
          We are excited to announce several enhancements to the layout and functionality of our Platform. Most notably, the
          search feature now includes additional filtering and sorting options. Please reference the{' '}
          <a
            href="https://thecapitolforum.com/wp-content/uploads/2023/12/TCF-GUIDE-20231220.pdf"
            target="_blank"
            rel="noopener noreferrer"
          >
            User Guide (PDF)
          </a>{' '}
          for details, and as always, don’t hesitate to reach out to your account representative with any questions.
        </p>
        <hr />
      </div>
    </div>
  );
};

export default Notifications;
