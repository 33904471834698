import React from 'react';
import { Card, CardBody, CardHeader, Collapse } from 'reactstrap';
import { BsFillArrowDownCircleFill, BsFillArrowUpCircleFill } from 'react-icons/bs';

export interface AccordionProps {
  children: React.ReactNode;
  isOpen?: boolean;
  title: string;
  id: string;
  handleToggle: (id: string) => void;
  className?: string;
}

const Accordion = (props: AccordionProps) => {
  const { children, isOpen, title, id, className, handleToggle } = props;

  return (
    <Card className={' ' + (className || '')}>
      <CardHeader
        className="p-2 accordion"
        onClick={(e) => {
          e.stopPropagation();
          e.preventDefault();
          handleToggle(id);
        }}
      >
        <div>
          <span className="float-right">{isOpen ? <BsFillArrowUpCircleFill /> : <BsFillArrowDownCircleFill />}</span>
          {title}
        </div>
      </CardHeader>
      <Collapse isOpen={isOpen}>
        <CardBody className="p-2">{children}</CardBody>
      </Collapse>
    </Card>
  );
};

export default Accordion;
