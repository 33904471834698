import React, { useState } from 'react';
import { Row, Button, Col } from 'reactstrap';

import { Query } from 'tcf-shared/models';

import { getAuthUser } from '../../reducers/authReducer';
import { searchCompanies } from '../../actions/companiesActions';
import { useAppSelector, useLocationHistoryQuery } from '../../utils/hooks';
import AsyncPage from '../AsyncPage/AsyncPage';
import CompanyFilter from './components/CompanyFilter';
import CompanyList from './components/CompanyList';

const COMPANIES_STORE = 'listCompaniesStore';
const DEFAULT_QUERY = { skip: 0, limit: 25, search: { sortBy: 'name', sortOrder: 'asc', withArticlesOnly: true } };

const ListCompanies = () => {
  const [query, replaceQuery, resetQuery, payload, isFetching, error] = useLocationHistoryQuery<Query>(
    'listCompaniesQuery',
    COMPANIES_STORE,
    (locationQuery?: Partial<Query>) => ({ ...DEFAULT_QUERY, ...locationQuery }),
    searchCompanies,
  );

  const authUser = useAppSelector((s) => getAuthUser(s));
  const [showFilterOnSm, setShowFilterOnSm] = useState(false);
  const toggleShowFilter = () => setShowFilterOnSm((prevState) => !prevState);

  if (!authUser) return null;

  return (
    <AsyncPage title="Companies" loading={isFetching && !payload} error={error}>
      <Row>
        <Col md={3} className={`sidebar pt-3 pl-4 pr-4 pl-md-3 pr-md-3  ${showFilterOnSm ? '' : 'd-none d-md-block'}`}>
          <div className="d-flex mb-2">
            <h4 className="mr-auto">Search</h4>
            {showFilterOnSm && (
              <Button className={'d-md-none mx-2'} onClick={toggleShowFilter}>
                Hide Search
              </Button>
            )}
            <Button className="mr-0" onClick={resetQuery}>
              Reset
            </Button>
          </div>
          <CompanyFilter authUser={authUser} query={query} onQueryChange={replaceQuery} />
        </Col>
        <Col md={9} className={'py-3 px-4 px-md-3'} style={{ backgroundColor: '#fff' }}>
          <div className="d-flex mb-3">
            <h4 className="mr-auto">Companies</h4>
            {!showFilterOnSm && (
              <Button className={'d-md-none ml-2'} onClick={toggleShowFilter}>
                Search
              </Button>
            )}
          </div>
          <CompanyList storeId={COMPANIES_STORE} query={query} onQueryChange={replaceQuery} />
        </Col>
      </Row>
    </AsyncPage>
  );
};

export default ListCompanies;
