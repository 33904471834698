import * as React from 'react';
import Loader from 'react-loader';

import { NotFoundError } from '../../components';
import BasicPage from '../BasicPage/BasicPage';

export interface AsyncPageProps {
  children?: any;
  error?: string;
  loading?: boolean;
  title?: string;
}

export default (props: AsyncPageProps) => {
  const {
    children,
    error,
    loading,
    title,
  } = props;

  let ErrorComponent;
  if (error) {
    switch (error.toLowerCase()) {
      case 'not found':
        ErrorComponent = <NotFoundError />;
        break;
      case 'network error':
        ErrorComponent = <h3>Network service not responding.  Please try again later.</h3>;
        break;
      case 'missing permission':
        ErrorComponent = (
          <div>
            <h3>
              Your subscription does not include access to the requested page.
            </h3>
            <p>If you believe this is an error, please contact editorial@thecapitolforum.com for help.</p>
          </div>
        );
        break;
      default:
        ErrorComponent = <h3>{error}</h3>;
    }
  }

  return (
    <Loader loaded={!loading}>
      <BasicPage title={title}>
        {error ? ErrorComponent : children}
      </BasicPage>
    </Loader>
  );
};
