import { library } from '../libraryApi';
import { asyncAction } from './asyncAction';
import { READ_SERVER } from './actionTypes';

export function getSector(storeIdentifier: string, code: string) {
  try {
    return asyncAction({
      actionType: READ_SERVER,
      func: async () => (await library.sectors.get(code)).data,
      storeIdentifier,
    });
  } catch (err) {
    return null;
  }
}
