import { AxiosInstance } from 'axios';

import { ApiResponse } from 'tcf-shared/models';

export class SectorsApiClient {
  constructor(readonly axios: AxiosInstance, readonly baseUrl: string) {}

  async get(code: string): Promise<ApiResponse> {
    return this.axios.get<ApiResponse>(`${this.baseUrl}/${code}`);
  }
}
