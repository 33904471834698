import { AxiosInstance } from 'axios';

import { ApiResponse, DocumentType, PublishRequest, UnpublishRequest } from 'tcf-shared/models';

import { CrudApiClient } from './CrudApiClient';

export class DocumentsApiClient extends CrudApiClient {
  constructor(readonly axios: AxiosInstance) {
    super(axios, '/docs');
  }

  async publish(request: PublishRequest): Promise<ApiResponse> {
    const { id, ...body } = request;
    return this.axios.post<ApiResponse>(`${this.baseUrl}/${id}/publish`, body);
  }

  async unpublish(request: UnpublishRequest): Promise<ApiResponse> {
    const { id, updatedAt } = request;
    const url = `${this.baseUrl}/${id}/publish` + this.getQueryString({ updatedAt });
    return this.axios.delete(url);
  }

  async upload(id: string, updatedAt: Date | string, file: File, mobile: boolean = false): Promise<ApiResponse> {
    const url = `${this.baseUrl}/${id}/file`;
    const formData = new FormData();
    formData.append('updatedAt', JSON.stringify(updatedAt));
    formData.append('mobile', JSON.stringify(mobile));
    formData.append('file', file);
    return this.axios.post<ApiResponse>(url, formData);
  }

  async getSGDocumentSummary(id: string): Promise<ApiResponse> {
    return this.axios.get<ApiResponse>(`${this.baseUrl}/${id}/sg/summary`);
  }

  async addAssociatedDocument(
    parentDocumentId: string,
    associatedDocumentTitle: string,
    associatedDocumentType: DocumentType,
    associatedDocumentFile: File,
  ): Promise<ApiResponse> {
    const url = `${this.baseUrl}/${parentDocumentId}/associated-document`;
    const formData = new FormData();
    formData.append('associatedDocumentTitle', associatedDocumentTitle);
    formData.append('associatedDocumentType', associatedDocumentType);
    formData.append('associatedDocumentFile', associatedDocumentFile);
    return this.axios.post<ApiResponse>(url, formData);
  }
}
