/*
  Experimenting with something a little different here.  The idea is that there's an array of search results stored
  in Redux and we're going to perform all of our CRUD operations against that instead of setting up another store
  for singleton operations.

  Put another way, we perform a search on Page A then perhaps we want to edit one of the objects found in those
  search results.  Rather than set up another store to deal with the singleton object, we will work out of the
  existing search results.  When we bring up the edit page, we will refresh the existing object in the search
  results before opening it in the editor.  When the object is saved, we will save it to the API and update the
  object in the search results.  All operations are performed against the search results.  This should work well
  in a list/detail paradigm which is what we're currently using for organization self management.  This should
  prevent excessive search refreshes like we see elsewhere in the app.

  We do something like this elsewhere, but this is a bit of an experiment to see if there's a better way to manage
  state.  TODO: If this works well, generalize it so we can use it on other pages.

  Note 1: There's something similar in Upstream where we have generalized lists and singletons.  It was partially
  ported to the Library a while back (see serverStoreReducer), but perhaps a full port is in order.

  Note 2: There's a pattern in the Redux documentation whereby all records in the result set are stored and maintained
  in a POJO (dictionary) indexed by ID and all reads come from this dictionary.  I find this inefficient because we
  need to store the dictionary of source data PLUS we need to implement a side construct to deal with the display order
  (perhaps a sorted array), but it is something to consider.

  Note 3: Redux offers a toolkit called RTK Query that sounds like it can accomplish what we're trying to achieve
  here.  I breezed through the documentation and feel that it's a bit more involved that I wanted to get into for
  this enhancement, but it's something to consider if we spin up a new app.
*/
import { ApiError } from 'tcf-shared/models';

import { Action } from '../actions/Action';
import {
  CREATE_SM_USER,
  LOGOUT,
  REFRESH_SM_USER,
  RESET_SM_USERS_STORE,
  SEARCH_SM_USERS,
  UPDATE_SM_USER,
  CLEAR_SM_SAVE_ERROR,
} from '../actions/actionTypes';

export interface OrganizationSelfManagementState {
  isInitialized: boolean;
  payload?: any;
  isSearching: boolean;
  searchError: string;
  isRefreshing: boolean;
  refreshError: string;
  isSaving: boolean;
  saveError: string;
}

const initialState: OrganizationSelfManagementState = {
  isInitialized: false,
  isSearching: false,
  searchError: '',
  isRefreshing: false,
  refreshError: '',
  isSaving: false,
  saveError: '',
};

export const organizationSelfManagementReducer = (state = initialState, action: Action): OrganizationSelfManagementState => {
  switch (action.type) {
    case SEARCH_SM_USERS.REQUESTED:
      return { ...state, isSearching: true };

    case REFRESH_SM_USER.REQUESTED:
      return { ...state, isRefreshing: true };

    case CREATE_SM_USER.REQUESTED:
    case UPDATE_SM_USER.REQUESTED:
      return { ...state, isSaving: true };

    case SEARCH_SM_USERS.SUCCEEDED:
      return { ...initialState, payload: action.payload, isInitialized: true };

    case REFRESH_SM_USER.SUCCEEDED:
      return {
        ...initialState,
        payload: {
          ...state.payload,
          results: state.payload.results.map((r: any) => (r.id === action.payload.id ? action.payload : r)),
        },
        isInitialized: true,
      };

    case CREATE_SM_USER.SUCCEEDED:
      return {
        ...initialState,
        payload: {
          ...state.payload,
          total: state.payload.total + 1,
          results: [action.payload, ...state.payload.results],
        },
        isInitialized: true,
      };

    case UPDATE_SM_USER.SUCCEEDED:
      return {
        ...initialState,
        payload: {
          ...state.payload,
          results: state.payload.results.map((r: any) => (r.id === action.payload.id ? action.payload : r)),
        },
        isInitialized: true,
      };

    case SEARCH_SM_USERS.FAILED: {
      const error = action.payload as ApiError;
      return { ...state, ...initialState, searchError: error?.message || 'Unable to search users.', isInitialized: true };
    }

    case REFRESH_SM_USER.FAILED: {
      const error = action.payload as ApiError;
      return { ...state, ...initialState, refreshError: error?.message || 'Unable to retrieve user.', isInitialized: true };
    }

    case CREATE_SM_USER.FAILED: {
      const error = action.payload as ApiError;
      return { ...state, ...initialState, saveError: error?.message || 'Unable to create user.', isInitialized: true };
    }

    case UPDATE_SM_USER.FAILED: {
      const error = action.payload as ApiError;
      return { ...state, ...initialState, saveError: error?.message || 'Unable to update user.', isInitialized: true };
    }

    case CLEAR_SM_SAVE_ERROR:
      return { ...state, saveError: '' };

    case RESET_SM_USERS_STORE:
    case LOGOUT.SUCCEEDED:
    case LOGOUT.FAILED:
      return initialState;

    default:
      return state;
  }
};
