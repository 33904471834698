import React from 'react';

import { AuthUser, allSectorsTableFilterOptions } from 'tcf-shared/models';
import FacetFilter from './FacetFilter';

interface SectorFilterProps {
  authUser?: AuthUser;
  onChange?: any;
  selectedSectors?: string[];
}

const SectorFilter = (props: SectorFilterProps) => {
  const { authUser, onChange } = props;
  const selectedSectors = props.selectedSectors || [];
  if (!authUser) {
    return null;
  }

  // Disable nonapplicable options based on user permissions
  const options = allSectorsTableFilterOptions.map((o) => ({
    ...o,
    disabled: !!(authUser.sectorCodes?.length && !authUser.sectorCodes.includes(o.value)),
    selected: selectedSectors.includes(o.value),
  }));

  const optionsChanged = (event: any) => {
    event.stopPropagation();
    const { value, checked } = event.target;
    let newOptions;
    if (checked) {
      newOptions = [...selectedSectors];
      newOptions.push(value);
    } else {
      newOptions = selectedSectors.filter((x) => x !== value);
    }
    onChange(newOptions);
  };

  return <FacetFilter title="Sectors" options={options} minOptionsToShow={options.length} onChange={optionsChanged} />;
};

export default SectorFilter;
