import { Query, User } from 'tcf-shared/models';

import {
  CREATE_SM_USER,
  READ_SERVER,
  REFRESH_SM_USER,
  RESET_SERVER_STORE,
  RESET_SM_USERS_STORE,
  SEARCH_SM_USERS,
  UPDATE_SM_USER,
  CLEAR_SM_SAVE_ERROR,
} from './actionTypes';
import { asyncAction } from './asyncAction';
import { library } from '../libraryApi';

export function searchSMUsers(query: Query) {
  // Searches for users.  Returns an array of results along with some metadata.
  return asyncAction({
    actionType: SEARCH_SM_USERS,
    func: async () => (await library.organizationSelfManagement.search(query)).data,
  });
}

export function refreshSMUser(id: string) {
  // Refreshes a single user in the array of results returned by searchSMUsers.  Used to ensure we're not editing stale data.
  return asyncAction({
    actionType: REFRESH_SM_USER,
    func: async () => (await library.organizationSelfManagement.get(id)).data,
  });
}

export function createSMUser(user: Partial<User>) {
  // Creates a single user and, if successful, updates the user in the array of results returned by searchSMUsers.
  return asyncAction({
    actionType: CREATE_SM_USER,
    func: async () => (await library.organizationSelfManagement.post(user)).data,
  });
}

export function updateSMUser(user: Partial<User>) {
  // Updates a single user and, if successful, updates the user in the array of results returned by searchSMUsers.
  return asyncAction({
    actionType: UPDATE_SM_USER,
    func: async () => (await library.organizationSelfManagement.put(user.id!, user)).data,
  });
}

export function resetSMUsersStore() {
  // It's like it never happened.
  return { type: RESET_SM_USERS_STORE };
}

export function readSMLicenseCount(storeIdentifier: string) {
  return asyncAction({
    actionType: READ_SERVER,
    storeIdentifier,
    func: async () => (await library.organizationSelfManagement.get('license')).data,
  });
}

export function readSMLicenseCountByOrgId(storeIdentifier: string, orgId: string) {
  return asyncAction({
    actionType: READ_SERVER,
    storeIdentifier,
    func: async () => (await library.organizationSelfManagement.get(`license/${orgId}`)).data,
  });
}

export function readSMOrganizationEntitlements(storeIdentifier: string) {
  return asyncAction({
    actionType: READ_SERVER,
    storeIdentifier,
    func: async () => (await library.organizationSelfManagement.get('organization-entitlements')).data,
  });
}

export function resetSMLicenseCount(storeIdentifier: string) {
  return { type: RESET_SERVER_STORE, storeIdentifier };
}

export function clearSMSaveError() {
  // A bit of a hack.  Since we're working out of a common Redux list store, failed saves don't get cleared
  // like a normal store would so we need a way to clear errors when the edit modal is closed.
  return { type: CLEAR_SM_SAVE_ERROR };
}
