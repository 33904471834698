import React from 'react';
import { Field as RFField } from 'react-final-form';
import { Input, Label, FormFeedback, FormGroup } from 'reactstrap';

const TcfRadioGroup = (props: any) => (
  <RFField name={props.name} className={props.className} options={props.options}>
    {(fieldProps) => {
      const hasError = fieldProps.meta.touched && fieldProps.meta.error;
      return (
        <div>
          {fieldProps.options.map((option: any) => (
            <FormGroup check key={option.value}>
              <Label check>
                <Input
                  type={'radio'}
                  {...props.input}
                  value={option.value}
                  disabled={option.disabled}
                  checked={option.value === fieldProps.input.value}
                  onChange={fieldProps.input.onChange}
                />{' '}
                {option.label}
              </Label>
            </FormGroup>
          ))}
          {hasError && <FormFeedback className="d-block">{fieldProps.meta.error}</FormFeedback>}
        </div>
      );
    }}
  </RFField>
);

export default TcfRadioGroup;
