import React, { useEffect } from 'react';
import { isArray } from 'lodash';
import Toolbar from 'react-big-calendar/lib/Toolbar';

import { CalendarEvent, TimeType } from 'tcf-shared/models';

// Borrowed directly from react-big-calendar and tweaked.
export const weekRangeFormat = (_ref: any, culture: any, local: any) => {
  const start = _ref.start;
  const end = _ref.end;
  return (
    local.format(start, 'MMMM D', culture) +
    ' – ' + // updated to use this localizer 'eq()' method
    local.format(end, local.eq(start, end, 'month') ? 'D' : 'MMMM D', culture)
  );
};

export const titleAccessor = (e: CalendarEvent) => <span>{e.title ?? 'No title'}</span>;

export const overrideMessages = {
  previous: 'Previous',
  noEventsInRange: 'There are no events in this date range. Use the Today button above to move the calendar to today.',
};

export interface Event {
  start: Date;
  end: Date;
  timeType: TimeType;
  title: string;
  description: string;
  allDay: boolean;
}

// For some reason, ranges returned by the React Big Calendar can be an object with a start and end or an array of
// dates.  If it's an array, let's find the min and max and turn it into an object with a start and end.
export const standardizeDateRange = (range: Date[] | { start: Date; end: Date }) => {
  if (!isArray(range)) return range;
  const sorted = range.sort((a, b) => a.getTime() - b.getTime());
  const end = sorted[sorted.length - 1];
  return { start: sorted[0], end: new Date(end.getFullYear(), end.getMonth(), end.getDate(), 23, 59, 59) };
};

// Borrowed from https://github.com/jquense/react-big-calendar/issues/1752#issuecomment-761051235
// The calendar does not provide any way to receive the range of dates that
// are visible except when they change. This is the cleanest way I could find
// to extend it to provide the _initial_ range (`onView` calls `onRangeChange`).
export const InitialRangeChangeToolbar = (props: any) => {
  const view = props.view;
  const onView = props.onView;
  useEffect(() => {
    onView(view);
  }, [onView, view]);
  return <Toolbar {...props} />;
};
