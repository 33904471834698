import { AxiosInstance } from 'axios';

import { ApiResponse, ReportQuery } from 'tcf-shared/models';

export class DocViewsApiClient {
  constructor(readonly axios: AxiosInstance, readonly baseUrl: string) {}

  async getReport(query: ReportQuery): Promise<ApiResponse> {
    return this.axios.post<ApiResponse>(`${this.baseUrl}/report`, query);
  }

  async getTopDocumentsAndTopics(): Promise<ApiResponse> {
    return this.axios.get<ApiResponse>(`${this.baseUrl}/top_docs_and_topics`);
  }
}
