import { Action } from '../actions/Action';
import { ApiError, Organization, User, EfficyProject } from 'tcf-shared/models';
import {
  GET_ORGANIZATION,
  GET_ORGANIZATION_RELATED,
  CREATE_ORGANIZATION,
  SAVE_ORGANIZATION,
  DELETE_ORGANIZATION,
  LOGOUT,
  SAVE_ORGANIZATION_USERS,
} from '../actions/actionTypes';

export interface CurrentOrganizationRelated {
  users: User[];
  efficyProjects?: EfficyProject[];
}

export interface OrganizationsState {
  currentOrganization?: Organization;
  currentOrganizationError: string;
  currentOrganizationRelated?: CurrentOrganizationRelated;
  currentOrganizationRelatedError: string;
  error: string;
  isDeleting: boolean;
  isFetching: boolean;
  isFetchingRelated: boolean;
}

const initialState: OrganizationsState = {
  currentOrganizationError: '',
  currentOrganizationRelatedError: '',
  error: '',
  isDeleting: false,
  isFetching: false,
  isFetchingRelated: false,
};

export const organizationsReducer = (state = initialState, action: Action): OrganizationsState => {
  switch (action.type) {
    case GET_ORGANIZATION.REQUESTED:
      return {
        ...state,
        currentOrganization: undefined,
        isFetching: true,
        currentOrganizationError: '',
      };

    case GET_ORGANIZATION_RELATED.REQUESTED:
      return {
        ...state,
        currentOrganizationRelated: undefined,
        isFetchingRelated: true,
        currentOrganizationRelatedError: '',
      };

    case CREATE_ORGANIZATION.REQUESTED:
    case SAVE_ORGANIZATION.REQUESTED:
      return {
        ...state,
        isFetching: true,
        currentOrganizationError: '',
      };

    case SAVE_ORGANIZATION_USERS.REQUESTED:
      return {
        ...state,
        isFetchingRelated: true,
        currentOrganizationRelatedError: '',
      };

    case DELETE_ORGANIZATION.REQUESTED:
      return {
        ...state,
        isDeleting: true,
        currentOrganizationError: '',
      };

    case GET_ORGANIZATION.SUCCEEDED:
    case CREATE_ORGANIZATION.SUCCEEDED:
    case SAVE_ORGANIZATION.SUCCEEDED:
      return {
        ...state,
        isFetching: false,
        currentOrganization: action.payload,
        currentOrganizationError: '',
      };

    case SAVE_ORGANIZATION_USERS.SUCCEEDED:
      const currentUsers = (state.currentOrganizationRelated && state.currentOrganizationRelated.users) || [];
      const newUsers = currentUsers.map(
        (u) => action.payload.find((uu: User) => uu.id === u.id) || currentUsers.find((uu: User) => uu.id === u.id),
      );
      return {
        ...state,
        currentOrganizationRelatedError: '',
        isFetchingRelated: false,
        currentOrganizationRelated: {
          ...state.currentOrganizationRelated,
          users: newUsers,
        },
      };

    case GET_ORGANIZATION_RELATED.SUCCEEDED:
      return {
        ...state,
        isFetchingRelated: false,
        currentOrganizationRelated: action.payload.results,
        currentOrganizationRelatedError: '',
      };

    case DELETE_ORGANIZATION.SUCCEEDED:
      return {
        ...state,
        isDeleting: false,
        currentOrganization: undefined,
        currentOrganizationError: '',
      };

    case GET_ORGANIZATION.FAILED: {
      const error = action.payload as ApiError;
      return {
        ...state,
        isFetching: false,
        currentOrganization: undefined,
        currentOrganizationError: error ? error.message : 'Not found',
      };
    }

    case GET_ORGANIZATION_RELATED.FAILED: {
      const error = action.payload as ApiError;
      return {
        ...state,
        isFetchingRelated: false,
        currentOrganizationRelated: undefined,
        currentOrganizationRelatedError: error ? error.message : 'Not found',
      };
    }

    case CREATE_ORGANIZATION.FAILED:
    case SAVE_ORGANIZATION.FAILED: {
      const error = action.payload as ApiError;
      return {
        ...state,
        isFetching: false,
        currentOrganizationError: `${error ? `${error.name}: ${error.message}` : 'no message'}`,
      };
    }

    case SAVE_ORGANIZATION_USERS.FAILED: {
      const error = action.payload as ApiError;
      return {
        ...state,
        isFetchingRelated: false,
        currentOrganizationRelatedError: `${error ? `${error.name}: ${error.message}` : 'no message'}`,
      };
    }

    case DELETE_ORGANIZATION.FAILED: {
      const error = action.payload as ApiError;
      return {
        ...state,
        isDeleting: false,
        currentOrganizationError: `${error ? `${error.name}: ${error.message}` : 'no message'}`,
      };
    }

    case LOGOUT.SUCCEEDED:
    case LOGOUT.FAILED:
      return initialState;

    default:
      return state;
  }
};
