import React from 'react';
import { Link } from 'react-router-dom';
import { Card, CardBody, CardHeader } from 'reactstrap';

import { SavedSearch } from 'tcf-shared/models';

import { paths } from '../../../paths';

interface OwnProps {
  savedSearches?: SavedSearch[];
}

export type SavedSearchNameListProps = OwnProps;

const SavedSearchNameList = (props: SavedSearchNameListProps) => {
  const { savedSearches } = props;

  if (!savedSearches?.length) {
    return null;
  }

  return (
    <Card className="mb-3">
      <CardHeader>Saved Searches</CardHeader>
      <CardBody>
        <ul className={'pl-3'}>
          {savedSearches.map((s: SavedSearch) => (
            <li key={s.id}>
              <Link to={paths.SAVED_SEARCH_RESULTS.replace(':id', s.id)} title="Click to run this search">
                {s.name}
              </Link>
            </li>
          ))}
        </ul>
        <h6 className={'ml-0'}>
          <i>
            <Link
              to={{ pathname: paths.VIEW_ACCOUNT, state: { activeTab: 'Advanced Alerts' } }}
              title="View detailed list of your saved searches"
            >
              View all...
            </Link>
          </i>
        </h6>
      </CardBody>
    </Card>
  );
};

export default SavedSearchNameList;
