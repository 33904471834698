import React from 'react';
import { Link } from 'react-router-dom';
import { Card, CardBody, CardHeader } from 'reactstrap';

import { paths } from '../../paths';

interface CompanyListProps {
  companies: { id: string; name: string }[];
  title: string;
}

const CompanyList = (props: CompanyListProps) => {
  const { companies, title } = props;

  return companies.length > 0 ? (
    <Card className={'mb-3'}>
      <CardHeader>{title}</CardHeader>
      <CardBody>
        <ul className={'pl-3'}>
          {companies.slice(0, 10).map((c) => (
            <li key={c.id}>
              <Link to={paths.VIEW_COMPANY.replace(':id', c.id)}>{c.name}</Link>
            </li>
          ))}
        </ul>
        <h6 className={'ml-0'}>
          <i>
            <Link to={paths.LIST_COMPANIES}>View all companies...</Link>
          </i>
        </h6>
      </CardBody>
    </Card>
  ) : null;
};

export default CompanyList;
