import React, { useState, useEffect, useCallback } from 'react';
import { Page } from 'react-pdf/dist/esm/entry.webpack';
import { Props as PageProps } from 'react-pdf/dist/Page';

const OBSERVER_CONFIG = {
  threshold: [0.1, 0.2, 0.3, 0.4, 0.5, 0.6, 0.7, 0.8, 0.9, 1.0],
};

// Adapted from { useIntersectionObserver } from '@wojtekmaj/react-hooks';
function useIntersectionObserver(
  page: { element: Element; observer: any },
  options: IntersectionObserverInit | undefined,
  observerCallback: IntersectionObserverCallback,
): void {
  useEffect(() => {
    const element = page?.element;

    if (element && 'IntersectionObserver' in window) {
      const observer = new IntersectionObserver(observerCallback, options);
      observer.observe(element);
      page.observer = observer;
      return () => {
        observer.disconnect();
        page.observer = null;
      };
    }
  }, [page, options, observerCallback]);
}

interface PageRef {
  pageNumber: number | undefined;
  element: Element;
  intersectionRatio: number;
  observer: any;
}

export interface PdfPageProps {
  pagesMap: any;
  onScrollEvent: any;
}

// Wrap Page with an IntersectionObserver so we can track currrent page while scrolling
const DocumentPage = (props: PdfPageProps & PageProps) => {
  const { pageNumber, pagesMap, onScrollEvent, ...otherProps } = props;
  const [page, setPage] = useState<PageRef | null>(null);

  const onIntersectionChange = useCallback(
    (entries: IntersectionObserverEntry[]) => {
      if (page && page.intersectionRatio !== entries[0].intersectionRatio) {
        page.intersectionRatio = entries[0].intersectionRatio;
        onScrollEvent();
      }
    },
    [page, onScrollEvent],
  );

  const setPageRef = useCallback(
    (element: any) => {
      if (element) {
        const _page: PageRef = { pageNumber, element, intersectionRatio: 0, observer: null };
        setPage(_page);
        pagesMap.set(pageNumber, _page);
      } else {
        pagesMap.delete(pageNumber);
      }
    },
    [pageNumber, pagesMap],
  );

  useIntersectionObserver(pagesMap.get(pageNumber), OBSERVER_CONFIG, onIntersectionChange);
  return <Page canvasRef={setPageRef} pageNumber={pageNumber} {...otherProps} />;
};

export default DocumentPage;
