import React from 'react';
import { Button } from 'reactstrap';
import { useHistory } from 'react-router-dom';

import { paths } from '../../paths';

const ViewCalendarButton = () => {
  const history = useHistory();

  return (
    <Button id="antitrust-calendar" onClick={() => history.push(paths.VIEW_CALENDAR)}>
      View Antitrust Calendar
    </Button>
  );
};

export default ViewCalendarButton;
