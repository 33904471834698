import { DirectoryEntity, Query } from 'tcf-shared/models';

import { READ_SERVER, SAVE_SERVER, DELETE_SERVER, UPLOAD_PROFILE_PICTURE, DELETE_PROFILE_PICTURE } from './actionTypes';
import { library } from '../libraryApi';
import { asyncAction } from './asyncAction';
import { toast } from './toastActions';

export function searchDirectoryEntity(storeIdentifier: string, query: Query) {
  return asyncAction({
    actionType: READ_SERVER,
    storeIdentifier,
    func: async () => (await library.directoryEntity.search(query)).data,
  });
}

export function readDirectoryEntity(storeIdentifier: string, id: string) {
  return asyncAction({
    actionType: READ_SERVER,
    storeIdentifier,
    func: async () => (await library.directoryEntity.get(id)).data,
  });
}

export function saveDirectoryEntity(storeIdentifier: string, directoryEntity: Partial<DirectoryEntity>) {
  const func = directoryEntity.id
    ? async () => (await library.directoryEntity.put(directoryEntity.id!, directoryEntity)).data
    : async () => (await library.directoryEntity.post(directoryEntity)).data;
  return asyncAction({
    actionType: SAVE_SERVER,
    storeIdentifier,
    func,
    onSucceeded: async (result, dispatch) =>
      dispatch(
        toast.showSucceeded({
          title: 'Entity saved',
          message: 'Entity saved.',
        }),
      ),
    onFailed: async (result, dispatch) =>
      dispatch(
        toast.showFailed({
          title: 'Error',
          message:
            'The entity was not saved correctly.  Please refresh the page and try ' +
            'again.  If the issue persists, please contact support.',
        }),
      ),
  });
}

export function deleteDirectoryEntity(storeIdentifier: string, id: string, updatedAt: Date | string) {
  return asyncAction({
    actionType: DELETE_SERVER,
    storeIdentifier,
    func: async () => (await library.directoryEntity.delete({ id, updatedAt })).data,
    onSucceeded: async (result, dispatch) =>
      dispatch(
        toast.showSucceeded({
          title: 'Entity deleted.',
          message: 'Entity deleted.',
        }),
      ),
    onFailed: async (result, dispatch) =>
      dispatch(
        toast.showFailed({
          title: 'Error.',
          message:
            'Unable to delete entity.  Please refresh the page and try again.  If the issue persists, please contact support.',
        }),
      ),
  });
}

export function uploadProfilePicture(storeIdentifier: string, directoryEntityId: string, file: File) {
  return asyncAction({
    actionType: UPLOAD_PROFILE_PICTURE,
    storeIdentifier,
    func: async () => (await library.directoryEntity.postProfilePicture(directoryEntityId, file)).data,
    onSucceeded: async (result, dispatch) =>
      dispatch(
        toast.showSucceeded({
          title: 'Profile picture uploaded',
          message: 'Profile picture successfully uploaded.',
        }),
      ),
    onFailed: async (result, dispatch) =>
      dispatch(
        toast.showFailed({
          title: 'Error',
          message:
            'The profile picture was not successfully uploaded.  Please refresh the page and try again.  If the issue persists, please contact support.',
        }),
      ),
  });
}

export function deleteProfilePicture(storeIdentifier: string, directoryEntityId: string) {
  return asyncAction({
    actionType: DELETE_PROFILE_PICTURE,
    storeIdentifier,
    func: async () => (await library.directoryEntity.deleteProfilePicture(directoryEntityId)).data,
    onSucceeded: async (result, dispatch) =>
      dispatch(
        toast.showSucceeded({
          title: 'Profile picture deleted',
          message: 'Profile picture successfully deleted.',
        }),
      ),
    onFailed: async (result, dispatch) =>
      dispatch(
        toast.showFailed({
          title: 'Error',
          message:
            'The profile picture was not successfully deleted.  Please refresh the page and try again.  If the issue persists, please contact support.',
        }),
      ),
  });
}
