import { Dispatch } from 'redux';

import { AsyncActionType } from './getAsyncActionTypes';

export interface AsyncActionOptions {
  actionType: AsyncActionType;
  func: (dispatch: Dispatch<any>, getState: () => any) => Promise<any>;
  requestPayload?: {};
  onSucceeded?: (result: any, dispatch: Dispatch<any>, getState: () => any) => Promise<any>;
  onFailed?: (error: any, dispatch: Dispatch<any>, getState: () => any) => Promise<any>;
  storeIdentifier?: string;
  deleteId?: string;
}

export const asyncAction = <AppState>(options: AsyncActionOptions) => {
  return async (dispatch: Dispatch<any>, getState: () => AppState) => {
    dispatch({
      type: options.actionType.REQUESTED,
      payload: options.requestPayload,
      storeIdentifier: options.storeIdentifier,
    });

    let result;
    try {
      result = await options.func(dispatch, getState as () => AppState);
      if (options.onSucceeded) {
        await options.onSucceeded(result, dispatch, getState);
      }
    } catch (err) {
      if (options.onFailed) {
        await options.onFailed(err, dispatch, getState);
      }
      dispatch({
        type: options.actionType.FAILED,
        error: true,
        payload: err && err.response ? err.response.data : { message: err.message || 'Unknown error' },
        storeIdentifier: options.storeIdentifier,
      });

      throw err;
    }

    dispatch({
      type: options.actionType.SUCCEEDED,
      error: false,
      payload: result,
      storeIdentifier: options.storeIdentifier,
      deleteId: options.deleteId,
    });
    return result;
  };
};
