import React from 'react';
import { Button } from 'reactstrap';
import { MdOutlineClose } from 'react-icons/md';
import { BsArrowLeftCircle, BsArrowRightCircle } from 'react-icons/bs';

import KeywordsFilter from '../../../components/filters/KeywordsFilter';

export interface DocumentSearchbarProps {
  numPages: number;
  maxPages: number;
  startPage: number;
  keywords: string;
  totalMatches: number;
  currentMatchIndex: number;
  onKeywordsChanged: any;
  onMatchIndexChanged: any;
  onHideSearchbar: any;
}

const DocumentSearchbar = (props: DocumentSearchbarProps) => {
  const {
    numPages,
    maxPages,
    startPage,
    keywords,
    totalMatches,
    currentMatchIndex,
    onKeywordsChanged,
    onMatchIndexChanged,
    onHideSearchbar,
  } = props;

  const onPreviousMatch = () => {
    onMatchIndexChanged(Math.max(1, currentMatchIndex - 1));
  };

  const onNextMatch = () => {
    onMatchIndexChanged(Math.min(currentMatchIndex + 1, totalMatches));
  };

  return (
    <>
      <KeywordsFilter keywords={keywords} placeholder="Search for..." onChange={onKeywordsChanged} noFill className="mr-0" setFocusOnMount={true} />
      <span className="ml-2">
        <Button
          outline
          onClick={onPreviousMatch}
          title={`Find previous occurrence in pages ${startPage} to ${Math.min(numPages, startPage + maxPages - 1)}`}
          className="p-0 border-0"
          disabled={totalMatches < 1}
        >
          <BsArrowLeftCircle size="2rem" />
        </Button>
        &nbsp;|&nbsp;
        <Button
          outline
          onClick={onNextMatch}
          title={`Find next occurrence in pages ${startPage} to ${Math.min(numPages, startPage + maxPages - 1)}`}
          className="p-0 border-0"
          disabled={totalMatches < 1}
        >
          <BsArrowRightCircle size="2rem" />
        </Button>
        {keywords.length > 0 && (
          <span className={`ml-2 ${totalMatches < 1 ? 'font-weight-bold' : ''}`}>
            {totalMatches > 0 ? `${currentMatchIndex} of ${totalMatches} matches` : 'Word not found'}
          </span>
        )}
        <Button outline onClick={onHideSearchbar} title="Hide" className="p-0 border-0 ml-2">
          <MdOutlineClose size="1rem" />
        </Button>
      </span>
    </>
  );
};

export default DocumentSearchbar;
