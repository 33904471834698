import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { Row, Col } from 'reactstrap';

import { DirectoryEvidence, DirectoryEvidenceType } from 'tcf-shared/models';

import EntityAnalysis from './components/EntityAnalysis';
import EntityDetails from './components/EntityDetails';
import EvidenceListCard from './components/EvidenceListCard';
import EvidenceViewModal from './components/EvidenceViewModal';
import { readDirectoryEntity } from '../../actions/directoryEntityActions';
import { resetServerStore } from '../../actions/serverStoreActions';
import { searchDirectoryEvidenceType } from '../../actions/directoryEvidenceTypeActions';
import { useAppDispatch, useAppSelector, usePopupToggler } from '../../utils/hooks';

const ENTITY_STORE_ID = 'EntityView';
const EVIDENCE_TYPE_STORE_ID = 'EntityViewEvidenceType';

const EntityView = () => {
  const dispatch = useAppDispatch();

  const directoryEvidenceModalState = usePopupToggler<DirectoryEvidence>();

  const { id: urlId } = useParams<{ id: string }>();

  const directoryEvidenceTypeList: DirectoryEvidenceType[] = useAppSelector(
    (state) => state?.serverStores?.[EVIDENCE_TYPE_STORE_ID]?.payload?.results ?? [],
  );

  useEffect(() => {
    dispatch(readDirectoryEntity(ENTITY_STORE_ID, urlId));
    dispatch(searchDirectoryEvidenceType(EVIDENCE_TYPE_STORE_ID, {}));
    return () => {
      dispatch(resetServerStore(ENTITY_STORE_ID));
      dispatch(resetServerStore(EVIDENCE_TYPE_STORE_ID));
    };
  }, [dispatch, urlId]);

  return (
    <>
      <Row>
        <Col xs="12" md="6" style={{ marginBottom: '2em' }}>
          <EntityDetails entityStoreId={ENTITY_STORE_ID} />
        </Col>
        {directoryEvidenceTypeList.map((det) => (
          <EvidenceListCard
            parentEntityId={urlId}
            evidenceStoreId={ENTITY_STORE_ID + det.id}
            evidenceType={det}
            handleClickEvidence={directoryEvidenceModalState.open}
            colWidths={{ xs: '12', md: '6' }}
          />
        ))}
        <Col xs="12" style={{ marginBottom: '2em' }}>
          <EntityAnalysis entityStoreId={ENTITY_STORE_ID} />
        </Col>
      </Row>
      {directoryEvidenceModalState.isOpen && directoryEvidenceModalState.data ? (
        <EvidenceViewModal
          entityStoreId={ENTITY_STORE_ID}
          directoryEvidence={directoryEvidenceModalState.data}
          handleCloseModal={directoryEvidenceModalState.close}
        />
      ) : null}
    </>
  );
};

export default EntityView;
