import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { Helmet } from 'react-helmet';

import { trackPageView } from '../../actions/trackerActions';
import { useMediaQuery } from '../../utils/hooks';

interface BasicPageProps {
  title?: string;
  children: React.ReactNode;
}

export default (props: BasicPageProps) => {
  const { pathname, search } = useLocation();
  const [rendered, setRendered] = useState(false);
  const title = (props.title && props.title.substring(0, 70) + ' - TCF Library') || 'TCF Library';
  const printing = useMediaQuery('print');

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  if (printing) return null;

  return (
    <div>
      <Helmet
        onChangeClientState={(newState: any) => {
          if (!rendered) {
            trackPageView(pathname + search, newState.title);
            setRendered(true);
          }
        }}
      >
        <title>{title}</title>
      </Helmet>
      {props.children}
    </div>
  );
};
