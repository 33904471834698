const humanizeBytesUnits = ['kB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

export const humanizeBytes = (size?: number) => {
  if (size === null || size === undefined) return '';

  const thresh = 1024;

  if (Math.abs(size) < thresh) return size + ' B';

  let u = -1;
  const r = 10;

  do {
    size /= thresh;
    ++u;
  } while (Math.round(Math.abs(size) * r) / r >= thresh && u < humanizeBytesUnits.length - 1);

  return size.toFixed(1) + ' ' + humanizeBytesUnits[u];
};
