import { AxiosInstance } from 'axios';

import { ApiResponse } from 'tcf-shared/models';

export class RegionsApiClient {
  constructor(readonly axios: AxiosInstance, readonly baseUrl: string) {}

  async get(id: string): Promise<ApiResponse> {
    return this.axios.get<ApiResponse>(`${this.baseUrl}/${id}`);
  }
}
