import React from 'react';

export interface IconProps {
  name: string;
  size?: number;
  className?: string;
  style?: React.CSSProperties;
}

const svgs = {
  'data-transfer-download': (
    <path d="M3 0v3h-2l3 3 3-3h-2v-3h-2zm-3 7v1h8v-1h-8z" />
  ),
  check: (
    <path
      d="M6.41 0l-.69.72-2.78 2.78-.81-.78-.72-.72-1.41 1.41.72.72 1.5 1.5.69.72.72-.72 3.5-3.5.72-.72-1.44-1.41z"
      transform="translate(0 1)"
    />
  ),
};

export const Icon = (props: IconProps) => {
  const { name, size, className, style } = props;
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size || 24}
      height={size || 24}
      viewBox="0 0 8 8"
      className={`icon ${name} ${className}`}
      style={style}
    >
      {svgs[name]}
    </svg>
  );
};
