import { getAsyncActionTypes } from './getAsyncActionTypes';

// Startup action.
export const GET_STARTUP_DATA = getAsyncActionTypes('GET_STARTUP_DATA');

// Docviews action
export const GET_TOP_DOCS_AND_TOPICS = getAsyncActionTypes('GET_TOP_DOCS_AND_TOPICS');

// Auth actions.
export const LOGIN = getAsyncActionTypes('LOGIN');
export const LOGOUT = getAsyncActionTypes('LOGOUT');
export const SEND_LOGIN_EMAIL = getAsyncActionTypes('SEND_LOGIN_EMAIL');
export const NEW_ACCESS_TOKEN_RECEIVED = 'NEW_ACCESS_TOKEN_RECEIVED';
export const API_REQUEST_NOT_AUTHORIZED = 'API_REQUEST_NOT_AUTHORIZED';

// Companies actions.

// Documents actions.
export const GET_DOCUMENT = getAsyncActionTypes('GET_DOCUMENT');
export const GET_ATTACHMENT = getAsyncActionTypes('GET_ATTACHMENT');
export const SAVE_DOCUMENT = getAsyncActionTypes('SAVE_DOCUMENT');
export const PUBLISH_DOCUMENT = getAsyncActionTypes('PUBLISH_DOCUMENT');
export const DELETE_DOCUMENT = getAsyncActionTypes('DELETE_DOCUMENT');
export const GET_DOWNLOAD_TOKEN = getAsyncActionTypes('GET_DOWNLOAD_TOKEN');
export const UPLOAD_FILE = getAsyncActionTypes('UPLOAD_FILE');
export const DELETE_UPLOADED_FILE = getAsyncActionTypes('DELETE_UPLOADED_FILE');
export const ADD_ASSOCIATED_DOCUMENT = getAsyncActionTypes('ADD_ASSOCIATED_DOCUMENT');
export const RESET_DOCUMENT_STORE = 'RESET_DOCUMENT_STORE';
export const SET_DOCUMENT = 'SET_DOCUMENT';

// Organization actions
export const GET_ORGANIZATION = getAsyncActionTypes('GET_ORGANIZATION');
export const GET_ORGANIZATION_RELATED = getAsyncActionTypes('GET_ORGANIZATION_RELATED');
export const CREATE_ORGANIZATION = getAsyncActionTypes('CREATE_ORGANIZATION');
export const SAVE_ORGANIZATION = getAsyncActionTypes('SAVE_ORGANIZATION');
export const DELETE_ORGANIZATION = getAsyncActionTypes('DELETE_ORGANIZATION');
export const SAVE_ORGANIZATION_USERS = getAsyncActionTypes('SAVE_ORGANIZATION_USERS');

// Search actions.
export const SEARCH = getAsyncActionTypes('SEARCH');
export const RESET_SEARCH = getAsyncActionTypes('RESET_SEARCH');

// Topics actions.

// Users actions.
export const GET_USER_EMAIL_DOMAINS = getAsyncActionTypes('GET_USER_EMAIL_DOMAINS');
export const GET_USERS = getAsyncActionTypes('GET_USERS');
export const SAVE_USERS = getAsyncActionTypes('SAVE_USERS');
export const CREATE_USER_API_TOKEN = getAsyncActionTypes('CREATE_USER_API_TOKEN');
export const SELECT_USERS = 'SELECT_USERS';
export const DESELECT_USERS = 'DESELECT_USERS';

// Profile picture actions.
export const UPLOAD_PROFILE_PICTURE = getAsyncActionTypes('UPLOAD_PROFILE_PICTURE');
export const DELETE_PROFILE_PICTURE = getAsyncActionTypes('DELETE_PROFILE_PICTURE');

// Organization self management actions.
export const SEARCH_SM_USERS = getAsyncActionTypes('SEARCH_SM_USERS');
export const REFRESH_SM_USER = getAsyncActionTypes('REFRESH_SM_USER');
export const CREATE_SM_USER = getAsyncActionTypes('CREATE_SM_USER');
export const UPDATE_SM_USER = getAsyncActionTypes('UPDATE_SM_USER');
export const RESET_SM_USERS_STORE = 'RESET_SM_USERS_STORE';
export const CLEAR_SM_SAVE_ERROR = 'CLEAR_SM_SAVE_ERROR';

// Typical actions for a querying/filtering pages.
export const INITIALIZE_QUERY = 'INITIALIZE_QUERY';
export const SET_QUERY = 'SET_QUERY';
export const SET_QUERY_PAGING_AND_SORTING = 'SET_QUERY_PAGING_AND_SORTING';
export const SET_QUERY_PAGING_AND_SEARCH_SORTING = 'SET_QUERY_PAGING_AND_SEARCH_SORTING';
export const SET_QUERY_FILTER = 'SET_QUERY_FILTER';
export const SET_QUERY_SEARCH = 'SET_QUERY_SEARCH';
export const UPDATE_QUERY_FILTERS = 'UPDATE_QUERY_FILTERS';
export const UPSERT_QUERY_FILTER = 'UPSERT_QUERY_FILTER';
export const REMOVE_QUERY_FILTER = 'REMOVE_QUERY_FILTER';
export const COMPLETELY_REMOVE_QUERY_FILTERS = 'COMPLETELY_REMOVE_QUERY_FILTERS';
export const ADD_TO_QUERY_FILTER_LIST = 'ADD_TO_QUERY_FILTER_LIST';
export const REMOVE_FROM_QUERY_FILTER_LIST = 'REMOVE_FROM_QUERY_FILTER_LIST';
export const RESET_QUERY_FILTER = 'RESET_QUERY_FILTER';
export const RESET_QUERY = 'RESET_QUERY';

// Actions performed against the generic store.
export const READ_SERVER = getAsyncActionTypes('READ_SERVER');
export const SAVE_SERVER = getAsyncActionTypes('SAVE_SERVER');
export const DELETE_SERVER = getAsyncActionTypes('DELETE_SERVER');
export const RESET_SERVER_STORE = 'RESET_SERVER_STORE';
export const NOOP = getAsyncActionTypes('NOOP'); // An action that should not affect the state.

// For adding/replacing/removing a single object in a list type server store.
export const SAVE_SERVER_UPDATE_LIST = getAsyncActionTypes('SAVE_SERVER_UPDATE_LIST');
export const DELETE_SERVER_UPDATE_LIST = getAsyncActionTypes('DELETE_SERVER_UPDATE_LIST');
