import React from 'react';
import { Button, Card, CardBody, CardFooter, CardHeader, CardSubtitle, Col, Row } from 'reactstrap';

import { AlertPreferences, allCategories, allRegions, allSectors, UserEntitlements } from 'tcf-shared/models';

import { arrayIsSubset } from '../../utils';
import CheckboxGroup, { CheckboxTypeDesignators } from './CheckboxGroup';
import SavedSearchDescription from '../../pages/SavedSearch/components/SavedSearchDescription';

const generateSelected = (optOutOfDefaultAlerts: boolean, entitled: string[], current?: string[]): string[] => {
  if (!current?.length) return optOutOfDefaultAlerts ? [] : entitled;
  return current.filter((c) => entitled.includes(c));
};

const everythingIsSelected = (userEntitlements: UserEntitlements, selected: UserEntitlements) => {
  return (
    arrayIsSubset(userEntitlements.categories, selected.categories) &&
    arrayIsSubset(userEntitlements.regions, selected.regions) &&
    arrayIsSubset(userEntitlements.sectors, selected.sectors)
  );
};

interface EditDefaultAlertsProps {
  alertPreferences?: AlertPreferences;
  notEntitledWarning: string;
  onChange: (alertPreferences: AlertPreferences) => void;
  userEntitlements: UserEntitlements;
}

const EditDefaultAlerts = (props: EditDefaultAlertsProps) => {
  const { alertPreferences, notEntitledWarning, onChange, userEntitlements } = props;

  const optOutOfDefaultAlerts = !!alertPreferences?.optOutOfDefaultAlerts;

  const selected = {
    categories: generateSelected(
      optOutOfDefaultAlerts,
      userEntitlements.categories,
      alertPreferences?.defaultAlerts?.categories,
    ),
    regions: generateSelected(optOutOfDefaultAlerts, userEntitlements.regions, alertPreferences?.defaultAlerts?.regions),
    sectors: generateSelected(optOutOfDefaultAlerts, userEntitlements.sectors, alertPreferences?.defaultAlerts?.sectors),
  };

  const disableClearAllButton = !selected.categories?.length && !selected.regions?.length && !selected.sectors?.length;

  const disableSelectAllButton = everythingIsSelected(userEntitlements, selected);

  const applyPatch = (patch: AlertPreferences) => {
    onChange({
      ...(alertPreferences || {}),
      ...patch,
      defaultAlerts: {
        ...(alertPreferences?.defaultAlerts || {}),
        ...(patch?.defaultAlerts || {}),
      },
    });
  };

  const handleClickSelectAll = () => {
    applyPatch({ optOutOfDefaultAlerts: false, defaultAlerts: { categories: [], regions: [], sectors: [] } });
  };

  const handleClickClearAll = () => {
    applyPatch({ optOutOfDefaultAlerts: true, defaultAlerts: { categories: [], regions: [], sectors: [] } });
  };

  const handleCheckboxChange = (designator: CheckboxTypeDesignators, selectedItems: string[]) => {
    const newSelected = { ...selected, [designator]: selectedItems };
    if (everythingIsSelected(userEntitlements, newSelected)) {
      handleClickSelectAll();
    } else {
      applyPatch({
        optOutOfDefaultAlerts: !newSelected.categories.length || !newSelected.regions.length || !newSelected.sectors.length,
        defaultAlerts: newSelected,
      });
    }
    return true;
  };

  return (
    <Card className="mt-4">
      <CardHeader>
        <Row>
          <Col xs="12" lg="6" className="d-flex align-items-center">
            Article Alerts
          </Col>
          <Col xs="12" lg="6" className="d-flex justify-content-start justify-content-lg-end pt-2 pt-lg-0">
            <Button color="primary" className="mb-0 mr-2" onClick={handleClickSelectAll} disabled={disableSelectAllButton}>
              Select All
            </Button>
            <Button color="primary" className="mb-0" onClick={handleClickClearAll} disabled={disableClearAllButton}>
              Clear All
            </Button>
          </Col>
        </Row>
      </CardHeader>
      <CardBody>
        <CardSubtitle className="mb-2">Coverage Categories</CardSubtitle>
        <Row>
          <CheckboxGroup
            allItems={allCategories}
            designator="categories"
            disabled={false}
            entitledItems={userEntitlements.categories}
            notEntitledWarning={notEntitledWarning}
            onChange={handleCheckboxChange}
            sectionLabel="Coverage Category"
            selectedItems={selected.categories}
          />
          {!selected?.categories?.length && (
            <Col xs={12} className="text-danger">
              At least one Coverage Category is required to receive article alerts.
            </Col>
          )}
        </Row>
      </CardBody>
      <CardBody>
        <CardSubtitle className="mb-2">Regions</CardSubtitle>
        <Row>
          <CheckboxGroup
            allItems={allRegions}
            designator="regions"
            disabled={false}
            entitledItems={userEntitlements.regions}
            notEntitledWarning={notEntitledWarning}
            onChange={handleCheckboxChange}
            sectionLabel="Region"
            selectedItems={selected.regions}
          />
          {!selected?.regions?.length && (
            <Col xs={12} className="text-danger">
              At least one Region is required to receive article alerts.
            </Col>
          )}
        </Row>
      </CardBody>
      <CardBody>
        <CardSubtitle className="mb-2">Industry Sectors</CardSubtitle>
        <Row>
          <CheckboxGroup
            allItems={allSectors}
            designator="sectors"
            disabled={false}
            entitledItems={userEntitlements.sectors}
            notEntitledWarning={notEntitledWarning}
            onChange={handleCheckboxChange}
            sectionLabel="Industry Sector"
            selectedItems={selected.sectors}
          />
          {!selected?.sectors?.length && (
            <Col xs={12} className="text-danger">
              At least one Industry Sector is required to receive article alerts.
            </Col>
          )}
        </Row>
      </CardBody>
      <CardFooter>
        {optOutOfDefaultAlerts ? (
          <span className={'font-italic text-danger font-weight-bold'}>No article alerts will be sent.</span>
        ) : (
          <SavedSearchDescription
            savedSearch={{
              categories: selected.categories,
              regions: selected.regions,
              sectors: selected.sectors,
            }}
          />
        )}
      </CardFooter>
    </Card>
  );
};

export default EditDefaultAlerts;
