import React from 'react';
// import { bindActionCreators } from 'redux';
// import { connect } from 'react-redux';
import moment from 'moment';

import { Document, Topic } from 'tcf-shared/models';

import { useAppDispatch } from '../../../utils/hooks';
import { getDownloadToken } from '../../../actions/documentsActions';
import { trackPageView } from '../../../actions/trackerActions';
import { DocumentRelated } from '../../../reducers/documentsReducer';

const getDownloadFilename = (doc: Document, documentRelated?: any) => {
  const topic = (doc.topics?.length && (documentRelated?.topics || []).find((t: Topic) => t.id === doc.topics[0].id)) || null;

  const pubDate = doc.meta.publishing?.displayPublishedAt || doc.meta.updatedAt;
  const titleWords = doc.title!.split(/[^a-zA-Z0-9-]+/);
  let title = '';
  while (title.length < 50 && titleWords.length > 0) {
    title += titleWords.splice(0, 1)[0] + ' ';
  }

  const extension = !doc.file ? '.pdf' : doc.file.originalName.substring(doc.file.originalName.lastIndexOf('.'));

  return (
    (topic ? topic.name + ' ' : '') +
    // Date format intentional for sorting purposes.  DO NOT CHANGE!
    moment.utc(pubDate).format('YYYY-MM-DD') +
    ' ' +
    title.trimRight() +
    extension
  );
};

export interface DownloadLinkProps {
  label: string;
  className?: string;
  style?: {};
  document: Document;
  documentRelated?: DocumentRelated;
  onCompletion: () => void;
}

// We can't pass headers in an anchor tag, and using a blob url
// doesn't work - the user gets prompted for the PDF password
// even though they can open/read without one. So we retrieve
// a short-lived token and pass it back in the query string.
const DownloadLink = (props: DownloadLinkProps) => {
  const dispatch = useAppDispatch();
  const { document, label, className, style, documentRelated, onCompletion } = props;
  const filename = getDownloadFilename(document, documentRelated);

  let downloading = false;
  const downloadUrl = `/api/docs/${document.id}/file?dl=1`;
  const getTokenAndDownload = (evt: React.MouseEvent<HTMLAnchorElement>) => {
    if (!downloading) {
      downloading = true;
      evt.preventDefault();
      // Get a token, set href with it, simulate a click, reset href.
      const anchor = evt.currentTarget as HTMLAnchorElement;
      dispatch(getDownloadToken(document.id)).then(({ token }: any) => {
        anchor.href = `${downloadUrl}&dt=${token}`;
        anchor.click();
        anchor.href = downloadUrl;
        trackPageView(downloadUrl, window.document.title);
        downloading = false;
        onCompletion();
      });
    }
  };

  return (
    <a
      className={className || 'btn btn-primary'}
      style={style}
      download={filename}
      href={downloadUrl}
      onClick={getTokenAndDownload}
    >
      {label}
    </a>
  );
};

export default DownloadLink;
// connect<DispatchProps, OwnProps>(undefined, (dispatch: any) =>
//   bindActionCreators({ getDownloadToken } as any, dispatch as any),
// )(DownloadLink);
