import { AxiosInstance } from 'axios';

import { ApiResponse, ReportQuery } from 'tcf-shared/models';

import { CrudApiClient } from './CrudApiClient';

export class UsageApiClient extends CrudApiClient {
  constructor(readonly axios: AxiosInstance) {
    super(axios, '/usage');
  }

  async getReport(query: ReportQuery): Promise<ApiResponse> {
    return this.axios.post<ApiResponse>(`${this.baseUrl}/report`, query);
  }
}
