import React, { useEffect, useMemo } from 'react';
import {
  Button,
  Col,
  Form,
  FormFeedback,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
  Spinner,
  Card,
  CardBody,
  CardHeader,
} from 'reactstrap';
import { Form as RFForm, Field as RFField } from 'react-final-form';

import { User, resolveUserEntitlements } from 'tcf-shared/models';

import * as Inputs from '../../components/Inputs';
import { OrganizationSelfManagementState } from '../../reducers/organizationSelfManagementReducer';
import { ServerStore } from '../../reducers/serverStoresReducer';
import {
  clearSMSaveError,
  createSMUser,
  readSMLicenseCount,
  refreshSMUser,
  updateSMUser,
  readSMOrganizationEntitlements,
} from '../../actions/organizationSelfManagementActions';
import { useAppDispatch, useAppSelector } from '../../utils/hooks';
import EditDefaultAlerts from '../../components/EditDefaultAlerts';
import { resetServerStore } from '../../actions/serverStoreActions';

const ORGANIZATION_ENTITLEMENTS_STORE_ID = 'organizationEntitlementsStoreId';

interface EditUserModalProps {
  userId?: string;
  handleCloseModal: () => void;
  licenseStoreId: string;
}

const validateEmailAddress = (value: string) => {
  const email = (value ?? '').trim();
  if (!/^.+@.+\..+$/.test(email)) return 'A valid email address is required';
};

const EditUserModal = (props: EditUserModalProps) => {
  const dispatch = useAppDispatch();

  const { userId, handleCloseModal, licenseStoreId } = props;

  useEffect(() => {
    if (userId) dispatch(refreshSMUser(userId));
    dispatch(readSMOrganizationEntitlements(ORGANIZATION_ENTITLEMENTS_STORE_ID));
    return () => {
      dispatch(clearSMSaveError());
      dispatch(resetServerStore(ORGANIZATION_ENTITLEMENTS_STORE_ID));
    };
  }, [dispatch, userId]);

  const searchState: OrganizationSelfManagementState = useAppSelector((state) => state?.organizationSelfManagement);
  const isRefreshing = searchState?.isRefreshing;
  const refreshError = searchState?.refreshError;
  const isSaving = searchState?.isSaving;
  const saveError = searchState?.saveError;

  const user: Partial<User> = useMemo(() => {
    if (!userId) return { disabled: false };
    const searchResults = searchState?.payload?.results ?? [];
    return searchResults.find((r: Partial<User>) => r.id === userId) || {};
  }, [userId, searchState]);

  const handleSubmit = async (userToSave: Partial<User>) => {
    const func = userToSave.id ? updateSMUser : createSMUser;
    const { overrideLibraryOrgEntitlements, categories, regions, sectors, ...userToSaveWithoutReadonlyProps } = userToSave;
    try {
      await dispatch(func(userToSaveWithoutReadonlyProps));
      handleCloseModal();
      await dispatch(readSMLicenseCount(licenseStoreId));
    } catch {
      // Swallow errors here.  Errors are handled by state management in the modal.
    }
  };

  const organizationEntitlementsState: ServerStore = useAppSelector(
    (s) => s.serverStores?.[ORGANIZATION_ENTITLEMENTS_STORE_ID],
  );
  const organizationEntitlementsIsFetching = organizationEntitlementsState?.isFetching;
  const organizationEntitlementsError = organizationEntitlementsState?.error;

  return (
    <RFForm initialValues={user} onSubmit={handleSubmit}>
      {(formProps) => {
        const formDisabled =
          formProps.submitting ||
          isRefreshing ||
          isSaving ||
          !!refreshError ||
          organizationEntitlementsIsFetching ||
          !!organizationEntitlementsError;
        const resolvedUserEntitlements = resolveUserEntitlements(formProps.values, organizationEntitlementsState?.payload);

        return (
          <Modal backdrop="static" isOpen={true} toggle={handleCloseModal} className="modal-lg">
            <Form onSubmit={formProps.handleSubmit}>
              <ModalHeader
                tag="span"
                className="bg-dark"
                style={{ border: '2px solid #555', color: 'white' }}
                close={
                  <span
                    className="close clickable"
                    onClick={handleCloseModal}
                    style={{ color: '#FFFFFF', opacity: '.9', textShadow: 'none' }}
                  >
                    x
                  </span>
                }
                toggle={handleCloseModal}
              >
                {isRefreshing || organizationEntitlementsIsFetching ? (
                  <Spinner size="sm">Loading...</Spinner>
                ) : formProps.initialValues.id ? (
                  'Edit user'
                ) : (
                  'Create user'
                )}
              </ModalHeader>
              <ModalBody className="m-2">
                {(refreshError || saveError || organizationEntitlementsError) && (
                  <FormFeedback className="d-block mb-2">
                    {refreshError || saveError || organizationEntitlementsError}
                  </FormFeedback>
                )}
                <Row>
                  <Col lg="6">
                    <Inputs.TcfTextInput
                      name="firstName"
                      placeholder="First Name"
                      label="First Name"
                      type="text"
                      autoComplete="off"
                      disabled={formDisabled}
                      maxLength={250}
                    />
                  </Col>
                  <Col lg="6">
                    <Inputs.TcfTextInput
                      name="lastName"
                      placeholder="Last Name"
                      label="Last Name"
                      type="text"
                      autoComplete="off"
                      disabled={formDisabled}
                      maxLength={250}
                    />
                  </Col>
                  <Col lg="6">
                    <Inputs.TcfTextInput
                      name="email"
                      placeholder="Email Address"
                      label="Email Address"
                      type="text"
                      autoComplete="off"
                      // 2/23/24 - email can no longer be edited if existing user
                      disabled={formDisabled || !!userId}
                      maxLength={254}
                      requiredIndicator={true}
                      validate={validateEmailAddress}
                    />
                  </Col>
                  <Col lg="6" style={{ paddingTop: '1rem' }}>
                    {/* Note that we are effectively showing "enabled" on the page but the value in the */}
                    {/* database is actually "disabled" thus the wonky logic to reverse the display value. */}
                    <Inputs.TcfCheckbox
                      name="disabled"
                      label="Subscription Enabled"
                      disabled={formDisabled}
                      format={(value: boolean | undefined) => !value}
                      parse={(value: boolean) => !value}
                    />
                    <Inputs.TcfCheckbox
                      name="canManageOrganization"
                      label="Administrator"
                      title="Designates individuals who may perform user management for their organization."
                      disabled={formDisabled}
                    />
                  </Col>
                  <Col>
                    <div style={{ marginBottom: '1rem' }}>
                      <Card>
                        <CardHeader>Email Settings</CardHeader>
                        <CardBody>
                          <Row>
                            <Col xs={12} lg={4} className="mb-2">
                              <Inputs.TcfCheckbox
                                name="alertPreferences.optOutOfDigestAlerts"
                                label="Send digest alerts"
                                title="Set/override user preference on weekly digest notifications"
                                format={(value: boolean | undefined) => !value}
                                parse={(value: boolean) => !value}
                              />
                            </Col>
                            <Col xs={12} lg={6} className="mb-2">
                              <Inputs.TcfCheckbox
                                name="alertPreferences.optOutOfAnnouncements"
                                label="Send conference call invitations and other announcements"
                                title="Set/override user preference on announcement notifications"
                                format={(value: boolean | undefined) => !value}
                                parse={(value: boolean) => !value}
                              />
                            </Col>
                          </Row>
                          <Row>
                            <Col xs={12}>
                              <RFField name="alertPreferences">
                                {(fieldProps) => (
                                  <EditDefaultAlerts
                                    alertPreferences={fieldProps.input.value}
                                    notEntitledWarning="User is not currently subscribed to this %s."
                                    onChange={fieldProps.input.onChange}
                                    userEntitlements={resolvedUserEntitlements}
                                  />
                                )}
                              </RFField>
                            </Col>
                          </Row>
                        </CardBody>
                      </Card>
                    </div>
                  </Col>
                </Row>
                <div>
                  <small>
                    <i>
                      {userId && (
                        <b>
                          To change an email address, please contact your account manager or email{' '}
                          <a href="mailto:editorial@thecapitolforum.com">editorial@thecapitolforum.com</a>.{' '}
                        </b>
                      )}
                      Please note that permission and access changes may take up to an hour to take effect. The user in question
                      may log out and back in to accelerate the process.
                    </i>
                  </small>
                </div>
              </ModalBody>
              <ModalFooter>
                <Button type="submit" color="primary" disabled={formDisabled || formProps.pristine}>
                  {formProps.submitting || isSaving ? 'Saving...' : 'Save'}
                </Button>
                <Button color="secondary" onClick={handleCloseModal}>
                  Cancel
                </Button>
              </ModalFooter>
            </Form>
          </Modal>
        );
      }}
    </RFForm>
  );
};

export default EditUserModal;
