import React, { useState } from 'react';
import { DropdownItem, DropdownMenu, DropdownToggle, UncontrolledDropdown } from 'reactstrap';

import { getAuthUser } from '../../reducers/authReducer';
import { useAppSelector } from '../../utils/hooks';
import ContactSupport from '../forms/ContactSupport';

const HelpMenu = ({ closeMenu }: { closeMenu: () => void }) => {
  const authUser = useAppSelector((state) => getAuthUser(state));

  const [contactSupportModalOpen, setContactSupportModalOpen] = useState(false);

  const onContactSupportClick = () => {
    closeMenu();
    setContactSupportModalOpen(true);
  };

  if (!authUser?.isPremiumSubscriber && !authUser?.canManageOrganization) return null;

  return (
    <>
      <UncontrolledDropdown nav inNavbar>
        <DropdownToggle nav caret>
          Help
        </DropdownToggle>
        <DropdownMenu right>
          <a
            href="https://thecapitolforum.com/wp-content/uploads/2023/12/TCF-GUIDE-20231220.pdf"
            target="_blank"
            rel="noopener noreferrer"
            className="dropdown-item"
          >
            User Guide (PDF)
          </a>
          {authUser?.canManageOrganization && (
            <a
              href="https://thecapitolforum.com/wp-content/uploads/2023/02/TCF-GUIDE-2023-3-ADM-Tools-1.pdf"
              target="_blank"
              rel="noopener noreferrer"
              className="dropdown-item"
            >
              Administrator Guide (PDF)
            </a>
          )}
          <DropdownItem divider />
          <DropdownItem onClick={onContactSupportClick}>Contact Support</DropdownItem>
        </DropdownMenu>
      </UncontrolledDropdown>
      {contactSupportModalOpen ? <ContactSupport closeModal={() => setContactSupportModalOpen(false)} /> : null}
    </>
  );
};

export default HelpMenu;
