import { ApiError } from 'tcf-shared/models';

import { Action } from '../actions/Action';
import {
  READ_SERVER,
  SAVE_SERVER,
  DELETE_SERVER,
  RESET_SERVER_STORE,
  LOGOUT,
  SAVE_SERVER_UPDATE_LIST,
  DELETE_SERVER_UPDATE_LIST,
  UPLOAD_PROFILE_PICTURE,
  DELETE_PROFILE_PICTURE,
} from '../actions/actionTypes';

export interface ServerStore {
  payload?: any;
  error: string;
  isFetching: boolean; // read
  isUpdating: boolean; // create, update, delete
}

const initialStoreState: ServerStore = {
  error: '',
  isFetching: false,
  isUpdating: false,
};

export interface ServerStoresState {
  [storeIdentifier: string]: ServerStore;
}

const initialState: ServerStoresState = {};

export const serverStoresReducer = (state = initialState, action: Action): ServerStoresState => {
  let updatedStoreState: ServerStore;

  switch (action.type) {
    case READ_SERVER.REQUESTED:
      updatedStoreState = {
        ...state[action.storeIdentifier!],
        isFetching: true,
      };
      break;

    case SAVE_SERVER_UPDATE_LIST.REQUESTED:
    case SAVE_SERVER.REQUESTED:
    case DELETE_SERVER_UPDATE_LIST.REQUESTED:
    case DELETE_SERVER.REQUESTED:
      updatedStoreState = {
        ...state[action.storeIdentifier!],
        isUpdating: true,
      };
      break;

    // These probably don't belong here, but I didn't want to create a new reducer for just two
    // actions that work against the server store.
    case DELETE_PROFILE_PICTURE.SUCCEEDED:
    case UPLOAD_PROFILE_PICTURE.SUCCEEDED:
      updatedStoreState = {
        ...initialStoreState,
        payload: action.payload,
      };
      break;

    case READ_SERVER.SUCCEEDED:
    case SAVE_SERVER.SUCCEEDED:
      updatedStoreState = {
        ...initialStoreState,
        payload: action.payload,
      };
      break;

    case SAVE_SERVER_UPDATE_LIST.SUCCEEDED:
      const isNew = !state[action.storeIdentifier!].payload.results.find((r: any) => r.id === action.payload.id);
      if (isNew) {
        updatedStoreState = {
          ...state[action.storeIdentifier!],
          payload: {
            ...state[action.storeIdentifier!].payload,
            total: state[action.storeIdentifier!].payload.total + 1,
            results: [action.payload, ...state[action.storeIdentifier!].payload.results],
          },
          error: '',
          isUpdating: false,
        };
      } else {
        updatedStoreState = {
          ...state[action.storeIdentifier!],
          payload: {
            ...state[action.storeIdentifier!].payload,
            results: state[action.storeIdentifier!].payload.results.map((r: any) =>
              r.id === action.payload.id ? action.payload : r,
            ),
          },
          error: '',
          isUpdating: false,
        };
      }
      break;

    case DELETE_SERVER_UPDATE_LIST.SUCCEEDED:
      updatedStoreState = {
        ...state[action.storeIdentifier!],
        payload: {
          ...state[action.storeIdentifier!].payload,
          total: state[action.storeIdentifier!].payload.total - 1,
          results: state[action.storeIdentifier!].payload.results.filter((r: any) => r.id !== action.deleteId),
        },
        error: '',
        isUpdating: false,
      };
      break;

    case DELETE_SERVER.SUCCEEDED:
      updatedStoreState = {
        ...initialStoreState,
      };
      break;

    case READ_SERVER.FAILED: {
      const error = action.payload as ApiError;
      updatedStoreState = {
        ...initialStoreState,
        error: error?.message ?? 'Not found',
      };
      break;
    }

    case SAVE_SERVER_UPDATE_LIST.FAILED:
    case SAVE_SERVER.FAILED: {
      const error = action.payload as ApiError;
      updatedStoreState = {
        ...state[action.storeIdentifier!],
        error: error?.message ?? 'Failed to save record',
        isUpdating: false,
      };
      break;
    }

    case DELETE_SERVER_UPDATE_LIST.FAILED:
    case DELETE_SERVER.FAILED: {
      const error = action.payload as ApiError;
      updatedStoreState = {
        ...state[action.storeIdentifier!],
        error: error?.message ?? 'Failed to delete record',
        isUpdating: false,
      };
      break;
    }

    case RESET_SERVER_STORE:
      updatedStoreState = {
        ...initialStoreState,
      };
      break;

    case LOGOUT.SUCCEEDED:
    case LOGOUT.FAILED:
      return initialState;

    default:
      return state;
  }

  return { ...state, [action.storeIdentifier!]: updatedStoreState };
};
