// Some functions to assist with determining the current Bootstrap window size.  Uses default breakpoints.
import { useEffect, useState } from 'react';

export type BootstrapWindowSize = 'xs' | 'sm' | 'md' | 'lg' | 'xl' | 'xxl';

export type BootstrapWindowBreakpoints = {
  [key in BootstrapWindowSize]: number;
};

export const BOOTSTRAP_WINDOW_SIZES: BootstrapWindowSize[] = ['xs', 'sm', 'md', 'lg', 'xl', 'xxl'];

export const BOOTSTRAP_WINDOW_SIZE_LOWER_BOUNDS: BootstrapWindowBreakpoints = {
  xs: 0,
  sm: 576,
  md: 768,
  lg: 992,
  xl: 1200,
  xxl: 1400,
};

export const getWindowSize = (windowWidth: number): BootstrapWindowSize => {
  return windowWidth < BOOTSTRAP_WINDOW_SIZE_LOWER_BOUNDS.sm
    ? 'xs'
    : windowWidth < BOOTSTRAP_WINDOW_SIZE_LOWER_BOUNDS.md
    ? 'sm'
    : windowWidth < BOOTSTRAP_WINDOW_SIZE_LOWER_BOUNDS.lg
    ? 'md'
    : windowWidth < BOOTSTRAP_WINDOW_SIZE_LOWER_BOUNDS.xl
    ? 'lg'
    : windowWidth < BOOTSTRAP_WINDOW_SIZE_LOWER_BOUNDS.xxl
    ? 'xl'
    : 'xxl';
};

export const useWindowSizeTracker = () => {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [bootstrapWindowSize, setBootstrapWindowSize] = useState(getWindowSize(window.innerWidth));

  const isWindowThisSize = (size: BootstrapWindowSize) => {
    return size === bootstrapWindowSize;
  };

  const isWindowThisSizeOrLarger = (minSize: BootstrapWindowSize) => {
    return BOOTSTRAP_WINDOW_SIZE_LOWER_BOUNDS[bootstrapWindowSize] >= BOOTSTRAP_WINDOW_SIZE_LOWER_BOUNDS[minSize];
  };

  const isWindowThisSizeOrSmaller = (maxSize: BootstrapWindowSize) => {
    return BOOTSTRAP_WINDOW_SIZE_LOWER_BOUNDS[bootstrapWindowSize] <= BOOTSTRAP_WINDOW_SIZE_LOWER_BOUNDS[maxSize];
  };

  const isWindowInThisRange = (minSize: BootstrapWindowSize, maxSize: BootstrapWindowSize) => {
    return (
      BOOTSTRAP_WINDOW_SIZE_LOWER_BOUNDS[minSize] <= windowWidth && windowWidth <= BOOTSTRAP_WINDOW_SIZE_LOWER_BOUNDS[maxSize]
    );
  };

  useEffect(() => {
    const updateWidth = () => {
      const newWidth = window.innerWidth;
      setWindowWidth(newWidth);
      setBootstrapWindowSize(getWindowSize(newWidth));
    };
    window.addEventListener('resize', updateWidth);
    return () => window.removeEventListener('resize', updateWidth);
  }, []);

  return {
    windowWidth,
    bootstrapWindowSize,
    isWindowThisSize,
    isWindowThisSizeOrLarger,
    isWindowThisSizeOrSmaller,
    isWindowInThisRange,
  };
};
