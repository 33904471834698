import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';

import { AuthUser, SavedSearch } from 'tcf-shared/models';

import { useAppDispatch, useAppSelector } from '../../utils/hooks';
import { paths } from '../../paths';
import { getSavedSearch, saveSavedSearch, deleteSavedSearch } from '../../actions/savedSearchesActions';
import { getUserWithEntitlements } from '../../actions/usersActions';
import AsyncPage from '../AsyncPage/AsyncPage';
import { ConfirmDialog, NotFoundError } from '../../components';
import { getAuthUser } from '../../reducers/authReducer';
import { resetServerStore } from '../../actions/serverStoreActions';
import EditSearchForm from './components/EditSearchForm';
import Loader from 'react-loader';
import ErrorComponent from '../AsyncPage/ErrorComponent';

const STORE_ID = 'EditSavedSearchStoreId';
const TARGET_USER_STORE_ID = 'EditSavedSearchEntitlementsStoreid';

const EditSearch = () => {
  const dispatch = useAppDispatch();
  const history = useHistory();
  const { userId: targetUserId, id } = useParams<{ userId: string; id: string }>();
  const [delayRender, setDelayRender] = useState(true);
  const authUser: AuthUser | undefined = useAppSelector((state) => getAuthUser(state));
  const isAdmin = authUser?.isAdmin;
  const authUserId = authUser?.id || '';
  const isOwnSearch = authUserId === targetUserId;
  const savedSearchStore = useAppSelector((state) => state?.serverStores?.[STORE_ID]);
  const savedSearch: SavedSearch | undefined = savedSearchStore?.payload;
  const entitlementsStore = useAppSelector((state) => state?.serverStores?.[TARGET_USER_STORE_ID]);
  const targetUserEntitlements: any = entitlementsStore?.payload;
  const entitlements = isOwnSearch ? authUser : targetUserEntitlements;
  const isFetching: boolean = savedSearchStore?.isFetching || entitlementsStore?.isFetching;
  const error = savedSearchStore?.error || entitlementsStore?.error;
  const isNew = id === 'new';
  const isTransitioning = savedSearch?.id !== (isNew ? undefined : id);
  const [showDeleteDialog, setShowDeleteDialog] = useState(false);
  const toggleDeleteDialog = () => setShowDeleteDialog(!showDeleteDialog);

  const returnToUrl = isOwnSearch
    ? paths.VIEW_ACCOUNT
    : isAdmin
    ? paths.admin.VIEW_USER.replace(':id', targetUserId)
    : paths.HOME;

  useEffect(() => {
    window.scrollTo(0, 0);
    if (isOwnSearch || isAdmin) {
      if (!isNew && id) {
        dispatch(getSavedSearch(STORE_ID, id));
      }
      if (!isOwnSearch) {
        dispatch(getUserWithEntitlements(TARGET_USER_STORE_ID, targetUserId));
      }
    }
    setDelayRender(false);
    return () => {
      dispatch(resetServerStore(STORE_ID));
    };
  }, [targetUserId, isNew, id, isOwnSearch, isAdmin, dispatch]);

  if (!authUser || !(isOwnSearch || isAdmin)) {
    return <NotFoundError />;
  }

  if (isFetching || delayRender || isTransitioning) {
    return <Loader loaded={false} />;
  } else if (error) {
    return <ErrorComponent error={error} />;
  }

  const handleSubmit = async (_savedSearch: Partial<SavedSearch>) => {
    const newSavedSearch = await dispatch(saveSavedSearch(STORE_ID, _savedSearch));
    if (isNew) {
      history.replace(paths.EDIT_SEARCH.replace(':id', newSavedSearch.id).replace(':userId', targetUserId));
    }
  };

  const handleDelete = async () => {
    const updatedAt = savedSearch?.meta?.updatedAt;
    if (updatedAt && savedSearch?.id) {
      await dispatch(deleteSavedSearch(STORE_ID, savedSearch.id, updatedAt));
      history.replace(returnToUrl);
    }
  };

  return (
    <AsyncPage title={`${isNew ? 'Create' : 'Edit'} Saved Search`} loading={isFetching} error={error}>
      <div className={'py-3 px-4 px-md-3'}>
        <EditSearchForm
          savedSearch={savedSearch || { userId: targetUserId }}
          entitlements={entitlements}
          handleSubmit={handleSubmit}
          handleDelete={toggleDeleteDialog}
          targetUserId={targetUserId}
          isOwnSearch={isOwnSearch}
          targetUserEmail={entitlements.email}
        />
        {showDeleteDialog && (
          <ConfirmDialog
            isOpen={showDeleteDialog}
            toggle={toggleDeleteDialog}
            header="Really delete this search?"
            body="This action cannot be reversed."
            confirmButtonText="Yes, delete search"
            confirmButtonColor="danger"
            onConfirm={handleDelete}
          />
        )}
      </div>
    </AsyncPage>
  );
};

export default EditSearch;
