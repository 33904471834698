import React from 'react';
import { Col, Row } from 'reactstrap';

export const NotFoundError = () => {
  return (
    <Row>
      <Col>
        <h2>The requested page could not be found.</h2>
      </Col>
    </Row>
  );
};

