import React from 'react';
import { Modal, ModalBody, ModalHeader } from 'reactstrap';

export interface SearchHintsModalProps {
  showModal?: boolean;
  toggleModal: any;
}

const SearchHintsModal = (props: SearchHintsModalProps) => {
  const { showModal, toggleModal } = props;
  return (
    <Modal isOpen={showModal} toggle={toggleModal}>
      <ModalHeader toggle={toggleModal}>Search Hints</ModalHeader>
      <ModalBody>
        <p>Text search supports the following operators:</p>
        <ul>
          <li>
            {' '}
            + Acts as the <i>and</i> operator
          </li>
          <li>
            {' '}
            | Acts as the <i>or</i> operator
          </li>
          <li> - Negates the word</li>
          <li> "" Wraps multiple words into a phrase</li>
          <li> * Acts as a wildcard at the end of a word</li>
          <li> () Wraps a clause for precedence</li>
        </ul>
        <p>Some examples to find documents containing:</p>
        <ul>
          <li>
            {' '}
            Any of several terms (default if no operator specified): <i>google amazon meta twitter</i>
          </li>
          <li>
            {' '}
            Either of two terms: <i>google | amazon</i>
          </li>
          <li>
            {' '}
            Both terms: <i>+google +amazon</i>
          </li>
          <li>
            {' '}
            References to DOJ: <i>DOJ "Department of Justice"</i>
          </li>
          <li>
            {' '}
            Amazon, but not AWS: <i>Amazon amzn +-AWS</i>
            <p>Note that leaving out the "+" operator would return articles that included "Amazon" OR did not include "AWS"</p>
          </li>
        </ul>
      </ModalBody>
    </Modal>
  );
};

export default SearchHintsModal;
