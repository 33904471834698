import React from 'react';
import { Card, CardBody, CardHeader } from 'reactstrap';
import Loader from 'react-loader';

import { useAppSelector } from '../../../utils/hooks';
import ErrorComponent from '../../AsyncPage/ErrorComponent';

interface OwnProps {
  entityStoreId: string;
}

const EntityAnalysis = (props: OwnProps) => {
  const { entityStoreId } = props;

  const directoryEntityState = useAppSelector((state) => state?.serverStores?.[entityStoreId] ?? {});
  const isFetching = directoryEntityState?.isFetching ?? true;
  const error = directoryEntityState?.error ?? '';
  const directoryEntity = directoryEntityState?.payload ?? {};

  if (isFetching) return <Loader loaded={false} />;
  if (error) return <ErrorComponent error={error} />;

  if (!directoryEntity?.analysis) return null;

  return (
    <Card>
      <CardHeader>TCF Analysis</CardHeader>
      <CardBody>
        <p style={{ whiteSpace: 'pre-wrap' }}>{directoryEntity.analysis}</p>
      </CardBody>
    </Card>
  );
};

export default EntityAnalysis;
