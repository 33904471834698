import { useCookies } from 'react-cookie';

// Technically, this is CookieSetOptions from the 'universal-cookie' package upon which 'react-cookie' relies, but
// I didn't want to explicitly install yet another package just to prevent TypeScript from yelling at me.
const cookieOptions: any = {
  path: '/',
  secure: true,
  sameSite: 'strict',
};

export const useSimpleKeyValueCookie = (key: string, defaultValue: string) => {
  const [cookies, setCookie] = useCookies([key]);
  const _value = cookies[key] || defaultValue;
  const setCookieValue = (value: string) => {
    setCookie(key, value, cookieOptions);
  };
  return { value: _value, setCookieValue };
};
