export const longDateFormat = 'MMM D, YYYY';
export const shortDateFormat = 'M/D/YYYY';
export const veryShortDateFormat = 'M/D/YY';

export const longDateTimeFormat = 'MMM D, YYYY h:mm a';
export const shortDateTimeFormat = 'M/D/YYYY h:mma';

export const veryLongDateTimeTZFormat = 'MMM D, YYYY, h:mm a z';

export const shortDateTimeSecondFormat = 'M/D/YY h:mm:ssa';
