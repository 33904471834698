import React, { useState, useEffect } from 'react';
import { BsFillXCircleFill } from 'react-icons/bs';

import { Topic } from 'tcf-shared/models';

import { useAppDispatch, useAppSelector } from '../../utils/hooks';
import { searchTopics } from '../../actions/topicsActions';
import KeywordsFilter from '../filters/KeywordsFilter';

interface IssuesSelectProps {
  input: any;
  useSimpleArray: boolean;
  handleTagChanges?: (selectedTopics: Topic[]) => void;
  matchesStoreId: string;
  issuesStoreId: string;
}

const IssuesSelect = (props: IssuesSelectProps) => {
  const dispatch = useAppDispatch();

  const { input, matchesStoreId, issuesStoreId, useSimpleArray, handleTagChanges } = props;
  const { value, onChange } = input;

  const [keywords, setKeywords] = useState('');
  const [showMatches, setShowMatches] = useState(false);

  const issueList: Topic[] = useAppSelector((s) => s.serverStores?.[issuesStoreId]?.payload?.results) || [];
  const issueLookup = issueList.reduce((dict: { [key: string]: string }, issue: Topic) => {
    dict[issue.id] = issue.name;
    return dict;
  }, {});

  const selectedIssues = value || [];
  const selectedIssueIds: string[] = useSimpleArray ? selectedIssues : selectedIssues.map((i: any) => i.id);
  const selectedIssueIdsString = selectedIssueIds.join(' ');

  const matches: Topic[] = useAppSelector((s) => s.serverStores?.[matchesStoreId]?.payload?.results) || [];

  useEffect(() => {
    if (selectedIssueIdsString?.length) {
      const _selectedIssues = selectedIssueIdsString.split(' ');
      if (_selectedIssues?.length > 0) {
        dispatch(searchTopics(issuesStoreId, { limit: _selectedIssues.length, search: { ids: _selectedIssues } }));
      }
    }
  }, [selectedIssueIdsString, issuesStoreId, dispatch]);

  useEffect(() => {
    if (keywords?.length) {
      const _selectedIssueIds = selectedIssueIdsString.split(' ');
      dispatch(searchTopics(matchesStoreId, { limit: 20, search: { name: keywords, not_ids: _selectedIssueIds } }));
      setShowMatches(true);
    } else {
      setShowMatches(false);
    }
  }, [keywords, selectedIssueIdsString, matchesStoreId, dispatch]);

  const onRemoveIssueId = (event: any, issueId: string) => {
    event.stopPropagation();
    const newIssues = selectedIssues.filter((i: any) => (useSimpleArray ? i !== issueId : i.id !== issueId));
    const newIssueIds: string[] = useSimpleArray ? newIssues : newIssues.map((i: any) => i.id);
    const newIssueList = issueList.filter((i: Topic) => newIssueIds.includes(i.id));
    onChange(newIssues);
    if (handleTagChanges) {
      handleTagChanges(newIssueList);
    }
  };

  const onAddIssue = (event: any, issue: Topic) => {
    event.stopPropagation();
    issueList.push(issue);
    const newIssues = [...selectedIssues, useSimpleArray ? issue.id : { id: issue.id }];
    const newIssueIds: string[] = useSimpleArray ? newIssues : newIssues.map((i: any) => i.id);
    const newIssueList = issueList.filter((i: Topic) => newIssueIds.includes(i.id));
    onChange(newIssues);
    if (handleTagChanges) {
      handleTagChanges(newIssueList);
    }
    setKeywords('');
  };

  const handleKeywordBlur = () => {
    // This weird function allows us to have an onBlur on the keyword filter that doesn't
    // immediately close the dropdown before the onClick happens.  It's a polish thing
    // for sure, but now we can click away from the dropdown and it will close instead of
    // staying open forever.
    setTimeout(() => {
      setKeywords('');
    }, 200);
  };

  return (
    <>
      {selectedIssues.map((i: any) => (
        <div className="mb-3 " key={useSimpleArray ? i : i.id}>
          <BsFillXCircleFill onClick={(e) => onRemoveIssueId(e, useSimpleArray ? i : i.id)} className="mr-2 clickable" />
          {issueLookup[useSimpleArray ? i : i.id] || ''}
        </div>
      ))}
      <div style={{ position: 'relative' }}>
        <div className="d-flex">
          <KeywordsFilter
            keywords={keywords}
            placeholder="Enter issue name..."
            onChange={setKeywords}
            onBlur={handleKeywordBlur}
          />
        </div>
        <div
          style={{
            display: showMatches ? 'block' : 'none',
            paddingLeft: '10px',
            paddingRight: '10px',
            position: 'absolute',
            top: '40px',
            border: '1px solid #000',
            zIndex: 50,
            height: '160px',
            width: '350px',
            overflowY: 'auto',
            backgroundColor: '#fff',
          }}
          onBlur={(e) => {
            e.stopPropagation();
            setKeywords('');
          }}
        >
          <ul className="nav flex-column">
            {matches.length ? (
              matches.map((i: Topic) => (
                <li key={i.id} className="nav-item clickable">
                  <span className="nav-link tcf-typeahead-option" onClick={(e) => onAddIssue(e, i)}>
                    {i.name}
                  </span>
                </li>
              ))
            ) : (
              <li>(No matches found)</li>
            )}
          </ul>
        </div>
      </div>
    </>
  );
};

export default IssuesSelect;
