import { Query, UsageReportQuery } from 'tcf-shared/models';

import { asyncAction } from './asyncAction';
import { library } from '../libraryApi';
import { NOOP, READ_SERVER } from './actionTypes';

export function readUsage(storeIdentifier: string, query: Query) {
  query.q = query.q || '*';
  query.skip = query.skip || 0;
  query.limit = query.limit || 25;
  query.filters = query.filters || {};

  return asyncAction({
    actionType: READ_SERVER,
    func: async () => (await library.usage.search(query)).data,
    storeIdentifier,
    requestPayload: { query },
  });
}

export function visitPage(id: string, url: string) {
  return asyncAction({
    actionType: NOOP,
    func: async () => (await library.usage.post({ type: 'visit', product: 'library', id, url })).data,
  });
}

export function leavePage(id: string, url: string) {
  return asyncAction({
    actionType: NOOP,
    func: async () => (await library.usage.post({ type: 'leave', product: 'library', id, url })).data,
  });
}

export const getUsageReport = (storeIdentifier: string, query: UsageReportQuery) => {
  return asyncAction({
    actionType: READ_SERVER,
    func: async () => (await library.usage.getReport(query)).data,
    storeIdentifier,
    requestPayload: { query },
  });
};
