import React from 'react';
import Loader from 'react-loader';
import { Nav, NavItem, NavLink, TabContent, TabPane, Table } from 'reactstrap';

import { getAuthUser } from '../../reducers/authReducer';
import { NotFoundError } from '../../components';
import { useAppSelector, useLocationHistoryActiveTab } from '../../utils/hooks';
import AsyncPage from '../AsyncPage/AsyncPage';
import ErrorComponent from '../AsyncPage/ErrorComponent';
import SavedSearchList from '../SavedSearch/components/SavedSearchList';
import SelectAlertPreferences from './components/SelectAlertPreferences';

const STANDARD_ALERTS_TAB = 'Standard Alerts';
const ADVANCED_ALERTS_TAB = 'Advanced Alerts';

const SAVED_SEARCHES_STORE_ID = 'viewAccountSavedSearchesStoreId';

const ViewAccount = () => {
  const [activeTab, setActiveTab] = useLocationHistoryActiveTab(STANDARD_ALERTS_TAB);
  const authUser = useAppSelector((state) => getAuthUser(state));
  const error = false;
  const isFetching = false;

  if (!authUser) return <NotFoundError />;

  const authUserId = authUser.id;

  if (isFetching) return <Loader loaded={false} />;
  if (error) return <ErrorComponent error={error} />;

  return (
    <AsyncPage title={'Email Alerts: ' + authUser.email}>
      <Table borderless responsive className="mb-0">
        <tbody>
          <tr>
            <td className="px-0">
              <h2>Email Alerts</h2>
            </td>
            <td className="px-0 text-right">Email: {authUser.email || ''}</td>
          </tr>
        </tbody>
      </Table>
      <Nav tabs>
        <NavItem>
          <NavLink
            href="#"
            className={activeTab === STANDARD_ALERTS_TAB ? 'active' : ''}
            onClick={() => setActiveTab(STANDARD_ALERTS_TAB)}
          >
            {STANDARD_ALERTS_TAB}
          </NavLink>
        </NavItem>
        <NavItem>
          <NavLink
            href="#"
            className={activeTab === ADVANCED_ALERTS_TAB ? 'active' : ''}
            onClick={() => setActiveTab(ADVANCED_ALERTS_TAB)}
          >
            {ADVANCED_ALERTS_TAB}
          </NavLink>
        </NavItem>
      </Nav>
      <TabContent activeTab={activeTab}>
        <TabPane tabId={STANDARD_ALERTS_TAB}>
          <SelectAlertPreferences />
        </TabPane>
        <TabPane tabId={ADVANCED_ALERTS_TAB}>
          <SavedSearchList storeId={SAVED_SEARCHES_STORE_ID} userId={authUserId!} />
        </TabPane>
      </TabContent>
    </AsyncPage>
  );
};

export default ViewAccount;
