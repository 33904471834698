import React, { useLayoutEffect, useRef, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { Collapse, Container, Nav, Navbar, NavbarToggler, NavItem } from 'reactstrap';

import { getAuthUser } from '../../reducers/authReducer';
import { paths } from '../../paths';
import { useAppSelector } from '../../utils/hooks';
import { useWindowSizeTracker } from '../../utils/hooks/useWindowSizeTracker';
import AccountMenu from './AccountMenu';
import AdminMenu from './AdminMenu';
import HelpMenu from './HelpMenu';
import MainMenu from './MainMenu';
import NotificationBell from './NotificationBell';

interface HeaderProps {
  onHeightChange: (height: number) => void;
}

const Header = (props: HeaderProps) => {
  const { pathname } = useLocation();
  const [isOpen, setIsOpen] = useState(false);
  const headerRef = useRef<HTMLDivElement | null>(null);

  const { isWindowThisSizeOrSmaller, windowWidth } = useWindowSizeTracker();

  const { onHeightChange } = props;

  const authUser = useAppSelector((state) => getAuthUser(state));

  useLayoutEffect(() => {
    if (headerRef.current) {
      onHeightChange(headerRef.current.clientHeight);
    }
  }, [windowWidth, onHeightChange]);

  const closeMenu = () => setIsOpen(false);

  const isNarrowDocView = isWindowThisSizeOrSmaller('xs');

  return (
    <div style={{ position: 'fixed', top: 0, width: '100%', zIndex: 1000 }} ref={headerRef}>
      <Navbar color="light" expand={!isNarrowDocView} light>
        <Container style={{ margin: 0, padding: 0 }} fluid>
          <Link to={paths.HOME} className="navbar-brand" style={{ margin: 0, padding: 0 }} onClick={closeMenu}>
            {isNarrowDocView ? (
              <img
                style={{ height: '38px', width: '38px' }}
                src={`${process.env.PUBLIC_URL}/TCF-logo-dark.png`}
                alt="The Capitol Forum"
              />
            ) : (
              <img
                style={{ height: '38px', width: '268px' }}
                src={`${process.env.PUBLIC_URL}/logo-dark.svg`}
                alt="The Capitol Forum"
              />
            )}
          </Link>

          <div className="d-flex align-items-center justify-content-end flex-grow-1">
            <NavbarToggler onClick={() => setIsOpen(!isOpen)} />
            {isNarrowDocView && authUser && <NotificationBell />}
          </div>

          <Collapse isOpen={isOpen} navbar>
            <Nav navbar className="ml-auto">
              {!authUser && pathname !== paths.LOGIN && pathname !== paths.LOGIN_WITH_TOKEN && pathname !== paths.LOGOUT && (
                <NavItem>
                  <Link title="Go to login page" className="btn btn-secondary" to={paths.LOGIN} onClick={closeMenu}>
                    Login
                  </Link>
                </NavItem>
              )}
              <HelpMenu closeMenu={closeMenu} />
              <AdminMenu closeMenu={closeMenu} />
              <AccountMenu authUser={authUser} closeMenu={closeMenu} />
            </Nav>
          </Collapse>

          {!isNarrowDocView && authUser && <NotificationBell />}
        </Container>
      </Navbar>
      <MainMenu authUser={authUser} />
    </div>
  );
};

export default Header;
