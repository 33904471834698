import React, { useState } from 'react';
import { Button } from 'reactstrap';

import { AuthUser, DocumentSearch, Query } from 'tcf-shared/models';

import CategoryFilter from '../../../components/filters/CategoryFilter';
import DocTypeFilter from '../../../components/filters/DocTypeFilter';
import IssueFilter from '../../../components/filters/IssueFilter';
import RegionFilter from '../../../components/filters/RegionFilter';
import SaveSearchModal from './SaveSearchModal';
import SearchHintsModal from './SearchHintsModal';
import SectorFilter from '../../../components/filters/SectorFilter';

export interface DocumentsFilterProps {
  authUser: AuthUser;
  query?: Query;
  hideCategoriesFilter?: boolean;
  hideRegionsFilter?: boolean;
  hideDocTypesFilter?: boolean;
  hideIssuesFilter?: boolean;
  hideSaveButton?: boolean;
  onToggleShowFilter?: any;
  showFilterOnSm?: boolean;
  onResetQuery: any;
  onQueryChange: (query: Query, debounce?: boolean) => void;
  issuesMatchesStoreId: string;
  includeIssuesStoreId: string;
  excludeIssuesStoreId: string;
  savedSearchesStoreId?: string;
}

const DocumentsFilter = (props: DocumentsFilterProps) => {
  const {
    authUser,
    onQueryChange,
    onResetQuery,
    hideCategoriesFilter,
    hideRegionsFilter,
    hideDocTypesFilter,
    hideIssuesFilter,
    hideSaveButton,
    query,
    onToggleShowFilter,
    showFilterOnSm,
    issuesMatchesStoreId,
    includeIssuesStoreId,
    excludeIssuesStoreId,
    savedSearchesStoreId,
  } = props;

  const documentSearch: DocumentSearch = query?.search || {};
  const [showHintsModal, setShowHintsModal] = useState(false);
  const [showSaveModal, setShowSaveModal] = useState(false);

  const canSave =
    !hideSaveButton &&
    (documentSearch.searchText?.length ||
      // Subject to review: selecting a single category alone is not enough to enable saving a search,
      // otherwise, the Save button would be enabled when navigating to Antitrust, Corporate, or M&A pages
      // without the user actually specifying any criteria.  It'd also be entirely redundant with basic alert options.
      (documentSearch.categories?.length ?? 0) > 1 ||
      documentSearch.regions?.length ||
      documentSearch.sectors?.length ||
      documentSearch.documentTypes?.length ||
      documentSearch.includeIssues?.length ||
      documentSearch.excludeIssues?.length);

  const onChangeSearchText = (event: React.ChangeEvent<HTMLInputElement>) => {
    const searchText = event.target.value;
    const _search: DocumentSearch = { ...query?.search };
    if (searchText) {
      _search.searchText = searchText;
    } else {
      delete _search.searchText;
    }
    onQueryChange({ ...query, skip: 0, search: _search }, true);
  };

  const onChangeSearchField = (searchField: string, searchIds: string[]) => {
    // Handle changes to array of categories, regions, sectors, or documentTypes IDs criteria
    const _search: DocumentSearch = { ...query?.search };
    if (searchIds?.length) {
      // Set the array of IDs for the searchField
      _search[searchField] = searchIds;
    } else {
      // Or remove any existing value for the searchField; leaving an empty array is insufficient for determining
      // when to enable the "save search" feature.
      delete _search[searchField];
    }
    onQueryChange({ ...query, skip: 0, search: _search });
  };

  const toggleHintsModal = () => setShowHintsModal(!showHintsModal);
  const toggleSaveModal = () => setShowSaveModal(!showSaveModal);

  if (!authUser) return null;

  return (
    <>
      <div className={'d-flex flex-wrap mb-1'}>
        <h4>Search</h4>
        <div className="d-flex flex-wrap ml-auto">
          {showFilterOnSm && (
            <Button className={'d-md-none ml-2'} onClick={onToggleShowFilter}>
              Hide Search
            </Button>
          )}
          {!hideSaveButton && (
            <Button
              className={'ml-2'}
              title="Save search criteria and optionally create an alert"
              disabled={!canSave}
              onClick={toggleSaveModal}
            >
              Save
            </Button>
          )}
          <Button className={'ml-2'} onClick={toggleHintsModal} title="Show search hints...">
            Help
          </Button>
          <Button className={'ml-2'} onClick={onResetQuery} title="Reset filters to defaults">
            Reset
          </Button>
        </div>
      </div>
      <input
        type="text"
        value={documentSearch.searchText ?? ''}
        onChange={onChangeSearchText}
        placeholder="Enter search terms..."
        className="flex-fill form-control prop-field mb-3"
      />
      {!hideCategoriesFilter && (
        <CategoryFilter
          authUser={authUser}
          selectedCategories={documentSearch.categories}
          onChange={(searchIds: string[]) => onChangeSearchField('categories', searchIds)}
        />
      )}
      {!hideRegionsFilter && (
        <RegionFilter
          authUser={authUser}
          selectedRegions={documentSearch.regions}
          onChange={(searchIds: string[]) => onChangeSearchField('regions', searchIds)}
        />
      )}
      <SectorFilter
        authUser={authUser}
        selectedSectors={documentSearch.sectors}
        onChange={(searchIds: string[]) => onChangeSearchField('sectors', searchIds)}
      />
      {!hideDocTypesFilter && (
        <DocTypeFilter
          selectedDocTypes={documentSearch.documentTypes}
          onChange={(searchIds: string[]) => onChangeSearchField('documentTypes', searchIds)}
        />
      )}
      {!hideIssuesFilter && (
        <IssueFilter
          matchesStoreId={issuesMatchesStoreId}
          issuesStoreId={includeIssuesStoreId}
          selectedIssues={documentSearch.includeIssues}
          onChange={(searchIds: string[]) => onChangeSearchField('includeIssues', searchIds)}
        />
      )}
      {!hideIssuesFilter && (
        <IssueFilter
          matchesStoreId={issuesMatchesStoreId}
          issuesStoreId={excludeIssuesStoreId}
          selectedIssues={documentSearch.excludeIssues}
          excluded
          onChange={(searchIds: string[]) => onChangeSearchField('excludeIssues', searchIds)}
        />
      )}
      {showHintsModal && <SearchHintsModal showModal={showHintsModal} toggleModal={toggleHintsModal} />}
      {showSaveModal && savedSearchesStoreId && (
        <SaveSearchModal
          userId={authUser.id}
          search={query!.search!}
          storeId={savedSearchesStoreId}
          showModal={showSaveModal}
          toggleModal={toggleSaveModal}
        />
      )}
    </>
  );
};

export default DocumentsFilter;
