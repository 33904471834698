import { AlertPreferences, DeleteRequest, Patch, Query, User } from 'tcf-shared/models';

import {
  CREATE_USER_API_TOKEN,
  DELETE_SERVER,
  DESELECT_USERS,
  GET_USERS,
  READ_SERVER,
  SAVE_SERVER,
  SAVE_USERS,
  SELECT_USERS,
} from './actionTypes';
import { asyncAction } from './asyncAction';
import { library } from '../libraryApi';
import { toast } from './toastActions';

export function searchUsers(storeIdentifier: string, query: Query) {
  return asyncAction({
    actionType: READ_SERVER,
    func: async () => (await library.users.search(query)).data,
    storeIdentifier,
  });
}

export const getUsers = (query: Query) => {
  query.q = undefined;
  query.skip = query.skip || 0;
  query.limit = query.limit || 20;
  query.filters = query.filters || {};

  return asyncAction({
    actionType: GET_USERS,
    func: async () => (await library.users.search(query)).data,
    requestPayload: { query },
  });
};

export function getUser(storeIdentifier: string, id: string) {
  return asyncAction({
    actionType: READ_SERVER,
    func: async () => (await library.users.get(id)).data,
    storeIdentifier,
  });
}

export function getUserWithEntitlements(storeIdentifier: string, id: string) {
  return asyncAction({
    actionType: READ_SERVER,
    func: async () => (await library.users.getWithEntitlements(id)).data,
    storeIdentifier,
  });
}

export function getUserRelated(storeIdentifier: string, id: string) {
  return asyncAction({
    actionType: READ_SERVER,
    func: async () => (await library.users.getRelated(id)).data,
    storeIdentifier,
  });
}

export function saveUser(storeIdentifier: string, user: Partial<User>) {
  const func =
    user.meta && user.meta.createdAt
      ? async () => (await library.users.put(user.id!, user)).data
      : async () => (await library.users.post(user)).data;

  return asyncAction({
    actionType: SAVE_SERVER,
    func,
    storeIdentifier,
    onSucceeded: async (result, dispatch) =>
      dispatch(
        toast.showSucceeded({
          title: 'User saved.',
          message: 'User saved.',
        }),
      ),
    onFailed: async (result, dispatch) =>
      dispatch(
        toast.showFailed({
          title: 'Error.',
          message: 'The user was not saved correctly.',
        }),
      ),
  });
}

export function patchUsers(storeIdentifier: string, patches: Patch[]) {
  return asyncAction({
    actionType: SAVE_SERVER,
    func: async () => (await library.users.bulkPatch(patches)).data,
    storeIdentifier,
    onSucceeded: async (result, dispatch) =>
      dispatch(
        toast.showSucceeded({
          title: 'Users updated.',
          message: 'Users updated.',
        }),
      ),
    onFailed: async (result, dispatch) =>
      dispatch(
        toast.showFailed({
          title: 'Error.',
          message: 'One or more users were not saved correctly.',
        }),
      ),
  });
}

export function deleteUser(storeIdentifier: string, request: DeleteRequest) {
  return asyncAction({
    actionType: DELETE_SERVER,
    func: async () => (await library.users.delete(request)).data,
    storeIdentifier,
    onSucceeded: async (result, dispatch) =>
      dispatch(
        toast.showSucceeded({
          title: 'User deleted.',
          message: 'User deleted.',
        }),
      ),
    onFailed: async (result, dispatch) =>
      dispatch(
        toast.showFailed({
          title: 'Error.',
          message: 'Something went wrong and we were not able to delete the user.  Please reload the page and try again.',
        }),
      ),
  });
}

function getEmailsFromBulkString(bulk: string) {
  return bulk
    .split('\n')
    .filter((line) => !!line)
    .map((line) => line.trim());
}

export function bulkAddUsers(users: string, organizationId: string, sendWelcomeEmails: boolean) {
  const emails = getEmailsFromBulkString(users);
  const bulkData = { emails, organizationId, sendWelcomeEmails };
  return asyncAction({
    actionType: SAVE_USERS,
    func: async () => (await library.users.bulkAdd(bulkData)).data,
    onSucceeded: async (result, dispatch) => dispatch(toast.showSucceeded({ title: 'Users added.', message: 'Users added.' })),
    onFailed: async (result, dispatch) =>
      dispatch(
        toast.showFailed({
          title: 'Error.',
          message: 'One or more users was not saved correctly.',
        }),
      ),
  });
}

export function getSGUserSummary(storeIdentifier: string, id: string) {
  return asyncAction({
    actionType: READ_SERVER,
    func: async () => (await library.users.getSGUserSummary(id)).data,
    storeIdentifier,
  });
}

export function createApiToken(id: string) {
  return asyncAction({
    actionType: CREATE_USER_API_TOKEN,
    func: async () => (await library.users.createApiToken(id)).data,
  });
}

export function selectUsers(users: User[]) {
  return { type: SELECT_USERS, payload: users };
}

export function deselectUsers(users: User[]) {
  return { type: DESELECT_USERS, payload: users };
}

// This isn't technically a "save", per se, but it does update the user so we'll need to treat it like a save.
export function sendWelcomeEmail(storeIdentifier: string, id: string) {
  const func = async () => (await library.users.sendWelcomeEmail(id)).data;
  return asyncAction({
    actionType: SAVE_SERVER,
    func,
    storeIdentifier,
    onSucceeded: async (result, dispatch) =>
      dispatch(
        toast.showSucceeded({
          title: 'Success!',
          message: 'Welcome email successfully sent.',
        }),
      ),
    onFailed: async (result, dispatch) =>
      dispatch(
        toast.showFailed({
          title: 'Error',
          message: 'There was an issue sending welcome email.  ',
        }),
      ),
  });
}

export function getAlertPreferences(storeIdentifier: string, id: string) {
  return asyncAction({
    actionType: READ_SERVER,
    func: async () => (await library.users.getAlertPreferences(id)).data,
    storeIdentifier,
  });
}

export function setAlertPreferences(storeIdentifier: string, id: string, alertPreferences: AlertPreferences) {
  const func = async () => (await library.users.setAlertPreferences(id, alertPreferences)).data;
  return asyncAction({
    actionType: SAVE_SERVER,
    func,
    storeIdentifier,
    onFailed: async (result, dispatch) =>
      dispatch(
        toast.showFailed({
          title: 'Error',
          message: 'Preference not saved correctly.',
        }),
      ),
  });
}
