import React from 'react';
import { Button, Col, Row } from 'reactstrap';

import { Query } from 'tcf-shared/models';

import AsyncPage from '../../AsyncPage/AsyncPage';
import EditUserModal from '../EditUserModal';
import LicenseCount from './LicenseCount';
import SearchBox from './SearchBox';
import UserList from './UserList';
import { OrganizationSelfManagementState } from '../../../reducers/organizationSelfManagementReducer';
import { usePopupToggler } from '../../../utils/hooks';

interface OwnProps {
  licenseStoreId: string;
  searchState: OrganizationSelfManagementState;
  licenseState: any;
  searchText: string;
  query: Query;
  handlePageOrSortChange: (newQuery: Query) => void;
  handleSearchTextChanged: (newSearchText: string) => void;
}

const OrganizationSelfManagementPage = (props: OwnProps) => {
  const { licenseStoreId, searchState, licenseState, searchText, query, handlePageOrSortChange, handleSearchTextChanged } =
    props;

  const editUserModal = usePopupToggler<string>();

  const searchError = searchState?.searchError;
  const searchResults = searchState?.payload?.results;
  const searchResultsCount = searchState?.payload?.total;

  const licenseHasErrored = !!licenseState?.error;
  const licenseCount = licenseState?.payload?.licenseCount;
  const licensesAvailable = licenseState?.payload?.licensesAvailable;
  const licenseIsInitialized = licenseState?.error || licenseCount !== undefined;

  const isInitialized = searchState?.isInitialized && licenseIsInitialized;

  const handleAddNewUserClick = () => {
    editUserModal.open();
  };

  const handleEditUserClick = (userId?: string) => {
    if (userId) editUserModal.open(userId);
  };

  return (
    <AsyncPage title="Organization Self Administration" loading={!isInitialized} error={searchError}>
      <LicenseCount licenseCount={licenseCount} licensesAvailable={licensesAvailable} licenseHasErrored={licenseHasErrored} />
      <Row>
        <Col lg="6" className="mt-2">
          <SearchBox
            searchText={searchText}
            handleSearchTextChanged={handleSearchTextChanged}
            searchResultsCount={searchResultsCount}
          />
        </Col>
        <Col lg="6" className="mt-2">
          <div className="float-lg-right">
            {!licenseHasErrored && (
              <Button className="btn" color="primary" onClick={handleAddNewUserClick} disabled={licenseHasErrored}>
                Create new user
              </Button>
            )}
          </div>
        </Col>
      </Row>
      <div className="org-self-manage mt-3">
        <UserList
          searchResults={searchResults}
          searchResultsCount={searchResultsCount}
          query={query}
          licenseHasErrored={licenseHasErrored}
          handleEditUserClick={handleEditUserClick}
          handlePageOrSortChange={handlePageOrSortChange}
        />
      </div>
      {editUserModal.isOpen && (
        <EditUserModal userId={editUserModal.data} handleCloseModal={editUserModal.close} licenseStoreId={licenseStoreId} />
      )}
    </AsyncPage>
  );
};

export default OrganizationSelfManagementPage;
