const getRange = (start: number, end: number = 0, step: number = 1) => {
  const range = [];
  let current = start;
  while (current <= end) {
    range.push(current);
    current += step;
  }
  return range;
};

export class PageData {
  readonly current: number;
  readonly total: number;
  readonly range: number[];

  constructor(query: { skip: number; limit: number }, total?: number, small = false) {
    if (!query || !total || total === 0) {
      this.current = 1;
      this.total = 1;
      this.range = [];
    } else {
      this.current = query!.skip! / query!.limit! + 1;

      this.total = total! % query!.limit! === 0 ? total! / query!.limit! : Math.floor(total! / query!.limit!) + 1;

      // Default version has 11 pages between ellipses.  Small version has 5.  (e.g. 1 ... 4 5 6 7 8 ... 99)
      const betweenCount = small ? 5 : 11;
      const eitherSideCount = (betweenCount - 1) / 2;

      const minPage = Math.max(Math.min(this.current - eitherSideCount, this.total - (betweenCount - 1)), 1);
      this.range = getRange(minPage, Math.min(minPage + (betweenCount - 1), this.total));
    }
  }
}
