import React from 'react';
import { Input } from 'reactstrap';

interface OwnProps {
  searchText: string;
  handleSearchTextChanged: (newSearchText: string) => void;
  searchResultsCount?: number | null;
}

const SearchBox = (props: OwnProps) => {
  const { searchText, handleSearchTextChanged, searchResultsCount } = props;

  return (
    <>
      <Input
        autoComplete="off"
        type="search"
        name="searchText"
        id="searchText"
        placeholder="Search..."
        value={searchText}
        style={{ display: 'inline', width: 'auto' }}
        maxLength={255}
        onChange={(evt) => handleSearchTextChanged(evt.target.value)}
      />
      {searchResultsCount !== null && searchResultsCount !== undefined ? (
        <small style={{ marginLeft: '0.5rem' }}>{searchResultsCount.toLocaleString()} matches</small>
      ) : null}
    </>
  );
};

export default SearchBox;
