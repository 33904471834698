import { AxiosInstance } from 'axios';
import { AlertPreferences, ApiResponse, Patch } from 'tcf-shared/models';
import { CrudApiClient } from './CrudApiClient';

export class UsersApiClient extends CrudApiClient {
  constructor(readonly axios: AxiosInstance) {
    super(axios, '/users');
  }

  async getWithEntitlements(id: string): Promise<ApiResponse> {
    return this.axios.get<ApiResponse>(`${this.baseUrl}/${id}/entitlements`);
  }

  async bulkAdd(bulkData: { emails: string[]; organizationId: string }): Promise<ApiResponse> {
    return this.axios.post<ApiResponse>(`${this.baseUrl}/bulk/add`, bulkData);
  }

  async bulkPatch(patches: Patch[]): Promise<ApiResponse> {
    return this.axios.patch<ApiResponse>(`${this.baseUrl}`, patches);
  }

  async getSGUserSummary(id: string): Promise<ApiResponse> {
    return this.axios.get<ApiResponse>(`${this.baseUrl}/${id}/sg/summary`);
  }

  async createApiToken(id: string): Promise<ApiResponse> {
    return this.axios.post<ApiResponse>(`${this.baseUrl}/${id}/api-token`);
  }

  async sendWelcomeEmail(id: string): Promise<ApiResponse> {
    return this.axios.post<ApiResponse>(`${this.baseUrl}/${id}/send-welcome-email`);
  }

  async getAlertPreferences(id: string): Promise<ApiResponse> {
    return this.axios.get<ApiResponse>(`${this.baseUrl}/${id}/alert-preferences`);
  }

  async setAlertPreferences(id: string, alertPreferences: AlertPreferences): Promise<ApiResponse> {
    return this.axios.patch<ApiResponse>(`${this.baseUrl}/${id}/alert-preferences`, alertPreferences);
  }
}
