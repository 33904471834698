import React, { useEffect, useMemo } from 'react';
import moment from 'moment-timezone';
import { momentLocalizer, Views } from 'react-big-calendar';
import { useHistory, useLocation } from 'react-router-dom';

import { DEFAULT_EVENT_DURATION_MILLISECONDS, TimeType } from 'tcf-shared/models';

import { resetServerStore } from '../../../actions/serverStoreActions';
import { searchCalendarEvent } from '../../../actions/calendarEventActions';
import { useAppDispatch, useAppSelector, usePopupToggler, useSimpleKeyValueCookie } from '../../../utils/hooks';
import { Event } from './utils';
import EventModal from './EventModal';
import EventCalendar from './EventCalendar';
import AsyncPage from '../../AsyncPage/AsyncPage';

interface LocationState {
  view?: string;
  date?: Date;
}

const localizer = momentLocalizer(moment);

const STORE_ID = 'ViewCalendarEvent';
const PAGE_SIZE = 10000;

const View = () => {
  const dispatch = useAppDispatch();
  const location = useLocation<LocationState>();
  const history = useHistory<LocationState>();

  const popupToggler = usePopupToggler<Event>();
  const browserTimezone = moment.tz.guess();

  const { value: viewCookie, setCookieValue } = useSimpleKeyValueCookie('antitrust-agenda-calendar-view', Views.MONTH);

  const hasHistoryState = !!location?.state;
  const view = location?.state?.view ?? viewCookie ?? Views.MONTH;
  const date = location?.state?.date ?? new Date();
  const pathname = location?.pathname;

  const searchPayload = useAppSelector((state) => state?.serverStores?.[STORE_ID]);
  const rawEvents = searchPayload?.payload?.results;
  const error = searchPayload?.error;
  const isFetching = searchPayload?.isFetching;

  const events = useMemo(() => {
    const returnEvents: Event[] = [];
    for (const rawEvent of rawEvents ?? []) {
      for (const dateTime of rawEvent.datesTimes ?? []) {
        if (dateTime.startDateTime) {
          returnEvents.push({
            start: dateTime.startDateTime,
            timeType: dateTime.type ?? TimeType.NORMAL,
            allDay: (dateTime.type ?? TimeType.NORMAL) !== TimeType.NORMAL,
            end: new Date(dateTime.startDateTime.getTime() + DEFAULT_EVENT_DURATION_MILLISECONDS),
            title: rawEvent.title,
            description: rawEvent.description,
          });
        }
      }
    }
    return returnEvents;
  }, [rawEvents]);

  useEffect(() => {
    return () => {
      dispatch(resetServerStore(STORE_ID));
    };
  }, [dispatch]);

  useEffect(() => {
    if (!hasHistoryState) {
      history.replace(pathname, { view, date });
    }
  }, [history, hasHistoryState, pathname, view, date]);

  const dateFullYear = date.getFullYear();
  const dateMonth = date.getMonth();
  useEffect(() => {
    const fromDate = new Date(dateFullYear, dateMonth - 1, 1);
    const toDate = new Date(dateFullYear, dateMonth + 2, 1);
    dispatch(
      searchCalendarEvent(STORE_ID, {
        q: '*',
        limit: PAGE_SIZE,
        skip: 0,
        filters: {
          lt: { keyField: 'earliestDateTime', keyValue: toDate.toISOString() },
          gte: { keyField: 'latestDateTime', keyValue: fromDate.toISOString() },
        },
      }),
    );
  }, [dispatch, dateFullYear, dateMonth]);

  const handleDateChange = (newDate: Date) => {
    history.push(pathname, { view, date: newDate });
  };

  const handleViewChange = (newView: string, newDate?: Date) => {
    setCookieValue(newView);
    history.push(pathname, { view: newView, date: newDate ?? date });
  };

  return (
    <AsyncPage title="View Antitrust Calendar" loading={isFetching} error={error}>
      <div style={{ height: '800px', paddingBottom: '3em' }}>
        <EventCalendar
          events={events}
          browserTimezone={browserTimezone}
          view={view}
          date={date}
          openPopup={popupToggler.open}
          localizer={localizer}
          onDateChange={handleDateChange}
          onViewChange={handleViewChange}
        />
        {popupToggler.isOpen && popupToggler.data && (
          <EventModal event={popupToggler.data} closeModal={popupToggler.close} browserTimezone={browserTimezone} />
        )}
      </div>
    </AsyncPage>
  );
};

export default View;
