export const arraysAreEquivalent = <T extends string | number | boolean>(arr1?: T[], arr2?: T[]) => {
  // Pass in two arrays, will tell you if they contain the same values.  Works best with intrinsic,
  // immutable values such as strings or numbers -- comparisons are shallow, so passing in anything
  // else could cause smoke and/or fire.  Ignores duplicates.  Ignores order.  If either array is
  // falsy, returns false.
  if (!arr1 || !arr2) return false;

  const set1 = new Set(arr1);
  const set2 = new Set(arr2);

  if (set1.size !== set2.size) return false;

  return Array.from(set1).every((item) => set2.has(item));
};

export const arrayIsSubset = <T extends string | number | boolean>(subset?: T[], superset?: T[]) => {
  // Pass in two arrays, will tell you if the first is fully contained by the second.  Works best
  // with intrinsic, immutable values such as strings or numbers -- comparisons are shallow, so
  // passing in anything else could cause smoke and/or fire.  Ignores duplicates.  Ignores order.
  // If the superset array is falsy, returns false.  Falsy subset arrays are treated as empty
  // arrays meaning the function will return true.
  if (!superset) return false;
  return (subset || []).every((item) => superset.includes(item));
};

export const addToArrayIfMissing = <T extends string | number | boolean>(arr?: T[], val?: T) => {
  // Adding a value to an array is trivial.  Checking to see if it's there first is annoying
  // boilerplate.  Let's abstract.  Always returns an array.  Returns a new array if val is added
  // or arr is falsy.  Returns the original array, untouched, otherwise.
  const a = arr || [];
  if (val === undefined || val === null || a.includes(val)) return a;
  return [...a, val];
};

export const removeFromArrayIfPresent = <T extends string | number | boolean>(arr?: T[], val?: T) => {
  // Removes a value from an array, abstracting away all the nonsense around whether or not the
  // array actually exists.  Always returns an array.  Returns a new array if val is removed
  // or arr is falsy.  Returns the original array, untouched, otherwise.
  const a = arr || [];
  if (val === undefined || val === null || !a.includes(val)) return a;
  return a.filter((i) => i !== val);
};

export const dedupeArrayByKey: any = (array: { [key: string]: string }[], key: string = 'id') => {
  return Array.from(new Map(array.map((i) => [i[key], i])).values());
};
