import { DirectoryEvidence, Query } from 'tcf-shared/models';

import { READ_SERVER, SAVE_SERVER_UPDATE_LIST, DELETE_SERVER_UPDATE_LIST, GET_DOWNLOAD_TOKEN } from './actionTypes';
import { library } from '../libraryApi';
import { asyncAction } from './asyncAction';
import { toast } from './toastActions';
import { AppState } from '../reducers';

export function getDirectoryEvidence(storeIdentifier: string, id: string) {
  return asyncAction({
    actionType: READ_SERVER,
    storeIdentifier,
    func: async () => (await library.directoryEvidence.get(id)).data,
  });
}

export function searchDirectoryEvidence(storeIdentifier: string, query: Query) {
  return asyncAction({
    actionType: READ_SERVER,
    storeIdentifier,
    func: async () => (await library.directoryEvidence.search(query)).data,
  });
}

export function createDirectoryEvidence(storeIdentifier: string, directoryEvidence: Partial<DirectoryEvidence>, file?: File) {
  return asyncAction({
    actionType: SAVE_SERVER_UPDATE_LIST,
    storeIdentifier,
    func: async () => (await library.directoryEvidence.postWithFile(directoryEvidence, file)).data,
    onSucceeded: async (result, dispatch) =>
      dispatch(
        toast.showSucceeded({
          title: 'Evidence created',
          message: 'Evidence created.',
        }),
      ),
    onFailed: async (result, dispatch) =>
      dispatch(
        toast.showFailed({
          title: 'Error',
          message:
            'Failed to create evidence.  Refresh the page and try again.  If the issue persists, please contact support.',
        }),
      ),
  });
}

export function updateDirectoryEvidence(
  storeIdentifier: string,
  directoryEvidenceId: string,
  directoryEvidence: Partial<DirectoryEvidence>,
) {
  return asyncAction({
    actionType: SAVE_SERVER_UPDATE_LIST,
    storeIdentifier,
    func: async () => (await library.directoryEvidence.put(directoryEvidenceId, directoryEvidence)).data,
    onSucceeded: async (result, dispatch) =>
      dispatch(
        toast.showSucceeded({
          title: 'Evidence updated',
          message: 'Evidence updated.',
        }),
      ),
    onFailed: async (result, dispatch) =>
      dispatch(
        toast.showFailed({
          title: 'Error',
          message:
            'Evidence was not updated correctly.  Refresh the page and try ' +
            'again.  If the issue persists, please contact support.',
        }),
      ),
  });
}

export function deleteDirectoryEvidence(storeIdentifier: string, id: string, updatedAt: Date | string) {
  return asyncAction({
    actionType: DELETE_SERVER_UPDATE_LIST,
    storeIdentifier,
    func: async () => (await library.directoryEvidence.delete({ id, updatedAt })).data,
    onSucceeded: async (result, dispatch) =>
      dispatch(
        toast.showSucceeded({
          title: 'Evidence deleted.',
          message: 'Evidence deleted.',
        }),
      ),
    onFailed: async (result, dispatch) =>
      dispatch(
        toast.showFailed({
          title: 'Error.',
          message:
            'Unable to delete evidence.  Refresh the page and try again.  If the issue persists, please contact support.',
        }),
      ),
    deleteId: id,
  });
}

export function getDirectoryEvidenceFileDownloadToken(id: string) {
  return asyncAction<AppState>({
    actionType: GET_DOWNLOAD_TOKEN,
    requestPayload: { id },
    func: async () => (await library.directoryEvidence.getDownloadToken(id)).data,
  });
}
