import React, { useEffect } from 'react';
import { FaBell } from 'react-icons/fa';
import { Link } from 'react-router-dom';
import moment from 'moment';

import { getNotificationsDate } from '../../actions/userActions';
import { NOTIFICATIONS_DATE_STORE_ID, NOTIFICATIONS_LAST_UPDATED, NotificationDateStore } from './Notifications';
import { paths } from '../../paths';
import { useAppDispatch, useAppSelector } from '../../utils/hooks';

const NEW_NOTIFICATION_DATE_CUTOFF_DAYS = 90;
const MINIMUM_CHECK_FREQUENCY_MINUTES = 1;

const NotificationBell = () => {
  const dispatch = useAppDispatch();

  const store = useAppSelector<NotificationDateStore>((s) => s.serverStores?.[NOTIFICATIONS_DATE_STORE_ID]?.payload);

  const areNewNotifications = NOTIFICATIONS_LAST_UPDATED.isAfter(moment().subtract(NEW_NOTIFICATION_DATE_CUTOFF_DAYS, 'days'));
  const mostRecentlyViewedNotificationPageDate = store?.mostRecentlyViewedNotificationPageDate;
  const checkedAt = store?.checkedAt;

  useEffect(() => {
    // There are new notifications and (we've not yet checked the server or we haven't checked
    // the server in the past MINIMUM_CHECK_FREQUENCY_MINUTES).
    if (
      areNewNotifications &&
      (!checkedAt || moment(checkedAt) < moment().subtract(MINIMUM_CHECK_FREQUENCY_MINUTES, 'minutes'))
    ) {
      dispatch(getNotificationsDate(NOTIFICATIONS_DATE_STORE_ID));
    }
  }, [dispatch, areNewNotifications, checkedAt]);

  // There are new notifications and we've checked with the server and (we've never viewed
  // notifications or the last notification we viewed is older than the most recent).
  const hasNotifications =
    areNewNotifications &&
    checkedAt &&
    (!mostRecentlyViewedNotificationPageDate || moment(mostRecentlyViewedNotificationPageDate) < NOTIFICATIONS_LAST_UPDATED);

  return (
    <Link to={paths.NOTIFICATIONS} className="pl-2" style={{ position: 'relative' }} aria-label="Link to site notifications">
      <FaBell aria-hidden="true" size={26} color="#11195b" />
      {hasNotifications ? (
        <span
          aria-hidden="true"
          style={{
            position: 'absolute',
            top: '6px',
            right: '6px',
            backgroundColor: '#dc3545',
            color: 'white',
            borderRadius: '50%',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            fontSize: '12px',
            lineHeight: '15px',
            height: '15px',
            width: '15px',
            minHeight: '15px',
            minWidth: '15px',
            boxSizing: 'border-box',
            fontWeight: 'bolder',
            transform: 'translate(50%, -50%)',
            fontFamily: 'sans-serif',
          }}
        >
          !
        </span>
      ) : null}
    </Link>
  );
};

export default NotificationBell;
