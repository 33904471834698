import { Action } from '../actions/Action';
import { Query, ApiError, Document } from 'tcf-shared/models';

import { LOGOUT, SEARCH, RESET_SEARCH } from '../actions/actionTypes';

export const defaultLimit = 20;

export interface SearchPayload {
  query: { q?: string };
  total: number;
  results: Document[];
}

export interface SearchState {
  query?: Query;
  isFetching: boolean;
  total: number;
  results: Document[];
  error: string;
}

interface SearchAction extends Action {
  query?: { q?: string };
}

const initialState: SearchState = {
  isFetching: false,
  total: 0,
  results: [],
  error: '',
};

export const searchReducer = (state = initialState, action: SearchAction): SearchState => {
  switch (action.type) {
    case RESET_SEARCH.REQUESTED: {
      return {
        ...initialState,
      };
    }

    case SEARCH.REQUESTED: {
      const searchPayload = action.payload;
      return {
        ...initialState,
        isFetching: true,
        query: searchPayload.query,
      };
    }

    case SEARCH.SUCCEEDED: {
      const searchPayload = action.payload as SearchPayload;
      return {
        isFetching: false,
        total: searchPayload.total,
        results: searchPayload.results,
        query: searchPayload.query,
        error: '',
      };
    }

    case SEARCH.FAILED: {
      const error = action.payload as ApiError;
      return {
        query: state.query,
        isFetching: false,
        total: 0,
        results: [],
        error: `${error ? `${error.name}: ${error.message}` : 'no message'}`,
      };
    }

    case LOGOUT.SUCCEEDED:
    case LOGOUT.FAILED: {
      return initialState;
    }

    default: {
      return state;
    }
  }
};
