import { SavedSearch, Query } from 'tcf-shared/models';

import { DELETE_SERVER, READ_SERVER, SAVE_SERVER } from './actionTypes';
import { library } from '../libraryApi';
import { asyncAction } from './asyncAction';
import { toast } from './toastActions';
import { AsyncActionType } from './getAsyncActionTypes';

export function getSavedSearch(storeIdentifier: string, id: string) {
  return asyncAction({
    actionType: READ_SERVER,
    func: async () => (await library.savedSearches.get(id)).data,
    storeIdentifier,
  });
}

export function searchSavedSearches(storeIdentifier: string, query: Query) {
  return asyncAction({
    actionType: READ_SERVER,
    storeIdentifier,
    func: async () => (await library.savedSearches.search(query)).data,
  });
}

export function saveSavedSearch(
  storeIdentifier: string,
  savedSearch: Partial<SavedSearch>,
  actionType: AsyncActionType = SAVE_SERVER,
) {
  const func = savedSearch?.meta?.createdAt
    ? async () => (await library.savedSearches.put(savedSearch.id!, savedSearch)).data
    : async () => (await library.savedSearches.post(savedSearch)).data;

  return asyncAction({
    actionType,
    func,
    storeIdentifier,
    // onSucceeded: async (result, dispatch) =>
    //   dispatch(
    //     toast.showSucceeded({
    //       title: 'Search saved',
    //       message: 'Search saved.',
    //     }),
    //   ),
    onFailed: async (result, dispatch) =>
      dispatch(
        toast.showFailed({
          title: 'Error',
          message: 'The search was not saved correctly.  Please try again.  If the issue persists, please contact support.',
        }),
      ),
  });
}

export function deleteSavedSearch(
  storeIdentifier: string,
  id: string,
  updatedAt: Date | string,
  actionType: AsyncActionType = DELETE_SERVER,
) {
  const _updatedAt = updatedAt instanceof Date ? updatedAt.toISOString() : updatedAt;
  return asyncAction({
    actionType,
    storeIdentifier,
    func: async () => (await library.savedSearches.delete({ id, updatedAt: _updatedAt })).data,
    // onSucceeded: async (result, dispatch) =>
    //   dispatch(
    //     toast.showSucceeded({
    //       title: 'Search deleted.',
    //       message: 'Search deleted.',
    //     }),
    //   ),
    onFailed: async (result, dispatch) =>
      dispatch(
        toast.showFailed({
          title: 'Error.',
          message: 'Unable to delete search.  Refresh the page and try again.  If the issue persists, please contact support.',
        }),
      ),
    deleteId: id,
  });
}
