import React from 'react';

import { NotFoundError } from '../../components/NotFoundError';

export interface OwnProps {
  error?: string;
  children?: React.ReactNode;
}

export default (props: OwnProps) => {
  const { error, children } = props;

  if (error) {
    switch (error.toLowerCase()) {
      case 'not found':
        return <NotFoundError />;
      case 'network error':
        return <h3>Network service not responding. Please try again later.</h3>;
      case 'missing permission':
        return (
          <div>
            <h3>Please login with credentials to view this page.</h3>
            <p>If you believe this is an error, please contact editorial@thecapitolforum.com for help.</p>
          </div>
        );
      default:
        return <h3>{error}</h3>;
    }
  }
  return children ? <>{children}</> : <></>;
};
