// These functions change the timezone of the supplied date to or from the supplied timezone depending on which
// function is called.  Examples:
//
//    // In both cases America/New_York is the local timezone and America/Denver is the user specified timezone.
//    changeFromTimezone would change 1/1/2001 8:30 AM MST to 1/1/2001 8:30 AM EST.
//    changeToTimezone would change 1/1/2001 8:30 AM EST to 1/1/2001 8:30 AM MST.
//
// The original use case is that Dates returned by DateTimePickers are always in the local timezone.  We need to
// swap out local timezone for the timezone the user selected so everything is saved in the database correctly.  This
// is surprisingly difficult to do in Javascript since Dates don't store timezone information.  To work around this,
// we take advantage of international date formatting to display the date in the destination timezone and apply a
// regex pattern to strip out the date/time bits from that freshly formatted string.  True hacking/witchcraft.
//
// We also need to revert the timezone change when we're feeding the Date back into the DatePicker.
//
// Changing a Date's timezone is tricky business without the help of an external library.  I didn't want to bring
// in yet another library just to adjust a timezone, so this function was born.  The idea was "borrowed" from another
// project that doesn't compile under ES6 (https://github.com/medikoo/date-from-timezone).
//
// Terrible business, Javascript Dates.

const getTimezoneAdjustedDate = (date: Date, timezone: string) => {
  if (!Intl?.DateTimeFormat) {
    // Older browsers (IE pre-11) do not support this feature.
    throw new Error('Browser does not support international date formatting (Intl.DateTimeFormat).');
  }

  const options: Intl.DateTimeFormatOptions = {
    timeZone: timezone,
    hour12: false,
    year: 'numeric',
    month: '2-digit',
    day: '2-digit',
    hour: '2-digit',
    minute: '2-digit',
    second: '2-digit',
  };

  const match = Intl.DateTimeFormat('en', options)
    .format(date)
    .match(/^(\d{2})\/(\d{2})\/(\d{1,4}), (\d{2}):(\d{2}):(\d{2})$/u);

  if (!match) {
    throw new Error('Formatted date does not match expected pattern -- unable to parse.');
  }

  return new Date(
    Number(match[3]),
    Number(match[1]) - 1,
    Number(match[2]),
    Number(match[4]),
    Number(match[5]),
    Number(match[6]),
    date.getMilliseconds(),
  );
};

// Changes the timezone of the supplied date from the supplied timezone to local time.
export const changeFromTimezone = (date: Date, timezone: string) => {
  return getTimezoneAdjustedDate(date, timezone);
};

// Changes the timezone of the supplied date from local time to the supplied timezone.
export const changeToTimezone = (date: Date, timezone: string) => {
  const timezoneAdjustedDate = getTimezoneAdjustedDate(date, timezone);
  const diff = date.getTime() - timezoneAdjustedDate.getTime();
  return new Date(date.getTime() + diff);
};
