import React from 'react';
import BootstrapTable from 'react-bootstrap-table-next';
import { Button } from 'reactstrap';

import { Query, User } from 'tcf-shared/models';

import paginationFactory from 'react-bootstrap-table2-paginator';
import { Icon } from '../../../components';

interface TableState {
  page: number;
  sizePerPage: number;
  sortField: string;
  sortOrder: any;
}

interface OwnProps {
  searchResults?: User[];
  searchResultsCount: number;
  query: Query;
  licenseHasErrored: boolean;
  handleEditUserClick: (userId: string) => void;
  handlePageOrSortChange: (newQuery: Query) => void;
}

const check = <Icon name="check" size={14} className="text-success" style={{ margin: 0 }} />;

const sizePerPageList = [
  { text: '10', value: 10 },
  { text: '25', value: 25 },
  { text: '50', value: 50 },
  { text: '100', value: 100 },
  { text: '250', value: 250 },
];

const missingValues = { email: '-', firstName: '-', lastName: '-', disabled: 0, canManageOrganization: 0 };

const getPagingParamsForQuery = (newState: TableState): Query => {
  const { page, sizePerPage, sortField, sortOrder } = newState;
  const query = {
    skip: sizePerPage * (page - 1),
    limit: sizePerPage,
    sort: [{ [sortField]: { order: sortOrder, missing: missingValues[sortField] } }],
  };
  if (sortField !== 'email') query.sort.push({ email: { order: 'asc', missing: missingValues.email } });
  return query;
};

const formatBoolean = (cell: boolean) => (cell ? check : null);
const formatReverseBoolean = (cell: boolean) => (cell ? null : check);
const formatEditButton = () => (
  <Button size={'sm'} color={'link'} title={'Edit user'} className={'tableButton'}>
    EDIT
  </Button>
);

const columns = [
  {
    dataField: 'email',
    text: 'Email',
    sort: true,
  },
  {
    dataField: 'firstName',
    text: 'First Name',
    sort: true,
  },
  {
    dataField: 'lastName',
    text: 'Last Name',
    sort: true,
  },
  {
    dataField: 'disabled',
    text: 'Enabled',
    sort: true,
    formatter: formatReverseBoolean,
  },
  {
    dataField: 'canManageOrganization',
    text: 'Admin',
    sort: true,
    formatter: formatBoolean,
  },
  {
    isDummyField: true,
    dataField: 'dummyField1',
    text: '',
    align: 'right',
    headerAlign: 'right',
    formatter: formatEditButton,
  },
];

const UserList = (props: OwnProps) => {
  const { searchResults, searchResultsCount, query, licenseHasErrored, handleEditUserClick, handlePageOrSortChange } = props;

  if (!searchResults || !searchResultsCount) return <>No matches</>;

  const skip = query.skip ?? 0;
  const limit = query.limit ?? 50;
  const currentPage = Math.floor(skip / limit) + 1;

  const onTableChange = (type: unknown, newState: any) => {
    handlePageOrSortChange(getPagingParamsForQuery(newState));
  };

  const rowEvents = {
    onClick: (event: any, row: any) => {
      if (row?.id && !licenseHasErrored) handleEditUserClick(row.id);
    },
  };

  const columnsFinal = licenseHasErrored ? columns.slice(0, -1) : columns;

  return (
    <BootstrapTable
      keyField="id"
      data={searchResults}
      columns={columnsFinal}
      remote={true}
      wrapperClasses="table-responsive"
      rowClasses="text-nowrap"
      condensed
      bootstrap4
      striped
      bordered={false}
      classes="remoteTable"
      defaultSorted={[{ dataField: 'email', order: 'asc' }]}
      onTableChange={onTableChange}
      rowEvents={rowEvents}
      pagination={paginationFactory({
        page: currentPage,
        sizePerPage: limit,
        totalSize: searchResultsCount,
        hidePageListOnlyOnePage: true,
        sizePerPageList,
      })}
    />
  );
};

export default UserList;
