import React from 'react';

import { AuthUser, allRegionsTableFilterOptions } from 'tcf-shared/models';
import FacetFilter from './FacetFilter';

interface RegionFilterProps {
  authUser?: AuthUser;
  onChange?: any;
  selectedRegions?: string[];
}

const RegionFilter = (props: RegionFilterProps) => {
  const { authUser, onChange } = props;
  const selectedRegions = props.selectedRegions || [];
  if (!authUser) {
    return null;
  }

  // Disable nonapplicable options based on user permissions
  const options = allRegionsTableFilterOptions.map((o) => ({
    ...o,
    disabled: !!(authUser.regionIds?.length && !authUser.regionIds.includes(o.value)),
    selected: selectedRegions.includes(o.value),
  }));

  const optionsChanged = (event: any) => {
    event.stopPropagation();
    const { value, checked } = event.target;
    let newOptions;
    if (checked) {
      newOptions = [...selectedRegions];
      newOptions.push(value);
    } else {
      newOptions = selectedRegions.filter((x) => x !== value);
    }
    onChange(newOptions);
  };

  return <FacetFilter title="Regions" options={options} onChange={optionsChanged} />;
};

export default RegionFilter;
