import React from 'react';
import { Row, Col, FormGroup, Input, Label } from 'reactstrap';

interface CurrentFilterProps {
  onChange?: any;
  current?: boolean;
}

const CurrentFilter = (props: CurrentFilterProps) => {
  const current = props.current ? true : false;

  const handleChange = () => props.onChange(!current);

  return (
    <Row className={'mb-3'}>
      <Col lg={6}>
        <FormGroup check>
          <Label check>
            <Input type="radio" name="current" value="1" checked={current} onChange={handleChange} />
            &nbsp;Current issues
          </Label>
        </FormGroup>
      </Col>
      <Col lg={6}>
        <FormGroup check>
          <Label check>
            <Input type="radio" name="current" value="0" checked={!current} onChange={handleChange} />
            &nbsp;All issues
          </Label>
        </FormGroup>
      </Col>
    </Row>
  );
};

export default CurrentFilter;
