import { DeleteRequest, Document, DocumentType, PublishRequest, Query } from 'tcf-shared/models';

import { library } from '../libraryApi';
import {
  GET_DOCUMENT,
  SAVE_DOCUMENT,
  PUBLISH_DOCUMENT,
  DELETE_DOCUMENT,
  UPLOAD_FILE,
  GET_DOWNLOAD_TOKEN,
  ADD_ASSOCIATED_DOCUMENT,
  RESET_DOCUMENT_STORE,
  SET_DOCUMENT,
  READ_SERVER,
} from './actionTypes';
import { asyncAction } from './asyncAction';
import { toast } from './toastActions';
import { AppState } from '../reducers';

export function getDocument(id: string) {
  return asyncAction({
    actionType: GET_DOCUMENT,
    func: async () => (await library.docs.get(id)).data,
  });
}

export function getDocumentRelated(storeIdentifier: string, id: string) {
  return asyncAction({
    actionType: READ_SERVER,
    func: async () => (await library.docs.getRelated(id)).data,
    storeIdentifier,
  });
}

export function getSGDocumentSummary(storeIdentifier: string, id: string) {
  return asyncAction({
    actionType: READ_SERVER,
    func: async () => (await library.docs.getSGDocumentSummary(id)).data,
    storeIdentifier,
  });
}

export function saveDocument(document: Partial<Document>) {
  const func =
    document.meta && document.meta.createdAt
      ? async () => (await library.docs.put(document.id!, document)).data
      : async () => (await library.docs.post(document)).data;

  return asyncAction({
    actionType: SAVE_DOCUMENT,
    func,
    onSucceeded: async (result, dispatch) =>
      dispatch(
        toast.showSucceeded({
          title: 'Document saved.',
          message: 'Document saved.',
        }),
      ),
    onFailed: async (result, dispatch) =>
      dispatch(
        toast.showFailed({
          title: 'Error.',
          message: 'Something went wrong and we were not able to save the document. Please reload the page and try again.',
        }),
      ),
  });
}

export function publishDocument(request: PublishRequest) {
  return asyncAction({
    actionType: PUBLISH_DOCUMENT,
    func: async () => (await library.docs.publish(request)).data,
    onSucceeded: async (result, dispatch) =>
      dispatch(
        toast.showSucceeded({
          title: 'Document published.',
          message: 'Document published.',
        }),
      ),
    onFailed: async (result, dispatch) =>
      dispatch(
        toast.showFailed({
          title: 'Error.',
          message: 'Something went wrong and we were not able to publish the document. Please reload the page and try again.',
        }),
      ),
  });
}

export function deleteDocument(request: DeleteRequest) {
  return asyncAction({
    actionType: DELETE_DOCUMENT,
    func: async () => (await library.docs.delete(request)).data,
    onSucceeded: async (result, dispatch) =>
      dispatch(
        toast.showSucceeded({
          title: 'Document deleted.',
          message: 'Document deleted.',
        }),
      ),
    onFailed: async (result, dispatch) =>
      dispatch(
        toast.showFailed({
          title: 'Error.',
          message: 'Something went wrong and we were not able to delete the document. Please reload the page and try again.',
        }),
      ),
  });
}

export function getDownloadToken(id: string) {
  return asyncAction<AppState>({
    actionType: GET_DOWNLOAD_TOKEN,
    requestPayload: { id },
    func: async () => (await library.docs.getDownloadToken(id)).data,
  });
}

export function uploadFile(document: Partial<Document>, file: File, mobile: boolean = false) {
  return asyncAction({
    actionType: UPLOAD_FILE,
    func: async () => (await library.docs.upload(document.id!, document.meta!.updatedAt, file, mobile)).data,
  });
}

export function readDocuments(storeIdentifier: string, query: Query) {
  query.q = query.q || '*';
  query.skip = query.skip || 0;
  query.limit = query.limit || 10;
  query.filters = query.filters || {};

  return asyncAction({
    actionType: READ_SERVER,
    func: async () => (await library.docs.search(query)).data,
    storeIdentifier,
    requestPayload: { query },
  });
}

export function addAssociatedDocument(
  parentDocumentId: string,
  associatedDocumentTitle: string,
  associatedDocumentType: DocumentType,
  associatedDocumentFile: File,
) {
  return asyncAction({
    actionType: ADD_ASSOCIATED_DOCUMENT,
    func: async () =>
      (
        await library.docs.addAssociatedDocument(
          parentDocumentId,
          associatedDocumentTitle,
          associatedDocumentType,
          associatedDocumentFile,
        )
      ).data,
  });
}

export const resetDocumentStore = () => ({ type: RESET_DOCUMENT_STORE });

export const setDocument = (document: Partial<Document>) => ({
  // This allows a page to directly set the document.  It's a special use case to reduce screen flashing on
  // bulk associated document upload.
  type: SET_DOCUMENT,
  payload: document,
});
