interface ClientConfig {
  TCF_ENV: string;
  CLIENT_VERSION: string;
  EFFICY_HOST: string;
  DISABLE_AUTHENTICATION_IN_DEV?: boolean;
}

const config: ClientConfig = (window as any).serverConfig || {};

export default config;
