import React from 'react';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter, Form } from 'reactstrap';

// Copied from reactstrap because it doesn't export this.
interface ModalProps {
  isOpen?: boolean;
  toggle?: () => void;
  // Other model props are not currently used.
}

type ConfirmDialogProps = ModalProps & {
  onConfirm?: () => any;
  header?: string;
  body?: React.ReactElement<any> | string;
  confirmButtonText?: string;
  confirmButtonColor?: string;
  cancelButtonText?: string;
  hideCancelButton?: boolean;
};

export const ConfirmDialog = (props: ConfirmDialogProps) => {
  const { isOpen, toggle, onConfirm, header, body, confirmButtonText, confirmButtonColor, cancelButtonText, hideCancelButton } =
    props;
  const modalProps = { isOpen, toggle };

  return (
    <Modal {...modalProps}>
      <Form>
        <ModalHeader>{header || 'Confirm action'}</ModalHeader>
        <ModalBody>{body || <div>Click {confirmButtonText || 'OK'} to continue.</div>}</ModalBody>
        <ModalFooter>
          {!hideCancelButton && (
            <Button color="light" onClick={toggle}>
              {cancelButtonText || 'Cancel'}
            </Button>
          )}
          <Button color={confirmButtonColor || 'primary'} onClick={onConfirm}>
            {confirmButtonText || 'OK'}
          </Button>{' '}
        </ModalFooter>
      </Form>
    </Modal>
  );
};
