import React from 'react';
import { Button, Col, Form, Row } from 'reactstrap';
import { Form as RFForm } from 'react-final-form';

import * as Inputs from '../../../components/Inputs';
import { SendLoginEmailProps } from '../../../actions/authActions';

interface LoginFormProps {
  onSubmit: (payload: SendLoginEmailProps) => void;
  initialValues: { redirectTo?: string };
}

const validateLogin = (value: string) => {
  const email = (value ?? '').trim();
  if (!/^.+@.+\..+$/.test(email)) return 'Please enter a valid email address';
};

const LoginForm = (props: LoginFormProps) => {
  return (
    <RFForm onSubmit={props.onSubmit} initialValues={props.initialValues}>
      {({ handleSubmit, invalid, submitting, pristine }) => (
        <Form onSubmit={handleSubmit}>
          <Row>
            <Col xs={12}>
              <Inputs.TcfTextInput type="email" placeholder="jane@example.com" name="email" validate={validateLogin} />
            </Col>
          </Row>
          <Row>
            <Col xs={12} md={6}>
              <Button
                disabled={invalid || submitting}
                type="submit"
                color="primary"
                style={{ whiteSpace: 'nowrap' }}
                title={pristine ? 'Please enter your email address above' : ''}
              >
                Send login email
              </Button>
            </Col>
            <Col xs={12} md={6} className="text-md-right">
              <a
                href="https://thecapitolforum.com/request-a-trial/?m=I%E2%80%99d+like+to+access+The+Capitol+Forum%E2%80%99s+full+library+of+investigative+reporting+and+in-depth+analysis+on+M%26A%2C+antitrust+and+corporate+investigations."
                target="_blank"
                rel="noopener noreferrer"
              >
                Not a Subscriber?
              </a>
            </Col>
          </Row>
          <Row>
            <Col xs={12} className="pt-2">
              <small style={{ color: 'grey' }}>
                Problems logging in or curious about how to use the website?{' '}
                <a
                  href="https://thecapitolforum.com/wp-content/uploads/2023/12/TCF-GUIDE-20231220.pdf"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Take a peek at our user guide (PDF).
                </a>
              </small>
            </Col>
          </Row>
        </Form>
      )}
    </RFForm>
  );
};

export default LoginForm;
