// JSON doesn't understand dates and, as such, dates are passed back and forth with the API as ISO strings.  Rather
// than casting all of the string dates to Date dates on every use, this class allows us to cast them once upon
// receipt by simply wrapping APIs call in the appropriate function.  There are many ways to implement this in
// Axios.  A transformer seemed like a really cool way to do it, but I couldn't find a way to make it generic yet
// customizable so we ended up with the method you see below which seemed like a good compromise.
//
// To use, simply instantiate an instance of this class passing in the function that performs the actual casting
// then wrap your API calls in the appropriate method; castDates or castAllDates.  There should be plenty of
// examples in the code for you to learn from.  Enjoy!
export class CastDates<T = unknown> {
  readonly _castingFunction: (o: T) => T;

  constructor(castingFunction: (o: T) => T) {
    this._castingFunction = castingFunction;
  }

  // For casting a single response object.
  castDates(o: T) {
    return o ? this._castingFunction(o) : o;
  }

  // Designed for casting a search/query response, but will work with any structure where an array of objects are
  // returned in the "results" property of the response.
  castAllDates(apiResponseData: { results: T[] }) {
    const results = apiResponseData?.results;
    return results
      ? {
          ...apiResponseData,
          results: results.map((o: T) => this.castDates(o)),
        }
      : apiResponseData;
  }
}
