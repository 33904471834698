import React from 'react';
import { Row, Col, Card, CardHeader, CardBody } from 'reactstrap';

import { allCategories, allRegions, allSectors } from 'tcf-shared/models';

import { useAppSelector } from '../../../utils/hooks';
import { getAuthUser } from '../../../reducers/authReducer';

const SubscriptionDetails = () => {
  const authUser = useAppSelector((state) => getAuthUser(state));
  if (!authUser) return null;

  const includedCategories =
    (authUser.categoryIds?.length ?? 0) > 0
      ? authUser.categoryIds!.map((id) => allCategories.find((category) => category.id === id)).filter((category) => category)
      : allCategories;

  const includedRegions =
    (authUser.regionIds?.length ?? 0) > 0
      ? authUser.regionIds!.map((id) => allRegions.find((region) => region.id === id)).filter((region) => region)
      : allRegions;

  const includedSectors =
    (authUser.sectorCodes?.length ?? 0) > 0
      ? authUser.sectorCodes!.map((code) => allSectors.find((sector) => sector.code === code)).filter((sector) => sector)
      : allSectors;

  const excludedCategories =
    (authUser.categoryIds?.length ?? 0) > 0
      ? allCategories.filter((category) => !authUser.categoryIds!.find((id) => id === category.id))
      : null;

  const excludedRegions =
    (authUser.regionIds?.length ?? 0) > 0
      ? allRegions.filter((region) => !authUser.regionIds?.find((id) => id === region.id))
      : null;

  const excludedSectors =
    (authUser.sectorCodes?.length ?? 0) > 0
      ? allSectors.filter((sector) => !authUser.sectorCodes?.find((code) => code === sector.code))
      : null;

  return (
    <div className="my-3">
      <p>Your subscription includes the following coverage categories, regions and industry sectors:</p>
      <Row>
        <Col md="4" className={'mb-3'}>
          <Card>
            <CardHeader>Coverage categories</CardHeader>
            <CardBody>
              <ul className={'pl-3'}>
                {includedCategories.map((c) => (
                  <li key={c!.id}>{c!.name}</li>
                ))}
              </ul>
            </CardBody>
          </Card>
        </Col>
        <Col md="4" className={'mb-3'}>
          <Card>
            <CardHeader>Regions</CardHeader>
            <CardBody>
              <ul className={'pl-3'}>
                {includedRegions.map((c) => (
                  <li key={c!.id}>{c!.name}</li>
                ))}
              </ul>
            </CardBody>
          </Card>
        </Col>
        <Col md="4" className={'mb-3'}>
          <Card>
            <CardHeader>Sectors</CardHeader>
            <CardBody>
              <ul className={'pl-3'}>
                {includedSectors.map((c) => (
                  <li key={c!.code}>{c!.name}</li>
                ))}
              </ul>
            </CardBody>
          </Card>
        </Col>
      </Row>
      {excludedCategories?.length || excludedRegions?.length || excludedSectors?.length ? (
        <>
          <p className="mt-3">Not included in your subscription:</p>
          <Row>
            <Col md="4" className={'mb-3'}>
              <Card>
                <CardHeader>Coverage categories</CardHeader>
                <CardBody>
                  <ul className={'pl-3'}>
                    {excludedCategories?.map((c) => (
                      <li key={c!.id}>{c!.name}</li>
                    ))}
                  </ul>
                </CardBody>
              </Card>
            </Col>
            <Col md="4" className={'mb-3'}>
              <Card>
                <CardHeader>Regions</CardHeader>
                <CardBody>
                  <ul className={'pl-3'}>
                    {excludedRegions?.map((c) => (
                      <li key={c!.id}>{c!.name}</li>
                    ))}
                  </ul>
                </CardBody>
              </Card>
            </Col>
            <Col md="4" className={'mb-3'}>
              <Card>
                <CardHeader>Sectors</CardHeader>
                <CardBody>
                  <ul className={'pl-3'}>
                    {excludedSectors?.map((c) => (
                      <li key={c!.code}>{c!.name}</li>
                    ))}
                  </ul>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </>
      ) : null}
      <p>
        Please contact your account manager if you'd like to change your subscription,&nbsp;or email
        editorial@thecapitolforum.com for assistance.
      </p>
    </div>
  );
};

export default SubscriptionDetails;
