import React from 'react';
import { ButtonGroup, Button, Input } from 'reactstrap';

import { PageData } from './PageData';

export interface PaginatorProps {
  className?: string;
  style?: React.CSSProperties;
  data: { query: { skip: number; limit: number }; total: number };
  onPageChange: (query: { skip: number; limit: number }) => any;
  showPerPage?: any;
}

export const Paginator = (props: PaginatorProps) => {
  const {
    data: { query, total },
    onPageChange,
    showPerPage,
  } = props;
  const pages = query ? new PageData(query, total) : null;

  return (
    (showPerPage || (pages && pages.total > 1)) && (
      <span>
        {total > 0 && props.showPerPage && (
          <span className={'float-right ml-2'}>
            Show&nbsp;
            <Input
              type="select"
              name="itemsPerPage"
              id="itemsPerPage"
              value={String(query.limit)}
              style={{ display: 'inline-block', width: 'auto', marginBottom: '0.5rem' }}
              onChange={(evt: any) => onPageChange({ ...query, skip: 0, limit: parseInt(evt.target.value, 10) })}
            >
              <option value="10">10</option>
              <option value="25">25</option>
              <option value="50">50</option>
              <option value="100">100</option>
            </Input>
            &nbsp;per page
          </span>
        )}
        {pages && pages.total > 1 && (
          <span className={props.className} style={props.style}>
            <ButtonGroup size="sm">
              {pages.range[0] > 1 && (
                <Button
                  color="light"
                  active={pages.current === 1}
                  onClick={() => {
                    onPageChange({ ...query, skip: 0 });
                  }}
                >
                  1
                </Button>
              )}
              {pages.range[0] > 1 && (
                <Button disabled color="light">
                  ...
                </Button>
              )}
              {pages.range.map((pageNumber: any) => {
                return (
                  <Button
                    color="light"
                    key={pageNumber}
                    active={pages.current === pageNumber}
                    onClick={() => {
                      onPageChange({ ...query, skip: (pageNumber - 1) * query.limit });
                    }}
                  >
                    {pageNumber}
                  </Button>
                );
              })}

              {pages.range[pages.range.length - 1] < pages.total && (
                <Button disabled color="light">
                  ...
                </Button>
              )}

              {pages.range[pages.range.length - 1] < pages.total && (
                <Button
                  color="light"
                  active={pages.current === pages.total}
                  onClick={() => {
                    onPageChange({ ...query, skip: (pages.total - 1) * query.limit });
                  }}
                >
                  {pages.total}
                </Button>
              )}
            </ButtonGroup>
          </span>
        )}
      </span>
    )
  );
};
