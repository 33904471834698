import { Action } from '../actions/Action';
import { ApiError, Document } from 'tcf-shared/models';
import {
  GET_DOCUMENT,
  SAVE_DOCUMENT,
  PUBLISH_DOCUMENT,
  UPLOAD_FILE,
  LOGOUT,
  DELETE_DOCUMENT,
  ADD_ASSOCIATED_DOCUMENT,
  SET_DOCUMENT,
  RESET_DOCUMENT_STORE,
} from '../actions/actionTypes';

export interface DocumentRelated {
  companies?: { id: string; name: string }[];
  topics?: { id: string; name: string }[];
}

export interface DocumentState {
  currentDoc?: Document;
  currentDocError: string;
  error: string;
  isDeleting: boolean;
  isFetching: boolean;
}

export const initialState: DocumentState = {
  currentDocError: '',
  error: '',
  isDeleting: false,
  isFetching: false,
};

export const documentsReducer = (state = initialState, action: Action): DocumentState => {
  switch (action.type) {
    case GET_DOCUMENT.REQUESTED:
      return {
        ...state,
        currentDoc: undefined,
        isFetching: true,
        currentDocError: '',
      };

    case SAVE_DOCUMENT.REQUESTED:
    case PUBLISH_DOCUMENT.REQUESTED:
    case UPLOAD_FILE.REQUESTED:
      return {
        ...state,
        currentDocError: '',
        isFetching: true,
      };

    case GET_DOCUMENT.SUCCEEDED:
    case SAVE_DOCUMENT.SUCCEEDED:
    case PUBLISH_DOCUMENT.SUCCEEDED:
    case UPLOAD_FILE.SUCCEEDED:
      return {
        ...state,
        currentDoc: action.payload,
        currentDocError: '',
        isFetching: false,
      };

    case DELETE_DOCUMENT.REQUESTED:
      return {
        ...state,
        currentDocError: '',
        isDeleting: true,
      };

    case DELETE_DOCUMENT.SUCCEEDED:
      return {
        ...state,
        currentDoc: undefined,
        currentDocError: '',
        isDeleting: false,
      };

    case DELETE_DOCUMENT.FAILED: {
      const error = action.payload as ApiError;
      return {
        ...state,
        currentDocError: error ? error.message : 'Not found',
        isDeleting: false,
      };
    }

    case GET_DOCUMENT.FAILED: {
      const error = action.payload as ApiError;
      return {
        ...state,
        currentDoc: undefined,
        currentDocError: error ? error.message : 'Not found',
        isFetching: false,
      };
    }

    case SAVE_DOCUMENT.FAILED:
    case PUBLISH_DOCUMENT.FAILED:
    case UPLOAD_FILE.FAILED: {
      const error = action.payload as ApiError;
      return {
        ...state,
        currentDoc: state.currentDoc,
        currentDocError: `${error ? `${error.name}: ${error.message}` : 'no message'}`,
        isFetching: false,
      };
    }

    case RESET_DOCUMENT_STORE:
      return {
        ...initialState,
      };

    case SET_DOCUMENT:
      return {
        ...state,
        currentDoc: action.payload,
        currentDocError: '',
        isFetching: false,
      };

    case ADD_ASSOCIATED_DOCUMENT.REQUESTED:
    case ADD_ASSOCIATED_DOCUMENT.SUCCEEDED:
    case ADD_ASSOCIATED_DOCUMENT.FAILED:
      // This is effectively a NOOP.  In order to tightly control when the page redraws, we will be handling
      // state refresh from within the page.  This is due to the fact that this action uploads many files
      // which will cause the page to redraw every time an upload completes which is not what we want.
      return state;

    case LOGOUT.SUCCEEDED:
    case LOGOUT.FAILED:
      return initialState;

    default:
      return state;
  }
};
