import React from 'react';
import Loader from 'react-loader';

import { getDirectoryEntityProfilePictureURL } from 'tcf-shared/models';

import { useAppSelector } from '../../../utils/hooks';
import ImageWithFallback from '../../../components/ImageWithFallback';
import ErrorComponent from '../../AsyncPage/ErrorComponent';

const iconStyle: React.CSSProperties = {
  float: 'left',
  marginRight: '0.8em',
  height: '2em',
  width: '2em',
};

interface OwnProps {
  entityStoreId: string;
}

const EntityDetails = (props: OwnProps) => {
  const { entityStoreId } = props;

  const directoryEntityState = useAppSelector((state) => state?.serverStores?.[entityStoreId] ?? {});
  const isFetching = directoryEntityState?.isFetching ?? true;
  const error = directoryEntityState?.error ?? '';
  const directoryEntity = directoryEntityState?.payload ?? {};

  if (isFetching) return <Loader loaded={false} />;
  if (error) return <ErrorComponent error={error} />;

  const src = getDirectoryEntityProfilePictureURL(directoryEntity);

  return (
    <>
      <div style={{ marginBottom: '1em', overflow: 'auto' }}>
        <ImageWithFallback
          src={src}
          fallbackSrc="/generic-profile-image.svg"
          alt={directoryEntity.name || 'Profile'}
          style={{
            fill: '#D1D0D0',
            float: 'left',
            marginRight: '0.8em',
            height: '150px',
            width: '150px',
          }}
        />
        <h5>{directoryEntity.name}</h5>
        {directoryEntity.title || directoryEntity.agency || directoryEntity.department ? (
          <p>
            {directoryEntity.title ? (
              <>
                {directoryEntity.title}
                <br />
              </>
            ) : null}
            {directoryEntity.agency ? (
              <>
                {directoryEntity.agency}
                <br />
              </>
            ) : null}
            {directoryEntity.department ? (
              <>
                {directoryEntity.department}
                <br />
              </>
            ) : null}
            {directoryEntity.phone ? (
              <>
                {directoryEntity.phone}
                <br />
              </>
            ) : null}
            {directoryEntity.emailAddress ? (
              <>
                <a href={`mailto:${directoryEntity.emailAddress}`}>{directoryEntity.emailAddress}</a>
                <br />
              </>
            ) : null}
          </p>
        ) : null}
      </div>
      {directoryEntity.twitterHandle ||
      directoryEntity.facebookURL ||
      directoryEntity.linkedInURL ||
      directoryEntity.instagramURL ||
      directoryEntity.homePageURL ? (
        <div style={{ marginBottom: '1em', overflow: 'auto' }}>
          {directoryEntity.twitterHandle ? (
            <a href={`https://twitter.com/${directoryEntity.twitterHandle}`} target="_blank" rel="noopener noreferrer">
              <img src="/socials/twitter.svg" alt="Visit Twitter profile" style={iconStyle} />
            </a>
          ) : null}
          {directoryEntity.facebookURL ? (
            <a href={directoryEntity.facebookURL} target="_blank" rel="noopener noreferrer">
              <img src="/socials/facebook.svg" alt="Visit Facebook profile" style={iconStyle} />
            </a>
          ) : null}
          {directoryEntity.linkedInURL ? (
            <a href={directoryEntity.linkedInURL} target="_blank" rel="noopener noreferrer">
              <img src="/socials/linkedin.svg" alt="Visit LinkedIn profile" style={iconStyle} />
            </a>
          ) : null}
          {directoryEntity.instagramURL ? (
            <a href={directoryEntity.instagramURL} target="_blank" rel="noopener noreferrer">
              <img src="/socials/instagram.svg" alt="Visit Instagram profile" style={iconStyle} />
            </a>
          ) : null}
          {directoryEntity.homePageURL ? (
            <a href={directoryEntity.homePageURL} target="_blank" rel="noopener noreferrer">
              <img src="/socials/homepage.svg" alt="Visit Home Page" style={iconStyle} />
            </a>
          ) : null}
        </div>
      ) : null}
    </>
  );
};

export default EntityDetails;
