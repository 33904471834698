import React from 'react';

import { DirectoryEvidence } from 'tcf-shared/models';

import { useAppDispatch } from '../../../utils/hooks';
import { getDirectoryEvidenceFileDownloadToken } from '../../../actions/directoryEvidenceActions';

export interface OwnProps extends React.DetailedHTMLProps<React.AnchorHTMLAttributes<HTMLAnchorElement>, HTMLAnchorElement> {
  directoryEvidence: DirectoryEvidence;
  children?: React.ReactNode;
}

const DownloadLink = (props: OwnProps) => {
  const dispatch = useAppDispatch();

  const { directoryEvidence, children, ...anchorProps } = props;

  const url = `/api/directory/evidence/${directoryEvidence.id}/file`;
  const fileName = directoryEvidence.originalFileName || directoryEvidence.id;

  let downloading = false;
  const handleClick = (event: React.MouseEvent<HTMLAnchorElement>) => {
    if (!downloading) {
      downloading = true;
      event.preventDefault();
      const anchor = event.currentTarget as HTMLAnchorElement;
      dispatch(getDirectoryEvidenceFileDownloadToken(directoryEvidence.id)).then((res: any) => {
        anchor.href = `${url}?dt=${res.token}`;
        anchor.click();
        anchor.href = url;
        downloading = false;
      });
    }
  };

  return (
    <a {...anchorProps} target="_blank" rel="noopener noreferrer" download={fileName} href={url} onClick={handleClick}>
      {children}
    </a>
  );
};

export default DownloadLink;
