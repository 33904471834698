import React from 'react';
import { Field as RFField } from 'react-final-form';
import { FormGroup, Input, Label } from 'reactstrap';

const TcfCheckbox = (props: any) => (
  <RFField {...props} type="checkbox" normalize={(value: unknown) => value === 'true'}>
    {(fieldProps) => (
      <FormGroup check className="mb-2" title={fieldProps.title}>
        <Input id={fieldProps.input.name} {...fieldProps.input} disabled={fieldProps.disabled} type="checkbox" value="true" />
        <Label check for={fieldProps.input.name} disabled={fieldProps.disabled}>
          {fieldProps.label}
        </Label>
      </FormGroup>
    )}
  </RFField>
);

export default TcfCheckbox;
