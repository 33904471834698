import { asyncAction } from './asyncAction';
import { Company, DeleteRequest, Query } from 'tcf-shared/models';
import { DELETE_SERVER, READ_SERVER, SAVE_SERVER } from './actionTypes';
import { library } from '../libraryApi';
import { toast } from './toastActions';

export function searchCompanies(storeIdentifier: string, query: Query) {
  return asyncAction({
    actionType: READ_SERVER,
    func: async () => (await library.companies.search(query)).data,
    storeIdentifier,
  });
}

export function similarCompanies(storeIdentifier: string, query: { name?: string; tickers?: string[] }) {
  return asyncAction({
    actionType: READ_SERVER,
    func: async () => (await library.companies.similar(query)).data,
    storeIdentifier,
  });
}

export function getCompany(storeIdentifier: string, id: string) {
  return asyncAction({
    actionType: READ_SERVER,
    func: async () => (await library.companies.get(id)).data,
    storeIdentifier,
  });
}

export function getCompanyRelated(storeIdentifier: string, id: string) {
  return asyncAction({
    actionType: READ_SERVER,
    func: async () => (await library.companies.getRelated(id)).data,
    storeIdentifier,
  });
}

export function saveCompany(storeIdentifier: string, company: Partial<Company>) {
  const func =
    company.meta && company.meta.createdAt
      ? async () => (await library.companies.put(company.id!, company)).data
      : async () => (await library.companies.post(company)).data;

  company.exchangeTickers = company.exchangeTickers ? company.exchangeTickers.filter((t) => t.symbol && t.symbol !== '') : [];

  return asyncAction({
    actionType: SAVE_SERVER,
    func,
    storeIdentifier,
    onSucceeded: async (result, dispatch) =>
      dispatch(
        toast.showSucceeded({
          title: 'Company saved.',
          message: 'Company saved.',
        }),
      ),
    onFailed: async (result, dispatch) =>
      dispatch(
        toast.showFailed({
          title: 'Error.',
          message: 'The company was not saved correctly.',
        }),
      ),
  });
}

export function deleteCompany(storeIdentifier: string, request: DeleteRequest) {
  return asyncAction({
    actionType: DELETE_SERVER,
    func: async () => (await library.companies.delete(request)).data,
    storeIdentifier,
    onSucceeded: async (result, dispatch) =>
      dispatch(
        toast.showSucceeded({
          title: 'Company deleted.',
          message: 'Company deleted.',
        }),
      ),
    onFailed: async (result, dispatch) =>
      dispatch(
        toast.showFailed({
          title: 'Error.',
          message: 'Something went wrong and we were not able to delete the company. Please reload the page and try again.',
        }),
      ),
  });
}
