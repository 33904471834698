/*
  The goal of this hook is to abstract away the complexity of popping up a simple file selection dialog.  In an ideal
  world this would be a component instead of a hook, however, I also wanted to abstract away how the file dialog is
  opened which is difficult to do in a component since, as far as I know, components can't expose their internals.  There
  is a way to do it using Refs, but I wanted to hide that as well, so here we are.

  It's a little clunky, but I think it turned out pretty well otherwise.  I have questions about efficiency (Is the <input>
  element being recreated every time the calling component is redrawn?  Probably.  Should it be memoized?  Maybe.  Can React
  efficiently detect differences against the VDOM?  Probably well enough.), but I'm not going to worry about them until we
  start seeing performance problems.

  See examples in code of how to use, but in summary, use {myFileSelector.inputElement} somewhere in your component to
  render the <input> element.  Use myFileSelector.open to pop open the file selection dialog box on, say, a button click.
  Use onFileSelected, onFilesSelected, or onChange to receive notification when a file is selected.
*/
import React, { useRef } from 'react';

interface FileSelectorProps extends React.DetailedHTMLProps<React.InputHTMLAttributes<HTMLInputElement>, HTMLInputElement> {
  // Returns the first file in the selection list.  A simplified abstraction of the onChange event.
  onFileSelected?: (file: File) => void;

  // Returns a FileList of all selected files.  Be sure to provide multiple={true} if you actually want the user
  // to be able to select multiple files otherwise you will always receive a list containing but a single file.
  // A simplified abstraction of the onChange event.
  onFilesSelected?: (file: FileList) => void;
}

export const useFileSelector = (props: FileSelectorProps) => {
  const { onFileSelected, onFilesSelected, onChange, ...rest } = props;

  const handlerCount = [onFileSelected, onFilesSelected, onChange].filter(Boolean).length;
  if (handlerCount > 1) {
    throw new Error('Only a single handler is supported at this time: onFileSelected, onFilesSelected, or onChange.');
  }
  if (handlerCount < 1) {
    throw new Error('No handlers provided.  Please supply one of: onFileSelected, onFilesSelected, or onChange.');
  }

  const ref = useRef<HTMLInputElement>(null);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    try {
      if (onChange) {
        onChange(event);
      } else {
        const files = event?.target?.files;
        if (files?.length) {
          if (onFileSelected) {
            onFileSelected(files[0]);
          } else if (onFilesSelected) {
            onFilesSelected(files);
          }
        }
      }
    } finally {
      // If we don't do this and the user selects the same file again later, the onChange event won't
      // fire.  Little gotchas like this are part of the reason we're abstracting this into a hook.
      if (ref?.current?.value) ref.current.value = '';
    }
  };

  return {
    ref, // In case the caller wants to hook into the <input> element for whatever reason.
    open: () => {
      if (ref?.current) {
        ref.current.click();
      }
    },
    inputElement: <input style={{ display: 'none' }} {...rest} type="file" ref={ref} onChange={handleChange} />,
  };
};
