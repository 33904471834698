import React from 'react';
import { connect } from 'react-redux';
import 'react-pdf/dist/esm/Page/AnnotationLayer.css';

import { AuthUser, Document } from 'tcf-shared/models';

import { getAuthUser } from '../../reducers/authReducer';
import { DocumentRelated } from '../../reducers/documentsReducer';
import { longDateTimeFormat } from '../../constants/momentFormat';
import { getDocument, getDocumentRelated, resetDocumentStore } from '../../actions/documentsActions';
import { resetServerStore } from '../../actions/serverStoreActions';
import { useMediaQuery } from '../../utils/hooks';
import ViewDocumentInner from './components/ViewDocumentInner';

const DOCUMENT_RELATED_STORE = 'viewDocumentRelatedStore';

interface OwnProps {
  headerHeight: number;
}

interface StateProps {
  accessToken?: string | null;
  authUser?: AuthUser;
  document: Document;
  documentRelated?: DocumentRelated;
  error: string;
  isFetching: boolean;
}

interface DispatchProps {
  propsGetDocument: (id: string) => any;
  propsGetDocumentRelated: (storeIdentifier: string, id: string) => any;
  propsResetDocumentStore: () => void;
  propsResetServerStore: (serverStoreId: string) => void;
}

type ViewDocumentProps = OwnProps & StateProps & DispatchProps;

const ViewDocument = (props: ViewDocumentProps) => {
  const printing = useMediaQuery('print');

  if (printing) {
    return null;
  }

  return <ViewDocumentInner dateFormat={longDateTimeFormat} {...props} documentRelatedStoreId={DOCUMENT_RELATED_STORE} />;
};

export default connect<StateProps, DispatchProps>(
  (state: any) => ({
    accessToken: state.auth.accessToken,
    authUser: getAuthUser(state),
    document: state.document.currentDoc as Document,
    documentRelated: state.serverStores?.[DOCUMENT_RELATED_STORE]?.payload?.results as DocumentRelated,
    error: state.document.currentDocError || state.serverStores?.[DOCUMENT_RELATED_STORE]?.error,
    isFetching: state.document.isFetching || state.serverStores?.[DOCUMENT_RELATED_STORE]?.isFetching,
  }),
  {
    propsGetDocument: getDocument,
    propsGetDocumentRelated: getDocumentRelated,
    propsResetDocumentStore: resetDocumentStore,
    propsResetServerStore: resetServerStore,
  },
)(ViewDocument);
