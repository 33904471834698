import React from 'react';
import { Field as RFField, FieldInputProps as RFFieldInputProps } from 'react-final-form';

interface OwnProps {
  fieldName: string;
  keyField?: string;
  options: any[];
  useSimpleArray?: boolean;
}

const CheckboxListSelect = (props: OwnProps) => {
  const { fieldName, keyField, options, useSimpleArray } = props;
  const useKeyField = keyField || 'id';

  const onChange = (event: any, input: RFFieldInputProps<any, HTMLElement>) => {
    // event.preventDefault();
    // event.stopPropagation();
    const target = event.target;
    const targetValue = target.value;
    const targetIsChecked = target.checked;
    const values = input.value;
    const newValues: any[] = useSimpleArray
      ? targetIsChecked
        ? [...values, targetValue]
        : values.filter((v: any) => v !== targetValue)
      : targetIsChecked
      ? [...values, { [useKeyField]: targetValue }]
      : values.filter((v: any) => v[useKeyField] !== targetValue);
    input.onChange(newValues);
  };

  return (
    <>
      {options.map((o) => (
        <div className="d-inline-block mb-2 mr-3" key={`${fieldName}${o.value}`}>
          <RFField name={fieldName}>
            {(renderProps) => (
              <input
                type="checkbox"
                value={o.value}
                id={o.value}
                disabled={o.disabled}
                checked={
                  useSimpleArray
                    ? renderProps.input.value.includes(o.value)
                    : !!(renderProps.input.value || []).find((v: any) => v[useKeyField] === o.value)
                }
                onChange={(e) => onChange(e, renderProps.input)}
              />
            )}
          </RFField>
          <label className="form-check-label" htmlFor={o.value}>
            &nbsp;{o.label}
          </label>
        </div>
      ))}
    </>
  );
};

export default CheckboxListSelect;
