import React from 'react';
import Loadable from 'react-loadable';
import { Input, InputGroup, InputGroupAddon, Label, FormGroup, FormFeedback } from 'reactstrap';
import { Field as RFField } from 'react-final-form';

const TcfRichTextArea = Loadable({
  loader: () => import('./TcfRichTextArea'),
  loading: () => <div>Loading rich text editor...</div>,
});

export interface TcfTextInputProps {
  richText?: boolean;
  disabled?: boolean;
  autoComplete?: string;
  label?: string;
  className?: string;
  placeholder?: string;
  name: string;
  button?: React.ReactElement;
  bsSize?: 'lg' | 'sm';
  style?: object;
  required?: boolean; // Uses built-in browser "required" handler.
  requiredIndicator?: boolean; // Use this one if you just want something to show the field is required without using the browser "required" handler.
  maxLength?: number;
  validate?: (value: any) => string | undefined;
  rows?: number;
  type:
    | 'button'
    | 'checkbox'
    | 'color'
    | 'date'
    | 'datetime'
    | 'datetime-local'
    | 'email'
    | 'file'
    | 'hidden'
    | 'image'
    | 'month'
    | 'number'
    | 'password'
    | 'radio'
    | 'range'
    | 'reset'
    | 'search'
    | 'select'
    | 'submit'
    | 'tel'
    | 'text'
    | 'textarea'
    | 'time'
    | 'url'
    | 'week';
}

const TcfTextInput = (props: TcfTextInputProps) => (
  <RFField
    className={props.className}
    placeholder={props.placeholder}
    name={props.name}
    type={props.type}
    component={props.richText ? renderTcfRichTextInput : renderTcfTextInput}
    validate={props.validate}
    props={{
      label: props.label,
      name: props.name,
      button: props.button,
      bsSize: props.bsSize,
      style: props.style,
      required: props.required,
      requiredIndicator: props.requiredIndicator,
      maxLength: props.maxLength,
      autoComplete: props.autoComplete,
      rows: props.rows,
    }}
    disabled={props.disabled}
    autoComplete={props.autoComplete}
  />
);

const renderInputGroup = (field: any, hasError: boolean) => {
  const input = (
    <Input
      id={field.props.name}
      className={field.className}
      type={field.type}
      placeholder={field.placeholder}
      invalid={hasError}
      {...field.input}
      bsSize={field.props.bsSize}
      style={field.props.style}
      disabled={field.disabled}
      required={field.props.required}
      maxLength={field.props.maxLength}
      autoComplete={field.props.autoComplete}
      rows={field.props.rows}
    />
  );

  if (!field.props.button) {
    return input;
  }

  return (
    <InputGroup>
      {input}
      <InputGroupAddon addonType={'append'}>{field.props.button}</InputGroupAddon>
    </InputGroup>
  );
};

const renderTcfTextInput = (field: any) => {
  const hasError = !!(field.meta.touched && field.meta.error);
  const requiredIndicator =
    field.props.required || field.props.requiredIndicator ? (
      <span style={{ color: 'gray', fontSize: '0.8rem' }}> (required)</span>
    ) : null;

  return (
    <FormGroup color={hasError ? 'danger' : ''} width={'500px'}>
      {field.props.label ? (
        <Label for={field.props.name}>
          {field.props.label}
          {requiredIndicator}
        </Label>
      ) : null}
      {renderInputGroup(field, hasError)}
      {hasError && <FormFeedback className="d-block">{field.meta.error}</FormFeedback>}
    </FormGroup>
  );
};

const renderTcfRichTextInput = (field: any) => {
  const hasError = !!(field.meta.touched && field.meta.error);
  const requiredIndicator =
    field.props.required || field.props.requiredIndicator ? (
      <span style={{ color: 'gray', fontSize: '0.8rem' }}> (required)</span>
    ) : null;

  return (
    <FormGroup color={hasError ? 'danger' : ''}>
      {field.props.label ? (
        <Label for={field.props.name}>
          {field.props.label}
          {requiredIndicator}
        </Label>
      ) : null}
      <TcfRichTextArea
        id={field.props.name}
        className={field.className}
        type={field.type}
        placeholder={field.placeholder}
        state={hasError ? 'danger' : ''}
        {...field.input}
      />
      {hasError && <FormFeedback className="d-block">{field.meta.error}</FormFeedback>}
    </FormGroup>
  );
};

export default TcfTextInput;
