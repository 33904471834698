import React from 'react';

import { AuthUser, Query, TopicSearch } from 'tcf-shared/models';

import CategoryFilter from '../../../components/filters/CategoryFilter';
import CurrentFilter from '../../../components/filters/CurrentFilter';
import RegionFilter from '../../../components/filters/RegionFilter';
import SectorFilter from '../../../components/filters/SectorFilter';

export interface OwnProps {
  authUser?: AuthUser;
  query: Query;
  onQueryChange: (query: Query, debounce?: boolean) => void;
}

const IssuesFilter = (props: OwnProps) => {
  const { authUser, query, onQueryChange } = props;

  const search: TopicSearch = query?.search || {};

  if (!search) return null;

  const onChangeName = (event: React.ChangeEvent<HTMLInputElement>) => {
    const searchName = event.target.value;
    const _search: TopicSearch = { ...query?.search };
    if (searchName) {
      _search.name = searchName;
    } else {
      delete _search.name;
    }
    onQueryChange({ ...query, skip: 0, search: _search }, true);
  };

  const onChangeCurrent = (current: boolean) => {
    const _search: TopicSearch = { ...query?.search };
    if (current) {
      _search.current = true;
    } else {
      delete _search.current;
    }
    onQueryChange({ ...query, skip: 0, search: _search });
  };

  const onChangeSearchField = (searchField: string, searchIds: string[]) => {
    // Handle changes to array of categories, regions, sectors IDs criteria
    const _search: TopicSearch = { ...query?.search };
    if (searchIds?.length) {
      // Set the array of IDs for the searchField
      _search[searchField] = searchIds;
    } else {
      // Or remove any existing value for the searchField; leaving an empty array is insufficient for determining
      // when to enable the "save search" feature.
      delete _search[searchField];
    }
    onQueryChange({ ...query, skip: 0, search: _search });
  };

  return (
    <>
      <input
        type="text"
        value={search.name ?? ''}
        onChange={onChangeName}
        placeholder="Enter search terms..."
        className="flex-fill form-control prop-field mb-3"
      />
      <CurrentFilter current={search.current} onChange={onChangeCurrent} />
      <CategoryFilter
        authUser={authUser}
        selectedCategories={search.categories}
        onChange={(searchIds: string[]) => onChangeSearchField('categories', searchIds)}
      />
      <RegionFilter
        authUser={authUser}
        selectedRegions={search.regions}
        onChange={(searchIds: string[]) => onChangeSearchField('regions', searchIds)}
      />
      <SectorFilter
        authUser={authUser}
        selectedSectors={search.sectors}
        onChange={(searchIds: string[]) => onChangeSearchField('sectors', searchIds)}
      />
    </>
  );
};

export default IssuesFilter;
