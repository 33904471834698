import React, { useEffect, useState } from 'react';
import { Button } from 'reactstrap';
import BootstrapTable, { SortOrder } from 'react-bootstrap-table-next';
import { useHistory, Link } from 'react-router-dom';
import Loader from 'react-loader';

import { SavedSearch } from 'tcf-shared/models';

import { useAppDispatch, useAppSelector } from '../../../utils/hooks';
import { deleteSavedSearch, saveSavedSearch, searchSavedSearches } from '../../../actions/savedSearchesActions';
import { formatVeryShortDate } from '../../../utils/momentFormat';
import ErrorComponent from '../../AsyncPage/ErrorComponent';
import { paths } from '../../../paths';
import { DELETE_SERVER_UPDATE_LIST, SAVE_SERVER_UPDATE_LIST } from '../../../actions/actionTypes';
import { ConfirmDialog } from '../../../components';
import { getAuthUser } from '../../../reducers/authReducer';
import SavedSearchDescription from './SavedSearchDescription';

export interface SavedSearchListProps {
  storeId: string;
  userId: string;
}

const MAX_SAVED_SEARCHES = 10;

const SavedSearchList = (props: SavedSearchListProps) => {
  const history = useHistory();
  const dispatch = useAppDispatch();
  const { storeId, userId } = props;

  const authUser = useAppSelector((state) => getAuthUser(state));
  const isAdmin = authUser?.isAdmin;
  const isViewingSelf = userId === authUser?.id;
  const savedSearchStore = useAppSelector((state) => state?.serverStores?.[storeId]);
  const isFetching: boolean = savedSearchStore?.isFetching;
  const error: string = savedSearchStore?.error;
  const savedSearches = savedSearchStore?.payload?.results || [];
  const topicLookup = savedSearchStore?.payload?.topicLookup || {};
  const savedSearchCount = savedSearches.length;
  const [showDeleteDialog, setShowDeleteDialog] = useState(false);
  const [idToDelete, setIdToDelete] = useState<string | undefined>(undefined);

  const toggleDeleteDialog = (id: string | undefined) => {
    setIdToDelete(id);
    setShowDeleteDialog(!showDeleteDialog);
  };

  useEffect(() => {
    dispatch(searchSavedSearches(storeId, { search: { userId } }));
  }, [storeId, dispatch, userId]);

  if (isFetching) {
    return <Loader loaded={false} />;
  } else if (error) {
    return <ErrorComponent error={error} />;
  }

  const handleEdit = (id: string) => {
    history.push(paths.EDIT_SEARCH.replace(':id', id).replace(':userId', userId));
  };

  const handleDelete = () => {
    const savedSearch = savedSearches.find((s: SavedSearch) => s.id === idToDelete);
    if (savedSearch) {
      dispatch(deleteSavedSearch(storeId, savedSearch.id, savedSearch.meta.updatedAt, DELETE_SERVER_UPDATE_LIST));
    }
    toggleDeleteDialog(undefined);
  };

  const handleAlertChanged = (id: string) => {
    const savedSearch = savedSearches.find((s: SavedSearch) => s.id === id);
    savedSearch.alert = !savedSearch.alert;
    dispatch(saveSavedSearch(storeId, savedSearch, SAVE_SERVER_UPDATE_LIST));
  };

  const dateFormatter = (cell: any) => {
    return formatVeryShortDate(cell);
  };

  const alertFormatter = (cell: boolean, row: any) => {
    return (
      <span>
        <input
          type="checkbox"
          defaultChecked={cell}
          onChange={() => handleAlertChanged(row.id)}
          disabled={!(isViewingSelf || isAdmin)}
        />{' '}
        {cell ? 'On' : 'Off'}
      </span>
    );
  };

  const nameFormatter = (cell: string, row: any) => {
    return isViewingSelf ? (
      <Link to={paths.SAVED_SEARCH_RESULTS.replace(':id', row.id)} title="View search results">
        {cell || ''}
      </Link>
    ) : (
      cell || ''
    );
  };

  const searchFormatter = (cell: any, row: any) => {
    return isViewingSelf ? (
      <Link to={paths.SAVED_SEARCH_RESULTS.replace(':id', row.id)} title="View search results">
        <SavedSearchDescription savedSearch={row} topicLookup={topicLookup} />
      </Link>
    ) : (
      <SavedSearchDescription savedSearch={row} topicLookup={topicLookup} />
    );
  };

  const actionsFormatter = (cell: any, row: any) => {
    return (
      <>
        <Button className="mb-2 mr-2 btn-sm" title="Edit saved search" onClick={() => handleEdit(row.id)}>
          Edit
        </Button>
        <Button className="mb-2 btn-sm" title="Delete saved search" onClick={() => toggleDeleteDialog(row.id)}>
          Delete
        </Button>
      </>
    );
  };

  const columns = [
    {
      dataField: 'alert',
      text: 'Email',
      sort: true,
      formatter: alertFormatter,
      headerStyle: { width: '6%' },
    },
    {
      dataField: 'meta.createdAt',
      text: 'Created',
      sort: true,
      formatter: dateFormatter,
      headerStyle: { width: '8%' },
    },
    {
      dataField: 'name',
      text: 'Name',
      sort: true,
      formatter: nameFormatter,
      headerStyle: { width: '20%' },
    },
    {
      dataField: 'categories',
      text: 'Search',
      formatter: searchFormatter,
      headerStyle: { width: '50%' },
    },
    {
      isDummyField: true,
      dataField: 'searchActions',
      text: 'Actions',
      formatter: actionsFormatter,
      headerStyle: { width: '14%' },
      align: 'right',
      headerAlign: 'right',
      hidden: !(isViewingSelf || isAdmin),
    },
  ];

  return (
    <>
      <div className="float-right ml-2">
        {(isViewingSelf || isAdmin) && savedSearchCount < MAX_SAVED_SEARCHES ? (
          <Link
            className="btn btn-secondary"
            to={paths.EDIT_SEARCH.replace(':id', 'new').replace(':userId', userId)}
            title={`Create a new saved search ${isViewingSelf ? '' : 'on behalf of user'}`}
          >
            New search
          </Link>
        ) : (
          <Button
            className="btn-secondary"
            disabled
            title={`Using ${MAX_SAVED_SEARCHES} of ${MAX_SAVED_SEARCHES} allowed saved searches; cannot create more`}
          >
            New search
          </Button>
        )}
      </div>
      <div className={'my-3'}>
        The Saved Searches feature allows you to create and reuse targeted article search criteria. Enabling the 'Email' option
        will notify you via email when new articles match one of your saved searches.
      </div>
      <div className="clearfix" />
      <BootstrapTable
        bootstrap4
        condensed
        keyField="id"
        data={savedSearches}
        columns={columns}
        bordered={false}
        defaultSorted={[{ dataField: 'meta.updatedAt', order: 'desc' as SortOrder }]}
        noDataIndication={'No saved searches'}
        striped
      />
      {savedSearchCount >= MAX_SAVED_SEARCHES && (
        <div className={'my-3 font-italic'}>
          <p>{`Using ${MAX_SAVED_SEARCHES} of ${MAX_SAVED_SEARCHES} allowed searches.`}</p>
        </div>
      )}
      {showDeleteDialog && (
        <ConfirmDialog
          isOpen={showDeleteDialog}
          toggle={() => toggleDeleteDialog(undefined)}
          header="Really delete this search?"
          body="This action cannot be reversed."
          confirmButtonText="Yes, delete search"
          confirmButtonColor="danger"
          onConfirm={handleDelete}
        />
      )}
    </>
  );
};

export default SavedSearchList;
