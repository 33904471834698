import React, { ChangeEvent, useEffect, useState } from 'react';
import { FormGroup, Input, Label, Popover, PopoverBody } from 'reactstrap';

interface CheckboxProps {
  checkboxId: string;
  checked: boolean;
  disabled: boolean;
  entitled: boolean;
  label: string;
  notEntitledWarning: string;
  onChange: (isChecked: boolean, value: string) => boolean;
  sectionLabel: string;
  value: string;
}

const Checkbox = (props: CheckboxProps) => {
  const { checkboxId, disabled, entitled, label, sectionLabel } = props;

  const [isPopoverOpen, setPopoverOpen] = useState(false);
  const [popoverMessage, setPopoverMessage] = useState('');

  useEffect(() => {
    if (isPopoverOpen && popoverMessage) {
      const timer = setTimeout(() => {
        setPopoverOpen(false);
      }, 4000);
      return () => clearTimeout(timer);
    }
  }, [isPopoverOpen, popoverMessage]);

  const handleCheckboxChange = (e: ChangeEvent<HTMLInputElement>) => {
    if (entitled && !disabled) {
      // onChange returns false if the change was disallowed.  Currently, the only reason the
      // change would be disallowed is if there are no checkboxes selected in a section.
      if (!props.onChange(e.target.checked, e.target.value)) {
        setPopoverMessage(`At least one ${sectionLabel} is required.`);
        setPopoverOpen(true);
      }
    }
  };

  const handleDisabledClick = () => {
    if (!entitled && !disabled) {
      setPopoverMessage(props.notEntitledWarning.replace('%s', sectionLabel));
      setPopoverOpen(true);
    }
  };

  // The FormGroup onClick here is a backup in case the overlay div below doesn't work.  Unfortunately, this won't work
  // if the user clicks directly on the checkbox, only if they click on the label, thus the overlay div hack below.
  return (
    <FormGroup check inline onClick={handleDisabledClick}>
      <Input
        type="checkbox"
        id={checkboxId}
        disabled={disabled || !entitled}
        value={props.value}
        checked={props.checked}
        onChange={handleCheckboxChange}
      />
      <Label check for={checkboxId}>
        {entitled ? label : <i>{label}</i>}
      </Label>
      {/* This bit places an overlay div over the checkbox and its label so we can capture clicks on disabled checkboxes. */}
      {!entitled && !disabled && (
        <div onClick={handleDisabledClick} style={{ position: 'absolute', top: 0, bottom: 0, left: 0, right: 0 }} />
      )}
      <Popover placement="top" isOpen={isPopoverOpen} target={checkboxId} toggle={() => setPopoverOpen(false)}>
        <PopoverBody>{popoverMessage}</PopoverBody>
      </Popover>
    </FormGroup>
  );
};

export default Checkbox;
