import React from 'react';

import { AuthUser, allCategoriesTableFilterOptions } from 'tcf-shared/models';
import FacetFilter from './FacetFilter';

interface CategoryFilterProps {
  authUser?: AuthUser;
  onChange?: any;
  selectedCategories?: string[];
}

const CategoryFilter = (props: CategoryFilterProps) => {
  const { authUser, onChange } = props;
  const selectedCategories = props.selectedCategories || [];

  if (!authUser) return null;

  // Disable nonapplicable options based on user permissions
  const options = allCategoriesTableFilterOptions.map((o) => ({
    ...o,
    disabled: !!(authUser.categoryIds?.length && !authUser.categoryIds.includes(o.value)),
    selected: selectedCategories.includes(o.value),
  }));

  const optionsChanged = (event: any) => {
    event.stopPropagation();
    const { value, checked } = event.target;
    let newOptions;
    if (checked) {
      newOptions = [...selectedCategories];
      newOptions.push(value);
    } else {
      newOptions = selectedCategories.filter((x) => x !== value);
    }
    onChange(newOptions);
  };

  return <FacetFilter title="Categories" options={options} onChange={optionsChanged} />;
};

export default CategoryFilter;
