import React from 'react';
import { Col, Row } from 'reactstrap';
import { isNumber } from 'lodash';

const WrapLicenseCount = (props: { children: React.ReactNode }) => {
  return (
    <Row className="mb-2">
      <Col>{props.children}</Col>
    </Row>
  );
};

const LicenseCount = (props: { licenseCount: number; licensesAvailable: number; licenseHasErrored: boolean }) => {
  const { licenseCount, licensesAvailable, licenseHasErrored } = props;

  if (licenseHasErrored)
    return (
      <WrapLicenseCount>
        We were unable to retrieve licensing information for your organization. Please contact your account manager for
        assistance.
      </WrapLicenseCount>
    );

  // This can theoretically only happen if an organization has unlimited licenses.  Don't show anything in this case.
  if (!isNumber(licenseCount) || !isNumber(licensesAvailable)) return null;

  return (
    <WrapLicenseCount>
      Your organization has a license for {licenseCount.toLocaleString()} {licenseCount === 1 ? 'user' : 'users'}.{' '}
      {licensesAvailable > 0 ? licensesAvailable.toLocaleString() : 'No'}{' '}
      {licensesAvailable === 1 ? 'license is' : 'licenses are'} available for use.
    </WrapLicenseCount>
  );
};

export default LicenseCount;
