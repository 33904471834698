// Use this if you want your active tab to interact correctly with the back/forward browser button.
import { useHistory, useLocation } from 'react-router-dom';

export const useLocationHistoryActiveTab = (defaultActiveTab: string) => {
  const history = useHistory<{ activeTab?: string }>();
  const location = useLocation<{ activeTab?: string }>();

  const locationState = location.state || {};
  const activeTab = locationState.activeTab ?? defaultActiveTab;

  const setActiveTab = (newActiveTab: string) => {
    if (newActiveTab !== activeTab) {
      history.push({ ...history.location, state: { ...locationState, activeTab: newActiveTab } });
    }
  };

  return [activeTab, setActiveTab] as const;
};
