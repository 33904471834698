import { Action } from '../actions/Action';
// import { ApiError } from 'tcf-shared/models';
import { GET_STARTUP_DATA } from '../actions/actionTypes';

export interface ReferenceDataState {
  isFetching: boolean;
  error: string;
}

export const initialState: ReferenceDataState = {
  isFetching: false,
  error: '',
};

export const referenceDataReducer = (state = initialState, action: Action): ReferenceDataState => {
  switch (action.type) {
    case GET_STARTUP_DATA.REQUESTED:
      return { isFetching: true, error: '' };

    case GET_STARTUP_DATA.SUCCEEDED:
      return { isFetching: false, error: '' };

    case GET_STARTUP_DATA.FAILED:
      // const error = action.payload as ApiError;
      return {
        isFetching: false,
        error: '',
        // Having error here can cause infinite loop bouncing between home page and login
        // error: `${error ? `${error.name}: ${error.message}` : 'no message'}`,
      };

    default:
      return state;
  }
};
