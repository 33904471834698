// @ts-ignore
import { SortField, Query, QueryFilters } from 'tcf-shared/models';

import {
  INITIALIZE_QUERY,
  SET_QUERY,
  SET_QUERY_PAGING_AND_SORTING,
  SET_QUERY_PAGING_AND_SEARCH_SORTING,
  SET_QUERY_FILTER,
  SET_QUERY_SEARCH,
  UPDATE_QUERY_FILTERS,
  UPSERT_QUERY_FILTER,
  REMOVE_QUERY_FILTER,
  COMPLETELY_REMOVE_QUERY_FILTERS,
  ADD_TO_QUERY_FILTER_LIST,
  REMOVE_FROM_QUERY_FILTER_LIST,
  RESET_QUERY_FILTER,
  RESET_QUERY,
} from './actionTypes';

// Only update query if there is no current query.
export function initializeQuery(queryIdentifier: string, query: Query) {
  return { type: INITIALIZE_QUERY, payload: { queryIdentifier, ...query } };
}

export function setQuery(queryIdentifier: string, query: Query) {
  return { type: SET_QUERY, payload: { queryIdentifier, ...query } };
}

export function setQueryPagingAndSorting(
  queryIdentifier: string,
  pagingAndSorting: { skip?: number; limit?: number; sort?: SortField[] },
) {
  return { type: SET_QUERY_PAGING_AND_SORTING, payload: { queryIdentifier, pagingAndSorting } };
}

export function setQueryPagingAndSearchSorting(
  queryIdentifier: string,
  pagingAndSorting: { skip?: number; limit?: number; sortBy?: string; sortOrder?: string },
) {
  return { type: SET_QUERY_PAGING_AND_SEARCH_SORTING, payload: { queryIdentifier, pagingAndSorting } };
}

export function setQueryFilter(queryIdentifier: string, filters: QueryFilters) {
  return { type: SET_QUERY_FILTER, payload: { queryIdentifier, filters } };
}

export function setQuerySearch(queryIdentifier: string, search: any) {
  return { type: SET_QUERY_SEARCH, payload: { queryIdentifier, search } };
}

export function updateQueryFilter(queryIdentifier: string, filters: QueryFilters) {
  return { type: UPDATE_QUERY_FILTERS, payload: { queryIdentifier, filters } };
}

export function upsertQueryFilter(queryIdentifier: string, filterField: string, filterValue: any) {
  return { type: UPSERT_QUERY_FILTER, payload: { queryIdentifier, filterField, filterValue } };
}

export function removeQueryFilter(queryIdentifier: string, filterField: string) {
  return { type: REMOVE_QUERY_FILTER, payload: { queryIdentifier, filterField } };
}

export function completelyRemoveQueryFilters(queryIdentifier: string) {
  return { type: COMPLETELY_REMOVE_QUERY_FILTERS, payload: { queryIdentifier } };
}

export function addToQueryFilterList(queryIdentifier: string, filterField: string, listItem: string) {
  return { type: ADD_TO_QUERY_FILTER_LIST, payload: { queryIdentifier, filterField, listItem } };
}

export function removeFromQueryFilterList(queryIdentifier: string, filterField: string, listItem: string) {
  return { type: REMOVE_FROM_QUERY_FILTER_LIST, payload: { queryIdentifier, filterField, listItem } };
}

export function resetQueryFilter(queryIdentifier: string) {
  return { type: RESET_QUERY_FILTER, payload: { queryIdentifier } };
}

export function resetQuery(queryIdentifier: string) {
  return { type: RESET_QUERY, payload: { queryIdentifier } };
}
