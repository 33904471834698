import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';

import { Col, Row } from 'reactstrap';
import { logout } from '../../actions/authActions';
import { paths } from '../../paths';
import { useAppDispatch } from '../../utils/hooks';
import BasicPage from '../BasicPage/BasicPage';

const Logout = () => {
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(logout());
  }, [dispatch]);

  return (
    <BasicPage title="Logout">
      <Row>
        <Col sm={{ size: 6, offset: 3 }} className="pt-3">
          <p className="lead">
            You have successfully logged out.
            <br />
            <br />
            <Link title="Go to login page" className="btn btn-primary" to={paths.LOGIN}>
              Click Here to Log Back In
            </Link>
          </p>
        </Col>
      </Row>
    </BasicPage>
  );
};

export default Logout;
