import React, { useEffect, useState } from 'react';

import { addToArrayIfMissing, removeFromArrayIfPresent } from '../../utils';
import { Col } from 'reactstrap';
import Checkbox from './Checkbox';

export type CheckboxTypeDesignators = 'categories' | 'regions' | 'sectors';

interface CheckboxGroupProps {
  allItems: { id: string; name: string }[] | { code: string; name: string }[];
  entitledItems: string[];
  designator: CheckboxTypeDesignators;
  disabled: boolean;
  notEntitledWarning: string;
  onChange: (designator: CheckboxTypeDesignators, selectedItems: string[]) => boolean;
  sectionLabel: string;
  selectedItems: string[];
}

const CheckboxGroup = (props: CheckboxGroupProps) => {
  const { allItems, entitledItems, designator, disabled, selectedItems, onChange } = props;

  const [internalSelectedItems, setInternalSelectedItems] = useState<string[]>(selectedItems);

  const selectedItemsString = JSON.stringify(selectedItems);
  useEffect(() => {
    const _selectedItems = JSON.parse(selectedItemsString);
    setInternalSelectedItems(_selectedItems);
  }, [selectedItemsString]);

  const keyName = designator === 'sectors' ? 'code' : 'id';

  const handleCheckboxChange = (isChecked: boolean, value: string) => {
    const func = isChecked ? addToArrayIfMissing : removeFromArrayIfPresent;
    const newSelectedItems = func(internalSelectedItems, value);
    if (onChange(designator, newSelectedItems)) {
      setInternalSelectedItems(newSelectedItems);
      return true;
    }
    return false;
  };

  return (
    <>
      {allItems.map((item) => {
        const checkboxPrefix = `${designator}-${item[keyName]}`;
        return (
          <Col key={`${checkboxPrefix}-key`} xs={12} lg={6} xl={4} className="mb-0">
            <Checkbox
              checkboxId={`${checkboxPrefix}-checkbox`}
              checked={disabled ? false : internalSelectedItems.includes(item[keyName])}
              disabled={disabled}
              entitled={entitledItems.includes(item[keyName])}
              label={item.name}
              notEntitledWarning={props.notEntitledWarning}
              onChange={handleCheckboxChange}
              sectionLabel={props.sectionLabel}
              value={item[keyName]}
            />
          </Col>
        );
      })}
    </>
  );
};

export default CheckboxGroup;
