import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import { DropdownItem, DropdownMenu, DropdownToggle, UncontrolledDropdown } from 'reactstrap';

import { getAuthUser } from '../../reducers/authReducer';
import { paths } from '../../paths';
import { useAppSelector } from '../../utils/hooks';

const AdminMenu = ({ closeMenu }: { closeMenu: () => void }) => {
  const { pathname } = useLocation();

  const authUser = useAppSelector((state) => getAuthUser(state));

  const { isStaff, isAdmin, canManageDirectory, canManageCalendar } = authUser || {};

  const { admin } = paths;
  const manageOrList = (isAdmin && 'Manage ') || 'List ';

  const isDocViewPage =
    !pathname.endsWith('/announcement') && pathname.match(paths.VIEW_DOCUMENT.replace(':id', '[a-z0-9]{12}'));
  const isDocActivityPage =
    !pathname.endsWith('/announcement') && pathname.match(paths.admin.VIEW_DOCUMENT_ACTIVITY.replace(':id', '[a-z0-9]{12}'));
  const isCompanyViewPage = pathname.match(paths.VIEW_COMPANY.replace(':id', '[a-z0-9]{12}'));
  const isTopicViewPage = pathname.match(paths.VIEW_ISSUE.replace(':id', '[a-z0-9]{12}'));
  const isUserViewPage = pathname.match(paths.admin.VIEW_USER.replace(':id', '[a-z0-9]{12}'));
  const isOrganizationViewPage = pathname.match(paths.admin.VIEW_ORGANIZATION.replace(':id', '[a-z0-9]{12}'));

  let editPath: any = '';
  const itemId: string = (pathname && pathname.split('/').pop()) || '';
  if (isDocViewPage || isDocActivityPage) {
    editPath = paths.admin.EDIT_DOCUMENT.replace(':id', itemId);
  } else if (isCompanyViewPage) {
    editPath = paths.admin.EDIT_COMPANY.replace(':id', itemId);
  } else if (isTopicViewPage) {
    editPath = paths.admin.EDIT_ISSUE.replace(':id', itemId);
  } else if (isUserViewPage) {
    editPath = paths.admin.EDIT_USER.replace(':id', itemId);
  } else if (isOrganizationViewPage) {
    editPath = paths.admin.EDIT_ORGANIZATION.replace(':id', itemId);
  }

  const docTypeParam = admin.MANAGE_DOCUMENTS.split('/')[3];

  if (!isStaff && !isAdmin && !canManageDirectory) return null;

  return (
    <UncontrolledDropdown nav inNavbar>
      <DropdownToggle nav caret>
        Admin
      </DropdownToggle>
      <DropdownMenu right>
        {isStaff && isDocViewPage && (
          <DropdownItem
            onClick={closeMenu}
            tag={Link}
            to={paths.admin.VIEW_DOCUMENT_ACTIVITY.replace(':id', itemId)}
            title="View usage of current document"
          >
            View this Document's Activity
          </DropdownItem>
        )}
        {isAdmin && (
          <>
            {(isDocViewPage || isDocActivityPage) && (
              <DropdownItem onClick={closeMenu} tag={Link} to={editPath}>
                Edit this Document
              </DropdownItem>
            )}
            {isCompanyViewPage && (
              <DropdownItem onClick={closeMenu} tag={Link} to={editPath}>
                Edit this Company
              </DropdownItem>
            )}
            {isTopicViewPage && (
              <DropdownItem onClick={closeMenu} tag={Link} to={editPath}>
                Edit this Issue
              </DropdownItem>
            )}
            {isUserViewPage && (
              <DropdownItem onClick={closeMenu} tag={Link} to={editPath}>
                Edit this User
              </DropdownItem>
            )}
            {isOrganizationViewPage && (
              <DropdownItem onClick={closeMenu} tag={Link} to={editPath}>
                Edit this Organization
              </DropdownItem>
            )}
            <DropdownItem onClick={closeMenu} tag={Link} to={admin.EDIT_DOCUMENT.replace(':id', 'new')}>
              New Document
            </DropdownItem>
            <DropdownItem divider />
            <DropdownItem onClick={closeMenu} tag={Link} to={admin.MANAGE_DOCUMENTS.replace(docTypeParam, 'draft')}>
              Manage Drafts
            </DropdownItem>
            <DropdownItem onClick={closeMenu} tag={Link} to={admin.MANAGE_DOCUMENTS.replace(docTypeParam, 'article')}>
              Manage Articles
            </DropdownItem>
            <DropdownItem onClick={closeMenu} tag={Link} to={admin.MANAGE_DOCUMENTS.replace(docTypeParam, 'digest')}>
              Manage Digests
            </DropdownItem>
            <DropdownItem onClick={closeMenu} tag={Link} to={admin.MANAGE_DOCUMENTS.replace(docTypeParam, 'source')}>
              Manage Source Documents
            </DropdownItem>
            <DropdownItem onClick={closeMenu} tag={Link} to={admin.MANAGE_DOCUMENTS.replace(docTypeParam, 'transcript')}>
              Manage Transcripts
            </DropdownItem>
            <DropdownItem onClick={closeMenu} tag={Link} to={admin.MANAGE_DOCUMENTS.replace(docTypeParam, 'announcement')}>
              Manage Announcements
            </DropdownItem>
            <DropdownItem onClick={closeMenu} tag={Link} to={admin.MANAGE_DOCUMENTS.split(':')[0]}>
              Manage All Documents
            </DropdownItem>
          </>
        )}
        {isStaff && (
          <>
            {(isDocViewPage || isAdmin) && <DropdownItem divider />}
            <DropdownItem onClick={closeMenu} tag={Link} to={admin.MANAGE_COMPANIES}>
              {manageOrList}Companies
            </DropdownItem>
            <DropdownItem onClick={closeMenu} tag={Link} to={admin.MANAGE_ISSUES}>
              {manageOrList}Issues
            </DropdownItem>
            <DropdownItem onClick={closeMenu} tag={Link} to={admin.MANAGE_ORGANIZATIONS}>
              {manageOrList}Organizations
            </DropdownItem>
            <DropdownItem onClick={closeMenu} tag={Link} to={admin.MANAGE_USERS}>
              {manageOrList}Users
            </DropdownItem>
            {canManageCalendar && (
              <DropdownItem onClick={closeMenu} tag={Link} to={admin.MANAGE_CALENDAR}>
                Manage Calendar
              </DropdownItem>
            )}
          </>
        )}
        {canManageDirectory && (
          <>
            {(isAdmin || isStaff) && <DropdownItem divider />}
            <DropdownItem onClick={closeMenu} tag={Link} to={paths.admin.MANAGE_DIRECTORY}>
              Manage Directory
            </DropdownItem>
          </>
        )}
        {isStaff && (
          <>
            <DropdownItem divider />
            <DropdownItem onClick={closeMenu} tag={Link} to={admin.VIEW_ALL_ACTIVITY}>
              Activity Reports
            </DropdownItem>
            <DropdownItem divider />
            <DropdownItem onClick={closeMenu} tag={Link} to={admin.MANAGE_EMAIL_SUPPRESSIONS}>
              {manageOrList}Email Suppressions
            </DropdownItem>
          </>
        )}
      </DropdownMenu>
    </UncontrolledDropdown>
  );
};

export default AdminMenu;
