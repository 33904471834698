import React from 'react';
import { Link } from 'react-router-dom';
import Loader from 'react-loader';

import {
  allSectorsTableFilterOptions,
  CompanyExchangeTicker,
  CompanySearch,
  Query,
  StockExchange,
  Topic,
} from 'tcf-shared/models';

import { formatVeryShortDate } from '../../../utils/momentFormat';
import { getAuthUser } from '../../../reducers/authReducer';
import { paths } from '../../../paths';
import { useAppSelector } from '../../../utils/hooks';
import ErrorComponent from '../../AsyncPage/ErrorComponent';
import TcfBootstrapSearchTable, {
  getDefaultSorted,
  getPagingParamsForTable,
  TableState,
} from '../../../components/TcfBootstrapSearchTable';

export const DEFAULT_PAGE_SIZE = 25;
export const DEFAULT_SORT_BY = 'name';
export const DEFAULT_SORT_ORDER = 'asc';

interface CompanyListProps {
  manageCompanies?: boolean;
  storeId: string;
  query: Query;
  onQueryChange: (query: Query, debounce?: boolean) => void;
}

const CompanyList = (props: CompanyListProps) => {
  const { manageCompanies, storeId, query, onQueryChange } = props;
  const authUser = useAppSelector((s) => getAuthUser(s));
  const isAdmin = authUser?.isAdmin;
  const isStaff = authUser?.isStaff;
  const isStaffOrAdmin = isStaff || isAdmin;

  const serverStore = useAppSelector((state) => state.serverStores?.[storeId]);
  const payload = serverStore?.payload;
  const error = serverStore?.error;
  const isFetching = serverStore?.isFetching ?? true;
  const isDeleting = serverStore?.isDeleting;

  if (error) return <ErrorComponent error={error} />;
  if (isFetching || isDeleting) return <Loader loaded={false} />;

  const { currentPage, limit, totalSize, sortBy, sortOrder } = getPagingParamsForTable(
    payload,
    query,
    DEFAULT_PAGE_SIZE,
    DEFAULT_SORT_BY,
    DEFAULT_SORT_ORDER,
  );

  const stockExchanges = payload?.stockExchanges || [];
  const micLookup: { [mic: string]: StockExchange } = stockExchanges.reduce((micDict: any, mi: StockExchange) => {
    micDict[mi.mic] = mi;
    return micDict;
  }, {});

  const onTableChange = (type: unknown, newState: TableState) => {
    const { page, sizePerPage, sortField, sortOrder: _sortOrder } = newState;
    const _search: CompanySearch = { ...query?.search };
    const _sortBy = sortField || query?.search?.sortBy || DEFAULT_SORT_BY;
    const _finalSortOrder = _sortOrder || query?.search?.sortOrder || DEFAULT_SORT_ORDER;
    const skip = sizePerPage * (page - 1);
    if (
      query.skip !== skip ||
      query.limit !== sizePerPage ||
      (_sortBy !== _search.sortBy && !(_sortBy === DEFAULT_SORT_BY && _search.sortBy == null)) ||
      (_finalSortOrder !== _search.sortOrder && !(_finalSortOrder === DEFAULT_SORT_ORDER && _search.sortOrder == null))
    ) {
      _search.sortBy = _sortBy;
      _search.sortOrder = _finalSortOrder;
      onQueryChange({ ...query, skip, limit: sizePerPage, search: _search });
    }
  };

  const actionsFormatter = (cell: string) => {
    return (
      <Link to={paths.admin.EDIT_COMPANY.replace(':id', cell)} title={'Edit Company'}>
        Edit
      </Link>
    );
  };

  const formatName = (cell: string, row: any) => (
    <Link to={paths.VIEW_COMPANY.replace(':id', row.id)} title={'View Company'}>
      {cell}
    </Link>
  );

  const formatAcquiredBy = (cell: string, row: any) =>
    cell ? (
      <Link to={paths.VIEW_COMPANY.replace(':id', row.acquiredBy)} title={'View Acquirer Company'}>
        {row.acquiredByName || row.acquiredBy || ''}
      </Link>
    ) : null;

  const formatSectors = (cell: { code: string }[]) =>
    cell?.length ? (
      <small>
        {cell
          .map((cx) => allSectorsTableFilterOptions.find((c) => c.value === cx.code)?.label || '')
          .filter(Boolean)
          .sort()
          .join('; ')}
      </small>
    ) : null;

  const formatExchangeTickers = (cell: CompanyExchangeTicker[]) => {
    const exchangeTickers = cell || [];
    const primaryTickers = exchangeTickers.filter((et: CompanyExchangeTicker) => et.status === 'primary') || null;
    const alternateTickers = exchangeTickers.filter((et: CompanyExchangeTicker) => et.status === 'alternate') || null;
    const delistedTickers = exchangeTickers.filter((et: CompanyExchangeTicker) => et.status === 'delisted') || null;

    const getTickerLabels = (tickers: CompanyExchangeTicker[], areDelisted: boolean) => {
      return (
        tickers?.map((et: CompanyExchangeTicker) => {
          const mic = et?.exchange;
          const stockExchange = mic ? micLookup[mic] : null;
          const isUSMarket = stockExchange?.country === 'USA';
          const exchangeLabel = isUSMarket ? '' : stockExchange?.prefix || mic || '';
          return (
            <div key={`${exchangeLabel ? exchangeLabel : ''}${et.symbol}`}>
              {`${exchangeLabel ? exchangeLabel + ': ' : ''}${et.symbol}${areDelisted ? ' (delisted)' : ''}`}
            </div>
          );
        }) || null
      );
    };

    const primaryTickerLabels = getTickerLabels(primaryTickers, false);
    const alternateTickersLabels = getTickerLabels(alternateTickers, false);
    const delistedTickersLabels = getTickerLabels(delistedTickers, true);

    return (
      <small>
        {primaryTickerLabels}
        {alternateTickersLabels}
        {delistedTickersLabels}
      </small>
    );
  };

  const formatAliases = (cell: string[]) =>
    cell && cell.length > 0 ? (
      <small>
        {cell.map((c) => (
          <div key={c}>{c}</div>
        ))}
      </small>
    ) : null;

  const formatCreatedAt = (cell: any, row: Topic) => formatVeryShortDate(row.meta.createdAt);
  const formatUpdatedAt = (cell: any, row: Topic) => formatVeryShortDate(row.meta.updatedAt);

  const columns = [
    {
      dataField: 'id',
      text: 'Actions',
      formatter: actionsFormatter,
      headerStyle: { width: '7%' },
      align: 'left',
      headerAlign: 'left',
      hidden: !manageCompanies || !isAdmin,
    },
    {
      dataField: 'name',
      text: 'Company',
      formatter: formatName,
      sort: true,
      headerStyle: { width: '15%' },
    },
    {
      dataField: 'exchangeTickers',
      text: 'Ticker',
      formatter: formatExchangeTickers,
      sort: true,
      headerStyle: { width: '8%' },
    },
    {
      dataField: 'aliases',
      text: 'Aliases',
      formatter: formatAliases,
      headerStyle: { width: '12%' },
      sort: true,
    },
    {
      dataField: 'acquiredBy',
      text: 'Acquired By',
      formatter: formatAcquiredBy,
      headerStyle: { width: '12%' },
    },
    {
      dataField: 'sectors',
      text: 'Sectors',
      formatter: formatSectors,
      headerStyle: { width: '15%' },
      sort: manageCompanies,
    },
    {
      dataField: 'createdAt',
      text: 'Added',
      formatter: formatCreatedAt,
      align: 'right',
      headerAlign: 'right',
      headerStyle: { width: '9%' },
      sort: true,
      hidden: !manageCompanies || !isStaffOrAdmin,
    },
    {
      dataField: 'updatedAt',
      text: 'Updated',
      formatter: formatUpdatedAt,
      align: 'right',
      headerAlign: 'right',
      sort: true,
      headerStyle: { width: '13%' },
      hidden: !manageCompanies || !isStaffOrAdmin,
    },
  ];

  return !payload?.total ? (
    <p>No matching companies found</p>
  ) : (
    <div style={{ minWidth: '720px' }}>
      <div>{totalSize?.toLocaleString() || 0} Companies Found</div>
      <TcfBootstrapSearchTable
        allowPaging
        allowWrap
        columns={columns}
        dataSource={payload?.results || []}
        defaultSorted={getDefaultSorted(sortBy, sortOrder)}
        keyField="id"
        onTableChange={onTableChange}
        page={currentPage}
        remote
        sizePerPage={limit}
        totalSize={totalSize}
      />
    </div>
  );
};

export default CompanyList;
