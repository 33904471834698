import { useState } from 'react';

interface PopupTogglerProps<T> {
  data?: T; // If you want to store some value along with the open/close state.
  isOpen?: boolean;
}

interface PopupTogglerState<T> {
  data?: T;
  isOpen: boolean;
}

export interface PopupTogglerReturnValue<T> {
  isOpen: boolean;
  data?: T;
  open: (data?: T) => void;
  close: () => void;
  toggle: (data?: T) => void;
}

export const usePopupToggler = <T = unknown>(initialValues?: PopupTogglerProps<T>): PopupTogglerReturnValue<T> => {
  const [state, setState] = useState<PopupTogglerState<T>>({
    data: initialValues?.data,
    isOpen: initialValues?.isOpen ?? false,
  });

  const open = (data?: T) => setState({ data, isOpen: true });
  const close = () => setState({ data: undefined, isOpen: false });
  const toggle = (data?: T) => setState((s) => (s.isOpen ? { isOpen: false } : { data, isOpen: true }));

  return { isOpen: state.isOpen, data: state.data, open, close, toggle };
};
