import React from 'react';
import { Link } from 'react-router-dom';
import { DropdownItem, DropdownMenu, DropdownToggle, UncontrolledDropdown } from 'reactstrap';

import { AuthUser } from 'tcf-shared/models';

import { paths } from '../../paths';

const AccountMenu = ({ authUser, closeMenu }: { authUser?: AuthUser; closeMenu: () => void }) => {
  const { email, isPremiumSubscriber, canManageOrganization } = authUser || {};

  if (!isPremiumSubscriber && !canManageOrganization) return null;

  return (
    <UncontrolledDropdown nav inNavbar>
      <DropdownToggle nav caret>
        Account
      </DropdownToggle>
      <DropdownMenu right>
        <DropdownItem onClick={closeMenu} disabled>
          {email || 'user@example.com'}
        </DropdownItem>
        <DropdownItem divider />
        {isPremiumSubscriber && (
          <>
            <DropdownItem onClick={closeMenu} tag={Link} title="View email alert settings" to={paths.VIEW_ACCOUNT}>
              Email Alerts
            </DropdownItem>
            <DropdownItem onClick={closeMenu} tag={Link} title="View subscription details" to={paths.VIEW_SUBSCRIPTION}>
              Subscription Details
            </DropdownItem>
            <DropdownItem divider />
          </>
        )}
        {canManageOrganization && (
          <>
            <DropdownItem
              onClick={closeMenu}
              tag={Link}
              title={"Manage your organization's users"}
              to={paths.SELF_MANAGE_ORGANIZATION}
            >
              Manage Your Organization's Users
            </DropdownItem>
            <DropdownItem divider />
          </>
        )}
        <DropdownItem onClick={closeMenu} tag={Link} to={paths.LOGOUT}>
          Logout
        </DropdownItem>
      </DropdownMenu>
    </UncontrolledDropdown>
  );
};

export default AccountMenu;
