import React from 'react';
import { Link } from 'react-router-dom';
import { Card, CardBody, CardHeader } from 'reactstrap';

import { paths } from '../../paths';

interface DocumentListProps {
  documents: { id: string; title: string }[];
  title: string;
}

const DocumentList = (props: DocumentListProps) => {
  const { documents, title } = props;

  return documents.length > 0 ? (
    <Card className={'mb-3'}>
      <CardHeader>{title}</CardHeader>
      <CardBody className={'pb-2'}>
        <small>
          <ul className={'pl-3'}>
            {documents.map((d) => (
              <li key={d.id} className={'mb-2'}>
                <Link to={paths.VIEW_DOCUMENT.replace(':id', d.id)}>{d.title}</Link>
              </li>
            ))}
          </ul>
        </small>
      </CardBody>
    </Card>
  ) : null;
};

export default DocumentList;
