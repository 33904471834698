// Actions for the currently logged in user.

import { NOOP, READ_SERVER, SAVE_SERVER } from './actionTypes';
import { asyncAction } from './asyncAction';
import { library } from '../libraryApi';

export function getAccountManager(storeIdentifier: string) {
  return asyncAction({
    actionType: READ_SERVER,
    func: async () => (await library.user.getAccountManager()).data,
    storeIdentifier,
  });
}

export function sendSupportMessage(message: string) {
  return asyncAction({
    actionType: NOOP,
    func: async () => (await library.user.sendSupportMessage(message)).data,
  });
}

export function getNotificationsDate(storeIdentifier: string) {
  return asyncAction({
    actionType: READ_SERVER,
    func: async () => {
      let data = (await library.user.getNotificationsDate()).data;
      if (typeof data === 'object') {
        data = { ...data, checkedAt: new Date().toISOString() };
      }
      return data;
    },
    storeIdentifier,
  });
}

export function setNotificationsDate(storeIdentifier: string, mostRecentlyViewedNotificationPageDate: Date) {
  return asyncAction({
    actionType: SAVE_SERVER,
    func: async () => {
      let data = (await library.user.setNotificationsDate(mostRecentlyViewedNotificationPageDate)).data;
      if (typeof data === 'object') {
        data = { ...data, checkedAt: new Date().toISOString() };
      }
      return data;
    },
    storeIdentifier,
  });
}
