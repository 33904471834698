import React, { useCallback, useEffect, useRef, useState } from 'react';
import { pdfjs, Document as PdfDocument } from 'react-pdf/dist/esm/entry.webpack';
import { TextLayerItemInternal } from 'react-pdf/dist/Page';

import { Document } from 'tcf-shared/models';

import { DocumentRelated } from '../../../reducers/documentsReducer';
import { DownloadDialog } from './DownloadDialog';
import DocumentPage from './DocumentPage';
import DocumentToolbar from './DocumentToolbar';

// TOooDOooo: copy this locally, so we don't have the external dependency.
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

const TOOLBAR_HEIGHT = 50;
const DESKTOP_PADDING = 31;
const TEXT_MATCH_OFFSET_Y = 70;
const MAX_PAGES = 20;
const FOOTER_TEXT_1 = 'The Capitol Forum. Direct or indirect reproduction or distribution of this article';
const FOOTER_TEXT_2 = 'Capitol Forum is a violation of Federal Copyright';

export interface PdfViewerProps {
  offsetX: number;
  isPdf: boolean;
  enableDownload: boolean;
  pdfFile: { url: string; httpHeaders: { Authorization: string | undefined } } | undefined;
  email: string;
  document: Document;
  documentRelated?: DocumentRelated;
  windowWidth: number;
  containerWidth: number;
  headerHeight: number;
  mobileBreakpoint: number;
}

const DocumentViewer = (props: PdfViewerProps) => {
  const {
    offsetX,
    isPdf,
    enableDownload,
    pdfFile,
    email,
    document,
    documentRelated,
    windowWidth,
    containerWidth,
    headerHeight,
    mobileBreakpoint,
  } = props;
  const keywordMatchOffsetY = headerHeight + TOOLBAR_HEIGHT + TEXT_MATCH_OFFSET_Y;
  const [pdfDetails, setPdfDetails] = useState({ pageWidth: 1, numPages: 1 });
  const [startPage, setStartPage] = useState<number>(1);
  const [currentPage, setCurrentPage] = useState(1);
  const [zoom, setZoom] = useState<number>(10);
  const [keywords, setKeywords] = useState('');
  const [keywordMatches, setKeywordMatches] = useState<Element[]>([]);
  const [currentMatchIndex, setCurrentMatchIndex] = useState<number>(0);
  const [showDownloadDialog, setShowDownloadDialog] = useState(false);

  const pdfPagesRef = useRef<any>(new Map());

  const pdfContainerWidth = containerWidth - DESKTOP_PADDING;
  const numPages = pdfDetails.numPages;
  const searchResultsCount = keywordMatches.length;

  const handleLoadSuccess = useCallback(async (pdf: any) => {
    const page = await pdf.getPage(1);
    const pageWidth = page.view[2];
    // Getting the text strings from pageText objects (items) may allow more complete searching, but:
    // A) words may be broken up into multiple objects
    // B) spaces are objects too
    // C) if you join all the object strings with .join(' '), you get spaces inside of words and double-spaces between most.
    // D) joining all the object strings with .join(''), you get some words merged together.
    // E) if you search the joined text, you can find more matches but how would you locate or highlight the text in the browser?
    // const pageText = await page.getTextContent();
    // const pageTextItems = pageText.items;
    setPdfDetails({ pageWidth, numPages: pdf.numPages });
    setZoom(10);
  }, []);

  const handleStartPageChanged = useCallback((newStartPage: number) => {
    window.scrollTo(0, 0);
    setKeywordMatches([]);
    setCurrentMatchIndex(0);
    setStartPage(newStartPage);
    setCurrentPage(newStartPage);
  }, []);

  const handleCurrentPageChanged = useCallback(
    (newCurrentPage: number) => {
      let y: number = 0;
      if (newCurrentPage > startPage) {
        const element = pdfPagesRef.current.get(newCurrentPage)?.element;
        if (element) {
          y = element.getBoundingClientRect().top + window.scrollY - (headerHeight + TOOLBAR_HEIGHT + 20);
        }
      }
      window.scrollTo(0, y);
      setCurrentPage(newCurrentPage);
    },
    [startPage, headerHeight],
  );

  const handleScrollEvent = useCallback(() => {
    // Find first visible page and use that as current page.
    if (!pdfPagesRef.current) return;

    const pdfPages = [...pdfPagesRef.current.values()];
    let page = pdfPages.find((pageObj: any) => pageObj.intersectionRatio > 0.3);
    if (!page) {
      page = pdfPages.find((pageObj: any) => pageObj.intersectionRatio > 0.1);
      if (!page) {
        page = pdfPages.find((pageObj: any) => pageObj.intersectionRatio > 0);
      }
    }
    if (page) {
      setCurrentPage(page.pageNumber);
    }
  }, []);

  const handleMatchIndexChanged = useCallback(
    (newIndex: number) => {
      if (newIndex > 0) {
        let y = 0;
        const element = keywordMatches[newIndex - 1];
        if (element) {
          y = element.getBoundingClientRect().top + window.scrollY - keywordMatchOffsetY;
        }
        window.scrollTo(0, y);
        handleScrollEvent();
      }
      setCurrentMatchIndex(newIndex);
    },
    [keywordMatches, keywordMatchOffsetY, handleScrollEvent],
  );

  const handleZoom = useCallback((nextZoom: number) => {
    setKeywordMatches([]);
    setZoom(nextZoom);
  }, []);

  const handleKeywordsChanged = useCallback((newKeywords: string) => {
    setKeywordMatches([]);
    setCurrentMatchIndex(0);
    setKeywords(newKeywords);
  }, []);

  const setMatchRef = useCallback((element: any) => {
    if (element) {
      // We seem to get match refs in random order, so sort them by y location so that index 0 is first match.
      setKeywordMatches((p) =>
        [...p, element].sort((a: Element, b: Element) => a.getBoundingClientRect().top - b.getBoundingClientRect().top),
      );
    }
  }, []);

  const textRenderer = useCallback(
    (textItem: TextLayerItemInternal) => {
      // Go to some trouble to avoid adding anything to text layer that isn't a keyword match, to prevent content copying
      if (!keywords?.trim().length) {
        return null as unknown as JSX.Element;
      }

      const text = textItem.str;

      // Try not to highlight user email address watermark or page footer text
      if (text === email || text.startsWith(FOOTER_TEXT_1) || text.startsWith(FOOTER_TEXT_2)) {
        return null as unknown as JSX.Element;
      }

      const regEscape = (v: string) => v.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, '\\$&');
      const regExp = new RegExp(regEscape(keywords), 'ig');

      const splitText = text.split(regExp);
      const matches = text.match(regExp);
      if (!matches) {
        return null as unknown as JSX.Element;
      }

      const results = splitText.reduce((arr: any[], element: any, index: number) => {
        // We need to include non-matched text elements to get the match text positioned close to correct place
        if (matches?.[index]) {
          return [
            ...arr,
            element,
            <mark key={index} ref={setMatchRef}>
              {matches[index]}
            </mark>,
          ];
        } else {
          return [...arr, element];
        }
      }, []);
      return results as unknown as JSX.Element;
    },
    [keywords, email, setMatchRef],
  );

  useEffect(() => {
    // Document has changed between desktop and mobile.  Reset most state vars to initial vals.
    handleStartPageChanged(1);
  }, [pdfFile, handleStartPageChanged]);

  useEffect(() => {
    if (keywordMatches.length) {
      setCurrentMatchIndex(1);
    }
  }, [keywordMatches]);

  useEffect(() => {
    if (currentMatchIndex > 0) {
      let y = 0;
      const element = keywordMatches[currentMatchIndex - 1];
      if (element) {
        y = element.getBoundingClientRect().top + window.scrollY - keywordMatchOffsetY;
      }
      window.scrollTo(0, y);
      handleScrollEvent();
    }
  }, [currentMatchIndex, keywordMatches, keywordMatchOffsetY, handleScrollEvent]);

  const scale =
    pdfDetails.pageWidth > 1 && (windowWidth < mobileBreakpoint || zoom * pdfDetails.pageWidth > pdfContainerWidth)
      ? Math.floor((pdfContainerWidth * 100) / pdfDetails.pageWidth) / 100
      : zoom;

  const toggleDownloadDialog = () => setShowDownloadDialog(!showDownloadDialog);

  return (
    <>
      <div
        className="position-sticky overflow-hidden"
        style={{ top: headerHeight, left: 0, right: 0, height: TOOLBAR_HEIGHT, zIndex: 3 }}
      >
        <DocumentToolbar
          isPdf={isPdf}
          toolbarTop={headerHeight}
          toolbarHeight={TOOLBAR_HEIGHT}
          searchBarOffsetX={offsetX + 30}
          windowWidth={windowWidth}
          containerWidth={containerWidth}
          pdfPageWidth={pdfDetails.pageWidth}
          zoom={scale}
          numPages={numPages}
          startPage={startPage}
          currentPage={currentPage}
          currentMatchIndex={currentMatchIndex}
          totalKeywordMatches={searchResultsCount}
          downloadEnabled={enableDownload}
          keywords={keywords}
          onDownload={setShowDownloadDialog}
          onZoomChanged={handleZoom}
          onStartPageChanged={handleStartPageChanged}
          onKeywordsChanged={handleKeywordsChanged}
          onMatchIndexChanged={handleMatchIndexChanged}
          onCurrentPageChanged={handleCurrentPageChanged}
        />
      </div>
      <div
        id="pdf-container"
        className={'d-flex justify-content-center overflow-hidden pt-3 bg-secondary'}
        style={{ userSelect: 'none' }}
        onContextMenu={(evt: any) => evt.preventDefault()}
      >
        <PdfDocument
          file={pdfFile}
          onLoadSuccess={handleLoadSuccess}
          externalLinkTarget={'_blank'}
          loading={
            <div className="bg-light text-center" style={{ width: pdfContainerWidth, height: '100vh', paddingTop: 100 }}>
              <div className="spinner-border text-muted" style={{ marginLeft: 'auto', marginRight: 'auto' }}>
                &nbsp;
              </div>
              <div style={{ marginLeft: 'auto', marginRight: 'auto' }}>Loading document...</div>
            </div>
          }
        >
          {Array.from(new Array(Math.max(0, Math.min(MAX_PAGES, numPages - (startPage - 1))))).map((_item, index) => {
            const _pageNumber = startPage + index;
            return (
              <div key={`frag-${startPage + index}`} onContextMenu={(evt: any) => evt.preventDefault()}>
                {index > 0 && (
                  <div className={'text-center ' + (index ? 'mt-3' : '')}>
                    Page {startPage + index} of {numPages}
                  </div>
                )}

                <DocumentPage
                  key={`${keywords || 'page'}-${_pageNumber}`}
                  pageNumber={_pageNumber}
                  scale={scale || 1}
                  renderTextLayer={true}
                  customTextRenderer={textRenderer}
                  pagesMap={pdfPagesRef.current}
                  // keywords={keywords}
                  onScrollEvent={handleScrollEvent}
                />
              </div>
            );
          })}
        </PdfDocument>
        {!isPdf && <div>Click the download link above to view the file.</div>}
      </div>
      {showDownloadDialog && (
        <DownloadDialog
          isOpen={showDownloadDialog}
          toggle={toggleDownloadDialog}
          document={document}
          documentRelated={documentRelated}
        />
      )}
    </>
  );
};

export default DocumentViewer;
