import { AxiosInstance } from 'axios';

import { ApiResponse } from 'tcf-shared/models';

export class StartupApiClient {
  constructor(readonly axios: AxiosInstance) {}

  async startup(): Promise<ApiResponse> {
    return this.axios.get<ApiResponse>('/client/startup');
  }
}
