// Actions for the currently logged in user.

import { AxiosInstance } from 'axios';
import { ApiResponse } from 'tcf-shared/models';
import { CrudApiClient } from './CrudApiClient';

export class UserApiClient extends CrudApiClient {
  constructor(readonly axios: AxiosInstance) {
    super(axios, '/user');
  }

  async getAccountManager(): Promise<ApiResponse> {
    return this.axios.get<ApiResponse>(`${this.baseUrl}/account-manager`);
  }

  async sendSupportMessage(message: string): Promise<ApiResponse> {
    return this.axios.post<ApiResponse>(`${this.baseUrl}/support-message`, { message });
  }

  async getNotificationsDate(): Promise<ApiResponse> {
    return this.axios.get<ApiResponse>(`${this.baseUrl}/notifications-date`);
  }

  async setNotificationsDate(mostRecentlyViewedNotificationPageDate: Date): Promise<ApiResponse> {
    return this.axios.put<ApiResponse>(`${this.baseUrl}/notifications-date`, { mostRecentlyViewedNotificationPageDate });
  }
}
