import React, { useCallback, useMemo, useState } from 'react';
import { Calendar, Views } from 'react-big-calendar';
import moment from 'moment-timezone';

import { TimeTypeLabels } from 'tcf-shared/models';

import {
  Event,
  InitialRangeChangeToolbar,
  overrideMessages,
  standardizeDateRange,
  titleAccessor,
  weekRangeFormat,
} from './utils';

import 'react-big-calendar/lib/css/react-big-calendar.css';

const CALENDAR_VIEWS = [Views.MONTH, Views.WEEK, Views.DAY, Views.AGENDA];

const START_HOUR = 8;
const END_HOUR = 18;

interface OwnProps {
  events: Event[];
  browserTimezone: string;
  view: string;
  date: Date;
  openPopup: (data?: Event) => void;
  localizer: any;
  onDateChange: (newDate: Date) => void;
  onViewChange: (newView: string, newDate?: Date) => void;
}

const EventCalendar = (props: OwnProps) => {
  const { events, browserTimezone, view, date, openPopup, localizer, onDateChange, onViewChange } = props;

  const [startRange, setStartRange] = useState(date);
  const [endRange, setEndRange] = useState(date);

  const minMaxTime = useMemo(() => {
    const hours = events
      .filter((e) => e.start <= endRange && e.end >= startRange)
      .reduce(
        (a, c) => ({
          startHour: Math.min(a.startHour, c.start.getHours()),
          endHour: Math.max(a.endHour, c.end.getHours()),
        }),
        { startHour: START_HOUR, endHour: END_HOUR },
      );
    return { min: new Date(1972, 0, 1, hours.startHour), max: new Date(1972, 0, 1, hours.endHour) };
  }, [startRange, endRange, events]);

  const handleRangeChange = (range: Date[] | { start: Date; end: Date }) => {
    const _range = standardizeDateRange(range);
    setStartRange(_range.start);
    setEndRange(_range.end);
  };

  const timeGutterFormat = useCallback((dt: any) => moment.tz(dt, browserTimezone).format('LT z'), [browserTimezone]);

  const eventTimeRangeFormat = useCallback(
    (event: Event) => moment.tz(event.start, browserTimezone).format('LT z'),
    [browserTimezone],
  );

  const CALENDAR_FORMATS = {
    dateFormat: 'D',
    dayFormat: 'D ddd',
    dayHeaderFormat: 'dddd MMM D',
    agendaDateFormat: 'ddd MMM D',
    dayRangeHeaderFormat: weekRangeFormat,
    eventTimeRangeFormat,
    agendaTimeRangeFormat: eventTimeRangeFormat,
    timeGutterFormat,
  };

  const handleDrillDown = (newDate: Date) => {
    if (view !== Views.AGENDA) onViewChange(Views.AGENDA, newDate);
  };

  const onSelectEvent = useCallback(
    (selectedEvent: Event) => {
      if ([Views.MONTH, Views.WEEK, Views.WORK_WEEK, Views.DAY].indexOf(view) > -1) {
        openPopup(selectedEvent);
      }
    },
    [openPopup, view],
  );

  const components = {
    agenda: {
      event: ({ event }: { event: Event }) => {
        const description = event.description || event.title || 'No description';
        return <div key="description" dangerouslySetInnerHTML={{ __html: description }} />;
      },
      time: ({ event }: { event: Event }) => {
        if (event.allDay) return TimeTypeLabels[event.timeType];
        return moment.tz(event.start, browserTimezone).format('LT z');
      },
    },
    toolbar: InitialRangeChangeToolbar,
  };

  return (
    <Calendar
      events={events}
      date={date}
      view={view}
      step={30}
      titleAccessor={titleAccessor}
      messages={overrideMessages}
      localizer={localizer}
      min={minMaxTime.min}
      max={minMaxTime.max}
      formats={CALENDAR_FORMATS}
      views={CALENDAR_VIEWS}
      onDrillDown={handleDrillDown}
      onNavigate={onDateChange}
      onSelectEvent={onSelectEvent}
      onRangeChange={handleRangeChange}
      onView={onViewChange}
      components={components}
      popup
    />
  );
};

export default EventCalendar;
