import { AxiosInstance } from 'axios';

import { ApiResponse, Query } from 'tcf-shared/models';
import { CrudApiClient } from './CrudApiClient';

export class EmailServiceApiClient extends CrudApiClient {
  constructor(readonly axios: AxiosInstance) {
    super(axios, '/email-service');
  }

  async readSuppressions(body: any): Promise<ApiResponse> {
    return this.axios.post<ApiResponse>(`${this.baseUrl}/suppressions/search`, body);
  }

  async searchEvents(query: Query): Promise<ApiResponse> {
    return this.axios.post<ApiResponse>(`${this.baseUrl}/events/search`, query);
  }
}
