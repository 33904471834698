import React from 'react';
import { Field as RFField } from 'react-final-form';
import { FormFeedback, FormGroup, Input, Label } from 'reactstrap';

const TcfSelect = (props: any) => (
  <RFField name={props.name} className={props.className} options={props.options}>
    {(fieldProps) => {
      const hasError = fieldProps.meta.touched && fieldProps.meta.error;
      return (
        <FormGroup color={hasError ? 'danger' : ''} className={props.formGroupClass}>
          {props.label ? (
            <Label for={fieldProps.input.name} className="mr-1">
              {props.label}
            </Label>
          ) : null}
          <Input
            {...fieldProps.input}
            className={fieldProps.className}
            disabled={fieldProps.disabled}
            name={fieldProps.input.name}
            type="select"
            value={fieldProps.input.value}
          >
            {fieldProps.options.map((option: any) => (
              <option key={'KEY' + option.value} value={option.value}>
                {option.label}
              </option>
            ))}
          </Input>
          {hasError && <FormFeedback className="d-block">{fieldProps.meta.error}</FormFeedback>}
        </FormGroup>
      );
    }}
  </RFField>
);

export default TcfSelect;
