import React from 'react';
import Loader from 'react-loader';

import { Query } from 'tcf-shared/models';

import { useAppSelector } from '../../../utils/hooks';
import ErrorComponent from '../../AsyncPage/ErrorComponent';
import DocumentListPaginator from './DocumentListPaginator';
import { Link } from 'react-router-dom';
import { paths } from '../../../paths';
import { formatLongDate } from '../../../utils/momentFormat';

interface DocumentListProps {
  queryId: string;
  storeId: string;
  onQueryChange: any;
}

const DocumentList = (props: DocumentListProps) => {
  const { queryId, storeId, onQueryChange } = props;
  const query: Query = useAppSelector((s) => s.queries[queryId]);
  const documentStore = useAppSelector((s) => s.serverStores?.[storeId]);
  const error: string = documentStore?.error;
  const isFetching: boolean = documentStore?.isFetching;
  const payload = documentStore?.payload;
  const results = payload?.results || [];
  const itemCount = payload?.total ?? 0;

  const paginator = (
    <div>
      <div className="mb-1">
        <DocumentListPaginator query={query} itemCount={itemCount} onPageChange={onQueryChange} />
      </div>
    </div>
  );

  if (isFetching) {
    return <Loader loaded={false} />;
  } else if (error) {
    return <ErrorComponent error={error} />;
  }

  return (
    <div>
      {itemCount > 10 && paginator}
      {results.length === 0 ? (
        <div>No matching documents found.</div>
      ) : (
        <ul className={'pl-4'}>
          {results.map((document: any) => (
            <li key={document.id}>
              <div className="mb-1">
                <Link to={paths.VIEW_DOCUMENT.replace(':id', document.id!)} title={'Read entire article'}>
                  {document.title}
                </Link>
                <div>
                  <small>
                    {document.meta.publishing?.published
                      ? formatLongDate(document.meta.publishing.displayPublishedAt)
                      : 'Draft'}
                  </small>
                </div>
              </div>
            </li>
          ))}
        </ul>
      )}
      {itemCount > 10 && paginator}
    </div>
  );
};

export default DocumentList;
