import React, { CSSProperties } from 'react';

import { DirectoryEvidence } from 'tcf-shared/models';

import { Modal, ModalBody, ModalHeader, Table } from 'reactstrap';
import { useAppSelector } from '../../../utils/hooks';
import DownloadLink from './DownloadLink';

const leftStyle: CSSProperties = { whiteSpace: 'nowrap', paddingRight: '1rem' };
const rightStyle: CSSProperties = { width: '90%' };

interface OwnProps {
  entityStoreId: string;
  directoryEvidence: DirectoryEvidence;
  handleCloseModal: () => void;
}

const formatDates = (e: DirectoryEvidence) => {
  try {
    if (e.fromDate && e.toDate) return <div>{e.fromDate + ' - ' + e.toDate}</div>;
    if (e.fromDate) return <div>{e.fromDate}</div>;
    if (e.toDate) return <div>{e.toDate}</div>;
  } catch {
    // Swallow errors.
  }
  return null;
};

const EvidenceViewModal = (props: OwnProps) => {
  const { entityStoreId, directoryEvidence, handleCloseModal } = props;

  const directoryEntityName = useAppSelector((state) => state?.serverStores?.[entityStoreId]?.payload?.name);

  return (
    <Modal backdrop="static" isOpen={true} toggle={handleCloseModal} className="modal-lg">
      <ModalHeader
        tag="span"
        className="bg-dark"
        style={{ border: '2px solid #555', color: 'white' }}
        close={
          <span
            className="close clickable"
            onClick={handleCloseModal}
            style={{ color: '#FFFFFF', opacity: '.9', textShadow: 'none' }}
          >
            x
          </span>
        }
        toggle={handleCloseModal}
      >
        View Evidence{directoryEntityName ? ` for ${directoryEntityName}` : ''}
      </ModalHeader>
      <ModalBody className="m-2">
        <Table>
          <tbody>
            {directoryEvidence?.type?.title ? (
              <tr>
                <td style={leftStyle}>Type</td>
                <td style={rightStyle}>{directoryEvidence.type.title}</td>
              </tr>
            ) : null}
            {directoryEvidence?.affinity ? (
              <tr>
                <td style={leftStyle}>Affinity</td>
                <td style={rightStyle}>{directoryEvidence.affinity}</td>
              </tr>
            ) : null}
            {directoryEvidence?.tags?.length ? (
              <tr>
                <td style={leftStyle}>Tags</td>
                <td style={rightStyle}>{directoryEvidence.tags.map((t) => t.tag).join(', ')}</td>
              </tr>
            ) : null}
            {directoryEvidence?.url ? (
              <tr>
                <td style={leftStyle}>URL</td>
                <td style={rightStyle}>
                  <a href={directoryEvidence.url} target="_blank" rel="noopener noreferrer">
                    {directoryEvidence.url}
                  </a>
                </td>
              </tr>
            ) : null}
            {directoryEvidence?.originalFileName ? (
              <tr>
                <td style={leftStyle}>File</td>
                <td style={rightStyle}>
                  <DownloadLink directoryEvidence={directoryEvidence}>Download file</DownloadLink>
                </td>
              </tr>
            ) : null}
            {directoryEvidence?.title ? (
              <tr>
                <td style={leftStyle}>Title</td>
                <td style={rightStyle}>{directoryEvidence.title}</td>
              </tr>
            ) : null}
            {directoryEvidence?.description ? (
              <tr>
                <td style={leftStyle}>Title</td>
                <td style={rightStyle}>{directoryEvidence.description}</td>
              </tr>
            ) : null}
            {directoryEvidence?.effectiveDate ? (
              <tr>
                <td style={leftStyle}>Date</td>
                <td style={rightStyle}>{directoryEvidence.effectiveDate}</td>
              </tr>
            ) : null}
            {directoryEvidence?.fromDate || directoryEvidence?.toDate ? (
              <tr>
                <td style={leftStyle}>Date Range</td>
                <td style={rightStyle}>{formatDates(directoryEvidence)}</td>
              </tr>
            ) : null}
            {directoryEvidence?.entities?.length ? (
              <tr>
                <td style={leftStyle}>Entities</td>
                <td style={rightStyle}>
                  <pre>{directoryEvidence.entities.map((e) => e.name).join('\n')}</pre>
                </td>
              </tr>
            ) : null}
          </tbody>
        </Table>
      </ModalBody>
    </Modal>
  );
};

export default EvidenceViewModal;
