import { AxiosInstance } from 'axios';

import { ApiResponse, Query } from 'tcf-shared/models';

export class StockExchangesApiClient {
  constructor(readonly axios: AxiosInstance, readonly baseUrl: string) {}

  async search(query: Query): Promise<ApiResponse> {
    return this.axios.post<ApiResponse>(`${this.baseUrl}/search`, query);
  }
}
