import React, { useState, useEffect, useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { TbLayoutSidebarLeftExpand, TbLayoutSidebarRightExpand } from 'react-icons/tb';

import { AuthUser, Document } from 'tcf-shared/models';

import { useWindowSizeTracker } from '../../../utils/hooks/useWindowSizeTracker';
import { DocumentRelated } from '../../../reducers/documentsReducer';
import AsyncPage from '../../AsyncPage/AsyncPage';
import ErrorComponent from '../../AsyncPage/ErrorComponent';
import DocumentViewer from './DocumentViewer';
import DocumentTags from './DocumentTags';
import { Button } from 'reactstrap';

const MOBILE_BREAKPOINT = 768;
const MIN_DISPLAY_WIDTH = 300;
const SIDEBAR_MAX_WIDTH = 300;
const SIDEBAR_MIN_WIDTH = 60;

const preventCopy = (evt: Event) => evt.preventDefault();

export interface ViewDocumentInnerProps {
  headerHeight: number;
  accessToken?: string | null;
  authUser?: AuthUser;
  dateFormat: string;
  document: Document;
  documentRelated?: DocumentRelated;
  error: string;
  isFetching: boolean;
  documentRelatedStoreId: string;
  propsGetDocument: (id: string) => Promise<any>;
  propsGetDocumentRelated: (storeIdentifier: string, id: string) => Promise<any>;
  propsResetDocumentStore: () => void;
  propsResetServerStore: (serverStoreId: string) => void;
}

const ViewDocumentInner = (props: ViewDocumentInnerProps) => {
  const { id: urlId } = useParams<{ id: string }>();
  const {
    headerHeight,
    accessToken,
    authUser,
    document,
    documentRelated,
    error,
    isFetching,
    documentRelatedStoreId,
    propsGetDocument,
    propsGetDocumentRelated,
    propsResetDocumentStore,
    propsResetServerStore,
  } = props;
  const email = authUser?.email || '';
  const { windowWidth } = useWindowSizeTracker();
  const [showSidebar, setShowSidebar] = useState<boolean>(true);

  const filename = document && (document.file ? document.file.originalName : document.pdf);
  const isPdf = filename ? filename.toLowerCase().endsWith('.pdf') : false;
  const requestMobileFile = windowWidth < MOBILE_BREAKPOINT;
  const documentContainerWidth =
    windowWidth <= MIN_DISPLAY_WIDTH
      ? MIN_DISPLAY_WIDTH
      : windowWidth < MOBILE_BREAKPOINT
      ? windowWidth
      : windowWidth - (showSidebar ? SIDEBAR_MAX_WIDTH : SIDEBAR_MIN_WIDTH);

  useEffect(() => {
    window.document.addEventListener('copy', preventCopy);
    return () => {
      window.document.removeEventListener('copy', preventCopy);
    };
  }, []);

  const pdfFile = useMemo(
    () =>
      urlId
        ? {
            url: `/api/docs/${urlId}/file${requestMobileFile ? '?m=1' : ''}`,
            httpHeaders: {
              Authorization: accessToken ? 'Bearer ' + accessToken : undefined,
            },
          }
        : undefined,
    [urlId, accessToken, requestMobileFile],
  );

  useEffect(() => {
    propsGetDocument(urlId!);
    propsGetDocumentRelated(documentRelatedStoreId, urlId!);
    return () => {
      propsResetDocumentStore();
      propsResetServerStore(documentRelatedStoreId);
    };
  }, [
    urlId,
    documentRelatedStoreId,
    propsGetDocument,
    propsGetDocumentRelated,
    propsResetDocumentStore,
    propsResetServerStore,
  ]);

  if (!email) return <ErrorComponent error="Not authorized" />;

  return (
    <AsyncPage title={document ? document.title : 'Article'} loading={isFetching} error={error}>
      {document && (
        <>
          {windowWidth >= MOBILE_BREAKPOINT && (
            <div
              className="position-fixed bg-light p-2"
              style={{
                top: headerHeight,
                bottom: 0,
                left: 0,
                zIndex: 2,
                overflowX: 'hidden',
                overflowY: 'auto',
                width: showSidebar ? SIDEBAR_MAX_WIDTH : SIDEBAR_MIN_WIDTH,
              }}
            >
              {showSidebar ? (
                <>
                  <div>
                    <Button outline onClick={() => setShowSidebar(false)} title="Hide document tags" className="p-0 border-0">
                      <TbLayoutSidebarRightExpand size="2rem" />
                    </Button>
                  </div>
                  <DocumentTags document={document} documentRelated={documentRelated} authUser={authUser} />
                </>
              ) : (
                <div>
                  <Button
                    outline
                    onClick={() => setShowSidebar(true)}
                    title="Display document tags"
                    className="p-0 mx-2 border-0"
                  >
                    <TbLayoutSidebarLeftExpand size="2rem" />
                  </Button>
                </div>
              )}
            </div>
          )}
          <div style={{ marginLeft: windowWidth - documentContainerWidth - 15, marginRight: -15 }}>
            <DocumentViewer
              offsetX={windowWidth - documentContainerWidth - 15}
              isPdf={isPdf}
              enableDownload={!authUser?.noDownload || document.type === 'source'}
              pdfFile={pdfFile}
              email={email}
              document={document}
              documentRelated={documentRelated}
              windowWidth={windowWidth}
              containerWidth={documentContainerWidth}
              headerHeight={headerHeight}
              mobileBreakpoint={MOBILE_BREAKPOINT}
            />
          </div>
          {windowWidth < MOBILE_BREAKPOINT && (
            <div>
              <DocumentTags document={document} documentRelated={documentRelated} authUser={authUser} />
            </div>
          )}
        </>
      )}
    </AsyncPage>
  );
};

export default ViewDocumentInner;
