import { library } from '../libraryApi';
import { asyncAction } from './asyncAction';
import { READ_SERVER } from './actionTypes';

export function getRegion(storeIdentifier: string, id: string) {
  try {
    return asyncAction({
      actionType: READ_SERVER,
      func: async () => (await library.regions.get(id)).data,
      storeIdentifier,
    });
  } catch (err) {
    return null;
  }
}
