import React, { useEffect } from 'react';

import { Card, CardBody, CardHeader } from 'reactstrap';
import { MdOpenInNew } from 'react-icons/md';

import { Document, DocumentType } from 'tcf-shared/models';

import { Link } from 'react-router-dom';
import { paths } from '../../paths';
import { readDocuments } from '../../actions/documentsActions';
import { useAppDispatch, useAppSelector } from '../../utils/hooks';

const ANNOUNCEMENTS_STORE = 'announcementsListStore';

const formatDocumentTitle = (doc: Document) => {
  if (doc.announcementSignUpURL)
    return (
      <a href={doc.announcementSignUpURL} target="_blank" rel="noopener noreferrer">
        {doc.title} <MdOpenInNew style={{ position: 'relative', top: '-1px' }} />
      </a>
    );
  if (doc.file) return <Link to={paths.VIEW_DOCUMENT.replace(':id', doc.id)}>{doc.title}</Link>;
  return doc.title;
};

const AnnouncementList = () => {
  const dispatch = useAppDispatch();
  const announcements = useAppSelector((s) => s.serverStores?.[ANNOUNCEMENTS_STORE]?.payload?.results) || [];

  useEffect(() => {
    const q = {
      search: {
        documentTypes: [DocumentType.announcement],
        displayUntilHasExpired: false,
      },
    };
    dispatch(readDocuments(ANNOUNCEMENTS_STORE, q));
  }, [dispatch]);

  return announcements.length > 0 ? (
    <Card className={'mb-3'}>
      <CardHeader>Announcements</CardHeader>
      <CardBody>
        <ul className={'pl-3 mb-0'}>
          {announcements.map((d: Document) => (
            <li key={d.id}>{formatDocumentTitle(d)}</li>
          ))}
        </ul>
      </CardBody>
    </Card>
  ) : null;
};

export default AnnouncementList;
