import { Query } from 'tcf-shared/models';

import { Action } from '../actions/Action';
import {
  INITIALIZE_QUERY,
  SET_QUERY,
  SET_QUERY_PAGING_AND_SORTING,
  SET_QUERY_PAGING_AND_SEARCH_SORTING,
  SET_QUERY_FILTER,
  SET_QUERY_SEARCH,
  UPDATE_QUERY_FILTERS,
  UPSERT_QUERY_FILTER,
  REMOVE_QUERY_FILTER,
  COMPLETELY_REMOVE_QUERY_FILTERS,
  ADD_TO_QUERY_FILTER_LIST,
  REMOVE_FROM_QUERY_FILTER_LIST,
  RESET_QUERY_FILTER,
  RESET_QUERY,
  LOGOUT,
} from '../actions/actionTypes';

export interface QueryState {
  [queryIdentifier: string]: Query;
}

export const DEFAULT_SKIP = 0;
export const DEFAULT_LIMIT = 10;
const initialState: QueryState = {};

// Note that this reduce function was written during the MySQL integration.  As such, the Elasticsearch only Query
// options are not being handled.  Rectify this if/when we integrate this reducer into the Elasticsearch pages.
export const initialQueryState: Query = {
  q: undefined, // Elasticsearch
  terms: undefined, // Elasticsearch
  skip: DEFAULT_SKIP, // Elasticsearch & MySQL
  after: undefined, // Elasticsearch
  limit: DEFAULT_LIMIT, // Elasticsearch & MySQL
  sort: undefined, // WAS [] Elasticsearch & MySQL
  filters: {}, // Elasticsearch & MySQL
  search: undefined,
};

// So object/dictionary keys are now stable in javascript.  We're going to sort them by key in an effort to
// reduce re-queries due to the order of keys differing even though they're the exact same query otherwise.
export const sortObjectKeys = (o: object) => Object.fromEntries(Object.entries(o).sort());

export const sortQueryObject = (query: Query) => {
  if (query.filters) query.filters = sortObjectKeys(query.filters);
  return sortObjectKeys(query);
};

export const queriesReducer = (state = initialState, action: Action): QueryState => {
  const queryIdentifier = action?.payload?.queryIdentifier;
  const currentQueryState = state[queryIdentifier] || initialQueryState;

  let currentFilterList: any;
  let updatedQueryState: any;

  switch (action.type) {
    case INITIALIZE_QUERY:
      // Only set the query if there isn't one.
      if (state[queryIdentifier]) return state;
      updatedQueryState = { ...initialQueryState, ...action.payload };
      break;

    case SET_QUERY:
      updatedQueryState = {
        ...initialQueryState,
        skip: action.payload?.skip ?? DEFAULT_SKIP,
        limit: action.payload?.limit ?? DEFAULT_LIMIT,
        sort: action.payload?.sort ?? [],
        q: action.payload?.q ?? '*',
        terms: action.payload?.terms ?? undefined,
        filters: action.payload?.filters ? { ...action.payload?.filters } : {},
        search: action.payload?.search ? { ...action.payload?.search } : {},
      };
      break;

    case SET_QUERY_PAGING_AND_SORTING: {
      updatedQueryState = {
        ...initialQueryState,
        ...currentQueryState,
        ...(action.payload.pagingAndSorting ?? {}),
      };
      break;
    }

    case SET_QUERY_PAGING_AND_SEARCH_SORTING: {
      updatedQueryState = {
        ...initialQueryState,
        ...currentQueryState,
        skip: action.payload.pagingAndSorting.skip || 0,
        limit: action.payload.pagingAndSorting.limit || 25,
      };
      const search = updatedQueryState.search || {};
      if (action.payload.pagingAndSorting.sortBy) {
        search.sortBy = action.payload.pagingAndSorting.sortBy;
      }
      if (action.payload.pagingAndSorting.sortOrder) {
        search.sortOrder = action.payload.pagingAndSorting.sortOrder;
      }
      updatedQueryState.search = search;
      break;
    }

    case SET_QUERY_SEARCH:
      updatedQueryState = {
        ...currentQueryState,
        skip: DEFAULT_SKIP, // Any filter change resets the page to 1 (0 skip).
        search: { ...action.payload.search },
      };
      break;

    case SET_QUERY_FILTER:
      updatedQueryState = {
        ...currentQueryState,
        skip: DEFAULT_SKIP, // Any filter change resets the page to 1 (0 skip).
        filters: { ...action.payload.filters },
      };
      break;

    case UPDATE_QUERY_FILTERS:
      updatedQueryState = {
        ...currentQueryState,
        skip: DEFAULT_SKIP, // Any filter change resets the page to 1 (0 skip).
        filters: { ...(currentQueryState.filters || {}), ...action.payload.filters },
      };
      break;

    case UPSERT_QUERY_FILTER:
      updatedQueryState = {
        ...currentQueryState,
        skip: DEFAULT_SKIP, // Any filter change resets the page to 1 (0 skip).
        filters: {
          ...(currentQueryState.filters || {}),
          [action.payload.filterField]: action.payload.filterValue,
        },
      };
      break;

    case REMOVE_QUERY_FILTER:
      updatedQueryState = {
        ...currentQueryState,
        skip: DEFAULT_SKIP, // Any filter change resets the page to 1 (0 skip).
        filters: { ...(currentQueryState.filters || {}) },
      };
      delete updatedQueryState.filters[action.payload.filterField];
      break;

    case COMPLETELY_REMOVE_QUERY_FILTERS:
      const { filters, ...rest } = {
        ...currentQueryState,
        skip: DEFAULT_SKIP, // Any filter change resets the page to 1 (0 skip).
      };
      updatedQueryState = rest;
      break;

    case ADD_TO_QUERY_FILTER_LIST:
      currentFilterList = currentQueryState?.filters?.[action.payload.filterField] || [];
      if (currentFilterList.includes(action.payload.listItem)) {
        updatedQueryState = currentQueryState;
      } else {
        updatedQueryState = {
          ...currentQueryState,
          skip: DEFAULT_SKIP, // Any filter change resets the page to 1 (0 skip).
          filters: {
            ...(currentQueryState.filters || {}),
            [action.payload.filterField]: [...currentFilterList, action.payload.listItem],
          },
        };
      }
      break;

    case REMOVE_FROM_QUERY_FILTER_LIST:
      currentFilterList = currentQueryState?.filters?.[action.payload.filterField] || [];
      updatedQueryState = {
        ...currentQueryState,
        skip: DEFAULT_SKIP, // Any filter change resets the page to 1 (0 skip).
        filters: {
          ...(currentQueryState.filters || {}),
          [action.payload.filterField]: currentFilterList.filter((i: string) => i !== action.payload.listItem),
        },
      };
      break;

    case RESET_QUERY_FILTER:
      updatedQueryState = {
        ...currentQueryState,
        skip: DEFAULT_SKIP, // Any filter change resets the page to 1 (0 skip).
        filters: {},
      };
      break;

    case RESET_QUERY:
      updatedQueryState = {
        ...initialQueryState,
      };
      break;

    case LOGOUT.SUCCEEDED:
    case LOGOUT.FAILED:
      return initialState;

    default:
      return state;
  }

  return { ...state, [queryIdentifier]: sortQueryObject(updatedQueryState) };
};
