import React from 'react';
import moment from 'moment-timezone';
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import { TimeTypeLabels } from 'tcf-shared/models';
import { Event } from './utils';

interface OwnProps {
  event: Event;
  closeModal: () => void;
  browserTimezone: string;
}

const EventModal = ({ event, closeModal, browserTimezone }: OwnProps) => {
  const title = event.allDay
    ? `${moment.tz(event.start, browserTimezone).format('LL')} ${TimeTypeLabels[event.timeType]}`
    : moment.tz(event.start, browserTimezone).format('LLLL');

  return (
    <Modal isOpen={true} backdrop={true} toggle={closeModal}>
      <ModalHeader
        tag="span"
        className="bg-dark"
        style={{ border: '2px solid #555', color: 'white' }}
        close={
          <span
            className="close clickable"
            onClick={closeModal}
            style={{ color: '#FFFFFF', opacity: '.9', textShadow: 'none' }}
          >
            x
          </span>
        }
        toggle={closeModal}
      >
        {title}
      </ModalHeader>
      <ModalBody>
        <div dangerouslySetInnerHTML={{ __html: event.description ?? '<p><i>No description provided</i></p>' }} />
      </ModalBody>
      <ModalFooter>
        <Button color="primary" onClick={closeModal}>
          Close
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default EventModal;
