import { AxiosInstance } from 'axios';

import { ApiResponse } from 'tcf-shared/models';

import { CrudApiClient } from './CrudApiClient';

export class EmailCampaignsApiClient extends CrudApiClient {
  constructor(readonly axios: AxiosInstance) {
    super(axios, '/email-campaigns');
  }

  async getSGCampaignSummary(id: string): Promise<ApiResponse> {
    return this.axios.get<ApiResponse>(`${this.baseUrl}/${id}/sg/summary`);
  }

  async sendTestCampaign(id: string, data: {}): Promise<ApiResponse> {
    return this.axios.post<ApiResponse>(`${this.baseUrl}/${id}/send-test-campaign`, data);
  }
}
