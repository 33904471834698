import { Action } from '../actions/Action';
import { ApiError, Query, User } from 'tcf-shared/models';
import { GET_USERS, SAVE_USERS, LOGOUT, GET_USER_EMAIL_DOMAINS, SELECT_USERS, DESELECT_USERS } from '../actions/actionTypes';

export interface EmailDomainState {
  query?: Query;
  total?: number;
  domains: {
    domain: string;
    count: number;
    inactive_count: number;
  }[];
  isFetching: boolean;
  error: string;
}

export interface DocViewsState {
  isFetching: boolean;
  error: string;
  data?: any;
}

export interface UsersState {
  docViews: DocViewsState;
  domains: EmailDomainState;
  error: string;
  isFetching: boolean;
  query?: Query;
  total?: number;
  users: User[];
  selectedUsers: User[];
}

const initialState: UsersState = {
  domains: { domains: [], isFetching: false, error: '' },
  docViews: { isFetching: false, error: '' },
  error: '',
  isFetching: false,
  users: [],
  selectedUsers: [],
};

const emailDomainReducer = (state: EmailDomainState, action: Action) => {
  switch (action.type) {
    case GET_USER_EMAIL_DOMAINS.REQUESTED:
      return { domains: [], isFetching: true, error: '' };

    case GET_USER_EMAIL_DOMAINS.SUCCEEDED:
      return {
        query: action.payload.query,
        total: action.payload.total,
        domains: action.payload.results,
        isFetching: false,
        error: '',
      };

    case GET_USER_EMAIL_DOMAINS.FAILED:
      return { domains: [], isFetching: false, error: action.payload.message };

    default:
      return state;
  }
};

export const usersReducer = (state = initialState, action: Action): UsersState => {
  switch (action.type) {
    case GET_USER_EMAIL_DOMAINS.REQUESTED:
    case GET_USER_EMAIL_DOMAINS.SUCCEEDED:
    case GET_USER_EMAIL_DOMAINS.FAILED:
      return {
        ...state,
        domains: emailDomainReducer(state.domains, action),
      };

    case GET_USERS.REQUESTED:
      return {
        ...initialState,
        isFetching: true,
      };

    case SAVE_USERS.REQUESTED:
      return {
        ...state,
        isFetching: true,
      };

    case GET_USERS.SUCCEEDED:
      return {
        ...state,
        error: '',
        isFetching: false,
        query: action.payload.query,
        total: action.payload.total,
        users: action.payload.results,
      };

    case SAVE_USERS.SUCCEEDED:
      return {
        ...state,
        error: '',
        isFetching: false,
        users: state.users.map(
          (u) => action.payload.find((uu: User) => uu.id === u.id) || state.users.find((uu: User) => uu.id === u.id),
        ),
      };

    case GET_USERS.FAILED: {
      const error = action.payload as ApiError;
      return {
        ...initialState,
        error: `${error ? `${error.name}: ${error.message}` : 'no message'}`,
      };
    }

    case SAVE_USERS.FAILED: {
      const error = action.payload as ApiError;
      return {
        ...state,
        error: `${error ? `${error.name}: ${error.message}` : 'no message'}`,
        isFetching: false,
      };
    }

    case SELECT_USERS: {
      const selectedUsers = [
        ...state.selectedUsers,
        ...action.payload.filter((u: User) => !state.selectedUsers.find((s: User) => s.id === u.id)),
      ];
      return {
        ...state,
        selectedUsers,
      };
    }

    case DESELECT_USERS: {
      const selectedUsers = [...state.selectedUsers.filter((s: User) => !action.payload.find((u: User) => s.id === u.id))];
      return {
        ...state,
        selectedUsers,
      };
    }

    case LOGOUT.SUCCEEDED:
    case LOGOUT.FAILED:
      return initialState;

    default:
      return state;
  }
};
