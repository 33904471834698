import React, { useState } from 'react';
import { Button, Col, Form, FormText, Label, Row } from 'reactstrap';
import { Form as RFForm, Field as RFField } from 'react-final-form';
import { Link } from 'react-router-dom';

import {
  SavedSearch,
  allCategoriesTableFilterOptions,
  allRegionsTableFilterOptions,
  allSectorsTableFilterOptions,
} from 'tcf-shared/models';

import * as Inputs from '../../../components/Inputs';
import { PromptUnsavedChanges } from '../../../components/forms/PromptUnsavedChanges';
import { paths } from '../../../paths';
import CheckboxListSelect from '../../../components/FormGroupSelect/CheckboxListSelect';
import SearchHintsModal from '../../Document/components/SearchHintsModal';
import IssuesSelect from '../../../components/FormGroupSelect/IssuesSelect';

const ISSUE_MATCHES_STORE = 'editSearchFormIssueMatchesStore';
const ISSUE_LIST_STORE = 'editSearchFormIssueListStore';
const EXCLUDED_ISSUE_MATCHES_STORE = 'editSearchFormExcludedIssueMatchesStore';
const EXCLUDED_ISSUE_LIST_STORE = 'editSearchFormExcludedIssueListStore';

const validateName = (value: string) => {
  return (value ?? '').trim().length > 0 ? undefined : 'Name is required.';
};

interface OwnProps {
  savedSearch: Partial<SavedSearch>;
  entitlements: any;
  targetUserId: string;
  targetUserEmail: string;
  isOwnSearch: boolean;
  handleDelete: () => void;
  handleSubmit: (savedSearch: Partial<SavedSearch>) => void;
}

const EditSearchForm = (props: OwnProps) => {
  const { savedSearch, entitlements, targetUserId, targetUserEmail, isOwnSearch, handleDelete, handleSubmit } = props;
  const [showHintsModal, setShowHintsModal] = useState(false);

  const isNew = !savedSearch?.id;
  const returnToURL = isOwnSearch ? paths.VIEW_ACCOUNT : paths.admin.VIEW_USER.replace(':id', targetUserId);
  const returnToURLState = isOwnSearch ? { activeTab: 'Advanced Alerts' } : { activeTab: 'Saved Searches' };

  const toggleHintsModal = () => setShowHintsModal(!showHintsModal);

  // Disable nonapplicable options based on user permissions
  const categoryOptions = allCategoriesTableFilterOptions.map((o) => ({
    ...o,
    disabled: entitlements?.categoryIds && !entitlements.categoryIds.includes(o.value),
    selected: savedSearch.categories?.includes(o.value),
  }));

  const regionOptions = allRegionsTableFilterOptions.map((o) => ({
    ...o,
    disabled: entitlements?.regionIds && !entitlements.regionIds.includes(o.value),
    selected: savedSearch.regions?.includes(o.value),
  }));

  const sectorOptions = allSectorsTableFilterOptions.map((o) => ({
    ...o,
    disabled: entitlements?.sectorCodes && !entitlements.sectorCodes.includes(o.value),
    selected: savedSearch.sectors?.includes(o.value),
  }));

  return (
    <>
      <RFForm initialValues={savedSearch} onSubmit={handleSubmit}>
        {(formProps) => (
          <PromptUnsavedChanges dirty={formProps.dirty}>
            <Form onSubmit={formProps.handleSubmit}>
              <Row className={'mb-2'}>
                <Col xs={3} md={6}>
                  <h2>
                    {isNew ? 'Create' : 'Edit'}&nbsp;search{isOwnSearch ? '' : ` for ${targetUserEmail}`}
                  </h2>
                </Col>
                <Col xs={9} md={6} className={'text-right'}>
                  <Button color="info" className="mr-2" onClick={toggleHintsModal} title="Show search hints...">
                    Help
                  </Button>
                  <Button
                    color={'primary'}
                    disabled={formProps.pristine || formProps.submitting || !formProps.valid}
                    title={formProps.pristine ? 'No changes yet to save' : 'Save changes'}
                    type={'submit'}
                  >
                    {formProps.submitting ? 'Saving...' : 'Save'}
                  </Button>
                  {!isNew && (
                    <Button
                      className="ml-2"
                      color="danger"
                      disabled={formProps.submitting}
                      onClick={handleDelete}
                      title={'Use with caution'}
                    >
                      Delete...
                    </Button>
                  )}
                  <Link
                    to={{ pathname: returnToURL, state: returnToURLState }}
                    title={isOwnSearch ? 'View list of saved searches' : 'View User'}
                    className="btn btn-secondary ml-2"
                  >
                    {isOwnSearch ? 'View searches' : 'View user'}
                  </Link>
                </Col>
              </Row>

              {formProps.submitFailed && <FormText color={'danger'}>{`Failed to save. ${formProps.error || ''}`}</FormText>}

              <Row>
                <Col xs={12} sm={2} className={'tcf-form-label'}>
                  <Label for={'name'}>Name</Label>
                </Col>
                <Col xs={12} sm={10}>
                  <Inputs.TcfTextInput
                    name={'name'}
                    placeholder={'Enter name for saved search'}
                    type={'text'}
                    validate={validateName}
                    disabled={formProps.submitting}
                  />
                </Col>
              </Row>

              <Row>
                <Col xs={12} sm={2} className={'tcf-form-label pt-0'}>
                  <Label for={'alert'}>Send email alerts</Label>
                </Col>
                <Col xs={12} sm={10} className="mb-3">
                  <Inputs.TcfCheckbox name={'alert'} disabled={formProps.submitting} />
                </Col>
              </Row>

              <Row>
                <Col xs={12} sm={2} className={'tcf-form-label pt-0'}>
                  <Label for={'categories'}>Categories</Label>
                </Col>
                <Col xs={12} sm={10} className="mb-3">
                  <CheckboxListSelect fieldName="categories" keyField="id" options={categoryOptions} useSimpleArray />
                </Col>
              </Row>

              <Row>
                <Col xs={12} sm={2} className={'tcf-form-label pt-0'}>
                  <Label for={'regions'}>Regions</Label>
                </Col>
                <Col xs={12} sm={10} className="mb-3">
                  <CheckboxListSelect fieldName="regions" keyField="id" options={regionOptions} useSimpleArray />
                </Col>
              </Row>

              <Row>
                <Col xs={12} sm={2} className={'tcf-form-label pt-0'}>
                  <Label for={'sectors'}>Sectors</Label>
                </Col>
                <Col xs={12} sm={10} className="mb-3">
                  <CheckboxListSelect fieldName="sectors" keyField="code" options={sectorOptions} useSimpleArray />
                </Col>
              </Row>
              <Row>
                <Col xs={12} sm={2} className={'tcf-form-label'}>
                  <Label for={'searchText'}>Keywords</Label>
                </Col>
                <Col xs={12} sm={10}>
                  <Inputs.TcfTextInput
                    name="searchText"
                    placeholder="Example: mobile payment antitrust regulation"
                    type="text"
                    disabled={formProps.submitting}
                  />
                </Col>
              </Row>

              <Row>
                <Col xs={12} sm={2} className={'tcf-form-label pt-0'}>
                  <Label for={'includeIssues'}>Issues</Label>
                </Col>
                <Col xs={12} sm={10} className="mb-3">
                  <RFField
                    name="includeIssues"
                    useSimpleArray={true}
                    matchesStoreId={ISSUE_MATCHES_STORE}
                    issuesStoreId={ISSUE_LIST_STORE}
                  >
                    {IssuesSelect}
                  </RFField>
                </Col>
              </Row>

              <Row>
                <Col xs={12} sm={2} className={'tcf-form-label pt-0'}>
                  <Label for={'excludeIssues'}>But NOT these issues</Label>
                </Col>
                <Col xs={12} sm={10} className="mb-3">
                  <RFField
                    name="excludeIssues"
                    useSimpleArray={true}
                    matchesStoreId={EXCLUDED_ISSUE_MATCHES_STORE}
                    issuesStoreId={EXCLUDED_ISSUE_LIST_STORE}
                  >
                    {IssuesSelect}
                  </RFField>
                </Col>
              </Row>
            </Form>
          </PromptUnsavedChanges>
        )}
      </RFForm>
      {showHintsModal && <SearchHintsModal showModal={showHintsModal} toggleModal={toggleHintsModal} />}
    </>
  );
};

export default EditSearchForm;
